import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface IItemsTypesListDialogProps {
  open: boolean;
  setOpen: any;
  productData: any;
  setPackData: any;
}

export const ItemsTypesListDialog: React.FC<IItemsTypesListDialogProps> = ({
  open,
  setOpen,
  productData,
  setPackData,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const { text2, text3 } = useDisplaySettingsContext();

  return (
    <div>
      <BootstrapDialog
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: text2 }} id="customized-dialog-title">
          Available Quantities for {productData.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {productData.packdata!.map((packData) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid black",
                    borderRadius: "50px",
                    padding: 1,
                    marginBottom: 1,
                    "&:hover": {
                      cursor: "pointer",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setPackData(packData);
                    handleClose();
                  }}
                >
                  <Typography sx={{ mr: 1, fontSize: text3 }}>{packData.desc}</Typography>
                  {packData.color !== null && (
                    <>
                      <Typography fontSize={text3}>Color : </Typography>
                      <Box
                        sx={{
                          mr: 1,
                          width: "10px",
                          height: "10px",
                          borderRadius: 44 / 2,
                          background:
                            `#${parseInt(packData?.color)
                              .toString(16)
                              .slice(2)}` ?? "0",
                        }}
                      />
                    </>
                  )}
                  {packData.size !== null && (
                    <Typography sx={{ mr: 1 }} fontSize={text3}>
                      Size : {packData.size!}
                    </Typography>
                  )}
                  {packData.discountedprice !== null ? (
                    <Typography sx={{ mr: 1 }} fontSize={text3}>
                      {packData.discountedprice!.toFixed(2)}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {packData.origprice != null ? (
                    <Typography
                      sx={{
                        mr: 1,
                        textDecorationLine: "line-through",
                      }}
                      fontSize={text3}
                    >
                      {packData.origprice!.toFixed(2)}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
