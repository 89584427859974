import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Divider,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { toastMessage } from "src/slices/commonSlice";
import { setDialog } from "src/slices/uiSettingsSlice";
import { loadResults, submitElection } from "src/slices/user-panel/thunks";
import { AppDispatch } from "src/store";

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H"];

interface ISubmitElectionResultDialog {
  dialogName: string;
  resultData: any;
  setActionButton: any;
}

export const SubmitElectionResultDialog: FC<ISubmitElectionResultDialog> = ({
  dialogName,
  resultData,
  setActionButton,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [result, setResult] = useState(null);
  const [mcqs, setMcqs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [answerList, setAnswerList] = useState([]);

  const { foregroundColor } = useDisplaySettingsContext();

  const ActionButtons = () => [
    {
      text: "Submit",
      color: "primary",
      variant: "contained",
      click: () => {
        console.log(resultData);
        console.log(answerList);
        dispatch(
          submitElection({
            asgid: resultData.id,
            answerList: [
              {
                id: resultData.id,
                name: resultData.name,
                date: resultData.date,
                answerList: answerList.map((data) => ({
                  qid: data.qid,
                  choices: data.choices,
                  text: data.text,
                })),
              },
            ],
          })
        ).then((response) => {
          if (response.payload.data.errorid === -1) {
            dispatch(
              toastMessage({
                message: "Submitted Successfully!",
              })
            );
          } else {
            dispatch(
              toastMessage({
                error: true,
                message: "Unable to submit",
              })
            );
          }
        });
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
    {
      text: "Cancel",
      color: "secondary",
      variant: "contained",
      click: () => {
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
  ];

  useEffect(() => {
    setActionButton(ActionButtons());
  }, [answerList, mcqs, resultData]);

  useEffect(() => {
    if (resultData) {
      dispatch(loadResults(resultData)).then((response) => {
        setIsLoading(false);
        const res = response.payload?.data?.answerperQsum?.answerlist ?? null;
        setMcqs(response.payload?.data?.rawasg?.qlist ?? null);
        setResult(res);
      });
    }
  }, [resultData]);

  const KeyValue = ({ label, value }) => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {mcqs ? (
        <Grid container sx={{ maxHeight: "70vh" }}>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
            }}
          >
            Results
          </Typography>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            {mcqs &&
              mcqs.map((mcq, index) => (
                <Box sx={{ py: 2 }}>
                  <Typography sx={{ marginBottom: "5px" }} variant="h6">{`${
                    index + 1
                  }. ${mcq?.questiontext}`}</Typography>
                  {mcq?.canlist?.map((candidate, candIndex) => (
                    <Box
                      sx={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: "5px",
                        mb: 1,
                      }}
                    >
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        {candidate.name}
                      </Typography>
                      <Checkbox
                        checked={answerList[index]?.text === mcq?.questiontext}
                        onChange={() => {
                          if (answerList[index]?.text === mcq?.questiontext) {
                            answerList[index] = null;
                            setAnswerList([...answerList]);
                            return;
                          }
                          answerList[index] = {
                            text: mcq?.questiontext,
                            choices: [candIndex],
                            qid: mcq.qid,
                          };
                          setAnswerList([...answerList]);
                        }}
                      />
                    </Box>
                  )) ?? <></>}
                  <Divider />
                </Box>
              ))}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!isLoading ? (
            <Typography>No Result Found</Typography>
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </Box>
      )}
    </>
  );
};
