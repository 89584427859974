import React, { createContext, useContext, useState, useEffect } from "react";

interface Coordinates {
  latitude: number;
  longitude: number;
  district: string;
}

interface GeolocationContextType extends Coordinates {
  updateDistrict: (newDistrict: string) => void;
  updateGeoLocation: (
    latitude: number,
    longitude: number,
    district: string
  ) => void;
}

const GeolocationContext = createContext<GeolocationContextType | undefined>(
  undefined
);

export const useGeolocationContext = (): GeolocationContextType => {
  const context = useContext(GeolocationContext);
  if (!context) {
    throw new Error(
      "useGeolocationContext must be used within a GeolocationProvider"
    );
  }
  return context;
};

export const GeolocationProvider = ({ children }) => {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    latitude: 0,
    longitude: 0,
    district: "Georgia",
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            district: coordinates.district,
          });
        },
        () => {
          setCoordinates({
            latitude: 0,
            longitude: 0,
            district: coordinates.district,
          });
        }
      );
    } else {
      console.error("Geolocation is not available.");
    }
  }, []);

  // Function to update the district value
  const updateDistrict = (newDistrict: string) => {
    setCoordinates({
      ...coordinates,
      district: newDistrict,
    });
  };

  const updateGeoLocation = (latitude, longitude, district) => {
    setCoordinates({
      latitude: latitude,
      longitude: longitude,
      district: district,
    });
  };

  return (
    <GeolocationContext.Provider
      value={{ ...coordinates, updateDistrict, updateGeoLocation }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};
