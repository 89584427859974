import { FC } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { BaseTextFieldProps, FormControl, TextField } from "@mui/material";

interface TextareaProps extends BaseTextFieldProps {
  name: string;
  label: string;
  helperText?: any;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  multiline: boolean;
  rows: number;
  readOnly?: boolean;
  maxLength?: number;
}

const Textarea: FC<TextareaProps> = (props) => {
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "small",
    fullWidth = true,
    variant = "outlined",
    required = false,
    multiline,
    rows,
    maxLength,
    readOnly = false,
  } = props;

  return (
    <Field as="Select" name={name}>
      {({ field, form }) => {
        console.log(field, form);
        return (
          <FormControl fullWidth={fullWidth}>
            <TextField
              {...props}
              {...field}
              name={name}
              autoComplete="off"
              fullWidth={fullWidth}
              size={size}
              variant={variant}
              required={required}
              multiline={multiline}
              rows={rows}
              placeholder={placeholder}
              helperText={helperText}
              label={label}
              inputProps={{ maxLength, readOnly }}
            />
          </FormControl>
        );
      }}
    </Field>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  size: PropTypes.any,
  variant: PropTypes.any,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
};
export default Textarea;
