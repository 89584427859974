import { Typography, Box, Grid, Paper, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsHomeLoading,
} from "src/slices/home/selectors";
import { AppDispatch } from "src/store";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "src/components/formik/FormikControl";
import { selectCartState } from "src/slices/cart/selectors";
import { ICartState } from "src/slices/cart/types";
import { useNavigate } from "react-router-dom";
import { selectAppointmentState } from "src/slices/appointment/selectors";
import {
  AppointmentModel,
  AppointmentState,
} from "src/slices/appointment/types";
import CButton from "src/components/button-group/CButton";
import { updateCustomerInAppointmentState } from "src/slices/appointment";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const PersonalDetailsView = ({}) => {
  const navigate = useNavigate();

  const formikRef = useRef(null);
  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();
  const selectedCartState: ICartState = useSelector(selectCartState);
  const appointmentState: AppointmentState = useSelector(
    selectAppointmentState
  );

  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const selectIsLoading = useSelector(selectIsHomeLoading);

  const validationSchemaModel = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    contact: Yup.string().required("Contact is required"),
  });

  const [appointmentModel, setAppointmentModel] =
    useState<AppointmentModel>(null);
  useEffect(() => {
    setAppointmentModel(appointmentState.onCreationAppointment);
  }, []);

  // const onSubmit() {
  //   if (!_formKey.currentState!.validate()) return;
  //   context.read<AppointmentBloc>().add(UpdateOnCreationAppointment(
  //         apt:_appointment!,
  //       ));
  //   PersistentNavBarNavigator.pushNewScreen(
  //     context,
  //     screen: MultiBlocProvider(
  //       providers: [
  //         BlocProvider.value(value: context.read<CartBloc>()),
  //         BlocProvider.value(
  //           value: context.read<AppointmentBloc>(),
  //         ),
  //       ],
  //       child: AppointmentDetails(),
  //     ),
  //   );
  // }

  return (
    <>
      {true ? (
        <Box
          sx={{
            width: isMobile ? "100%" : "50%",
            alignSelf: "center",
          }}
        >
          <Formik
            initialValues={{
              name: "",
              email: "",
              contact: "",
            }}
            validationSchema={validationSchemaModel}
            innerRef={formikRef}
            onSubmit={(values) => {
              console.log(values);
              setAppointmentModel((appointmentModel) => ({
                ...appointmentModel,
                customername: values.name,
                customeremail: values.email,
                customercontactnumber: values.contact,
              }));
              dispatch(
                updateCustomerInAppointmentState({
                  customername: values.name,
                  customeremail: values.email,
                  customercontactnumber: values.contact,
                })
              );
              navigate("/appointment-details");
            }}
          >
            {({ touched, errors, getFieldProps }) => (
              <Form>
                <Paper sx={{ p: 2 }}>
                  <Stack alignItems="center">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography fontSize={heading3}>
                          {appointmentState.serviceModel.servicename ??
                            "Not Loaded"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontSize={text2}>Personal Details</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontSize={text1}>
                          Enter the details of the patient
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          control="InputField"
                          label={"Full Name"}
                          name="name"
                          size="small"
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          {...getFieldProps("name")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          control="InputField"
                          label={"Email Address"}
                          name="email"
                          size="small"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          {...getFieldProps("email")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          control="InputField"
                          label={"Contact Number"}
                          name="contact"
                          size="small"
                          error={Boolean(touched.contact && errors.contact)}
                          helperText={touched.contact && errors.contact}
                          {...getFieldProps("contact")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CButton variant="contained" type="submit">
                          View
                        </CButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Paper>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
};
