import React, { FC, forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import XIcon from "../icons/X";
import { useDispatch } from "react-redux";
import CTypography from "./CTypography";
import CModalConfirmation from "./CModalConfirmation";
import {
  Slide,
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { setDialog } from "src/slices/uiSettingsSlice";
import { ContainedButton, OutlinedButton } from "./button-group";
import { AppDispatch } from "src/store";

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

interface ICModal {
  okButtonType?: string;
  title: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullScreen?: boolean;
  disableSavedButton?: boolean;
  open: boolean;
  cfooter?: any;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  content: any;
  showSaveButton?: boolean;
  handleSave?: () => void;
  handleSaveButtonText?: string;
  showCancelButton?: boolean;
  handleCancel?: () => void;
  handleCancelButtonText?: string;
  saveButtonType?: "button";
  actionButtons?: any;
  dialogName?: string;
  confirmOnCancel?: boolean;
  contentType?: string;
  headerElements?: any;
  headerElementsx?: any;
  footerElementsx?: any;
}

const CModal: FC<ICModal> = ({
  okButtonType,
  title,
  open,
  setOpen,
  maxWidth = "md",
  fullScreen = false,
  disableSavedButton = false,
  cfooter,
  content,
  showSaveButton = true,
  handleSave,
  handleSaveButtonText,
  showCancelButton = true,
  handleCancel,
  handleCancelButtonText,
  saveButtonType,
  actionButtons,
  dialogName,
  confirmOnCancel = false,
  contentType = "form",
  headerElements,
  headerElementsx,
  footerElementsx,
  ...rest
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setDialog({ open: false, dialogName }));
    }
  };

  useEffect(() => {
    if (confirmOnCancel) {
      dispatch(
        setDialog({ open: false, dialogName: `${dialogName}Confirmation` })
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      aria-labelledby={`modal-modal-${title}`}
      aria-describedby={`modal-modal-desc-${title}`}
      open={open}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth
      fullScreen={fullScreen}
      disableEscapeKeyDown
      onClose={handleClose}
      TransitionComponent={Transition}
      {...rest}
      id={title.split(" ").join()}
    >
      {confirmOnCancel && (
        <CModalConfirmation
          dialogName={`${dialogName}Confirmation`}
          handleSave={() => {
            handleClose("", "ExitButtonClose");
            if (handleCancel) handleCancel();
          }}
        />
      )}

      <DialogTitle
        sx={
          headerElementsx === null ? { p: "5px 24px" } : { ...headerElementsx }
        }
      >
        <Stack
          spacing={1}
          direction={{ xs: "column", md: "row" }}
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <CTypography sx={{ fontWeight: "600", fontSize: "18px" }}>
            {title}
          </CTypography>
          {headerElements && <>{headerElements}</>}
        </Stack>
        <IconButton
          sx={{
            position: "absolute",
            right: "8px",
            top: "6px",
          }}
          disableRipple
          size="small"
          onClick={(e) => {
            handleClose(e, "crossIconClose");
            if (handleCancel) handleCancel();
          }}
        >
          <XIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      {contentType === "table" ? (
        <DialogContent dividers sx={{ p: 0 }}>
          {content}
        </DialogContent>
      ) : (
        <DialogContent dividers>{content}</DialogContent>
      )}

      <DialogActions
        sx={footerElementsx === null ? { p: "8px" } : { ...footerElementsx }}
      >
        {!!actionButtons && (
          <Grid container spacing={1} justifyContent="right">
            {actionButtons.map((ele) => (
              <Grid item>
                {ele.type !== undefined && ele.type === "submit" ? (
                  <ContainedButton
                    size="small"
                    form={dialogName}
                    type={ele.type}
                    color={ele.color}
                    variant={ele.variant}
                    onClick={ele.click}
                    disabled={ele.disabled !== undefined && ele.disabled}
                  >
                    {ele.text}
                  </ContainedButton>
                ) : (
                  <ContainedButton
                    size="small"
                    color={ele.color}
                    variant={ele.variant}
                    onClick={ele.click}
                    disabled={ele.disabled !== undefined && ele.disabled}
                  >
                    {ele.text}
                  </ContainedButton>
                )}
              </Grid>
            ))}
          </Grid>
        )}

        {!!cfooter && cfooter}

        {actionButtons === undefined &&
          cfooter === undefined &&
          showSaveButton && (
            <>
              {saveButtonType === undefined ? (
                <ContainedButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  autoFocus
                  form={dialogName}
                  type="submit"
                  disabled={disableSavedButton}
                  onClick={() => {
                    if (handleSave) handleSave();
                  }}
                >
                  {handleSaveButtonText === undefined
                    ? "Ok"
                    : handleSaveButtonText}
                </ContainedButton>
              ) : (
                <ContainedButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  autoFocus
                  onClick={(e) => {
                    handleClose(e, "ExitButtonClose");
                    if (handleSave) handleSave();
                  }}
                >
                  {handleSaveButtonText === undefined
                    ? "Ok"
                    : handleSaveButtonText}
                </ContainedButton>
              )}
            </>
          )}

        {actionButtons === undefined &&
          cfooter === undefined &&
          showCancelButton && (
            <OutlinedButton
              size="small"
              color="primary"
              variant="contained"
              onClick={(e) => {
                if (confirmOnCancel) {
                  dispatch(
                    setDialog({
                      open: true,
                      dialogName: `${dialogName}Confirmation`,
                    })
                  );
                } else {
                  handleClose(e, "ExitButtonClose");
                  if (handleCancel) handleCancel();
                }
              }}
            >
              {handleCancelButtonText === undefined
                ? "Cancel"
                : handleCancelButtonText}
            </OutlinedButton>
          )}
      </DialogActions>
    </Dialog>
  );
};

CModal.propTypes = {
  okButtonType: PropTypes.string,
  title: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.any,
  cfooter: PropTypes.any,
  content: PropTypes.any,
  showSaveButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  actionButtons: PropTypes.array,
  handleSaveButtonText: PropTypes.string,
  handleCancelButtonText: PropTypes.string,
  saveButtonType: PropTypes.any,
  dialogName: PropTypes.string,
  confirmOnCancel: PropTypes.bool,
  contentType: PropTypes.string,
  headerElements: PropTypes.any,
  headerElementsx: PropTypes.any,
  footerElementsx: PropTypes.any,
  disableSavedButton: PropTypes.bool,
};
export default CModal;
