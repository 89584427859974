import {
  TextField,
  IconButton,
  InputAdornment,
  Box,
  styled,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { auth } from "src/configs/firebase";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import { getServiceProviders } from "src/slices/home/thunks";
import * as CONSTANTS from "src/constants";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { CategoryButton } from "./CategoryButton";
import { SubCategoryButton } from "./SubCategoryButton";
import {
  selectProdatypeinfo,
  selectSelectedAtypeCategory,
  selectSplitCategory,
} from "src/slices/landing-page/selectors";
import { AtypeTab } from "../AtypeTab";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";

const SearchTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid #0294cf;
    }

    &.Mui-focused active {
      border: 1px solid #0294cf;
    }

    .Mui-focused focus {
      border: 1px solid #0294cf;
    }
  }
`;

export const Category2 = ({ subdomain }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const categoriesData = useSelector(selectSplitCategory);
  const prodatypeinfo = useSelector(selectProdatypeinfo);
  const selectedAtype = useSelector(selectSelectedAtypeCategory);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const { foregroundColor } = useDisplaySettingsContext();
  const [searchText, setSearchText] = useState("");
  const { district, updateDistrict, latitude, longitude } =
    useGeolocationContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isAtypelst = useMemo(() => {
    return (
      prodatypeinfo?.atypelst !== null || prodatypeinfo?.atypelst !== undefined
    );
  }, [prodatypeinfo.atypelst]);

  return (
    <>
      <Box sx={{ backgroundColor: 'white', padding: isMobile ? "15px" : "15px 50px" }}>
      <SearchTextField
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          size="small"
          label="Search Product"
          variant="outlined"
          inputProps={{
            autoComplete: "off",
            //   style: { paddingRight: 0}
          }}
          InputProps={{
            style: { paddingRight: 5, borderRadius: "50px" },
            endAdornment: (
              <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>
                <IconButton
                  sx={{
                    backgroundColor: foregroundColor,
                    m: 0,
                    p: 0,
                    width: "50px",
                    height: "25px",
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (searchText.trim() !== "") {
                      dispatch(
                        getServiceProviders({
                          dynamicProperty: {
                            servicetype: CONSTANTS.ecom_enduser_search,
                            grouptype: CONSTANTS.ecom_enduser_search,
                            isshop: true,
                            sp: {
                              statename: district,
                              lati: latitude,
                              longi: longitude,
                            },
                            cust: {
                              customeruserid: auth.currentUser.uid,
                            },
                            spoffset: 0,
                          },
                          Query: searchText,
                        })
                      ).then(() => {
                        navigate(`${CONSTANTS.ecom_enduser_search}`);
                      });
                    }
                  }}
                >
                  <Search sx={{ color: "white" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: "50px",
            mb: "12px",
          }}
        />
        {isAtypelst && (
          <AtypeTab
            atype={selectedAtype}
            atypelst={prodatypeinfo?.atypelst}
            subdomain={subdomain}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "scroll",
            gap: "32px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {categoriesData?.categories?.map((name) => (
            <CategoryButton
              name={name}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          ))}
        </Box>
      </Box>
      {selectedCategory && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "scroll",
            gap: "32px",
            backgroundColor: "white",
            padding: isMobile ? "24px" : "24px 72px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {categoriesData.splitcatlst[selectedCategory].map((data) => (
            <SubCategoryButton
              name={data.name}
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
            />
          ))}
        </Box>
      )}
    </>
  );
};
