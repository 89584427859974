import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { useDebounce } from "src/hooks/useDebounce";
import { getTripRelatedLookups } from "src/slices/trip/thunks";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import {
  selectIsTripLoading,
  selectTripRelatedLookupsResult,
} from "src/slices/trip/selectors";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
interface IAsyncAutocompleteProps {
  label: string;
  onSelect: (value: any) => void;
}
export const TripLookupAutocomplete: FC<IAsyncAutocompleteProps> = ({
  label,
  onSelect,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const isTripLoading = useSelector(selectIsTripLoading);
  const searchResults = useSelector(selectTripRelatedLookupsResult);

  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = React.useState("");

  const [searchText, setSearchText] = useState("");
  const searchQuery = useDebounce(searchText, 1000);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);

  const { district } = useGeolocationContext();

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active && !isTripLoading && loading && searchResults) {
        setOptions([...searchResults?.pointlist]);
        setOpen(true);
      }
    })();
    return () => {
      active = false;
    };
  }, [isTripLoading, loading, searchResults]);

  useEffect(() => {
    setLoading(false);
  }, [searchResults]);

  useEffect(() => {
    setLoading(false);
  }, [searchResults]);

  useEffect(() => {
    if (searchQuery && value !== inputValue) {
      setLoading(true);
      dispatch(
        getTripRelatedLookups({
          text: searchQuery,
          state: district,
        })
      );
    } else {
      setLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="async-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option: any) => option}
      options={options}
      loading={loading}
      value={value}
      onChange={(event: any, newValue: any | null) => {
        setValue(newValue);
        onSelect(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setSearchText(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
