import { CTabView } from "src/components/CTabView";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store";
import { loadExamTerms } from "src/slices/user-panel/thunks";

import { AttendancePanel } from "./attendance-panel";
import { ProgressPanel } from "./progress-panel";
import { EventPanel } from "./event-panel";
import { AssignmentPanel } from "./assignment-panel";

export const UserPanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(loadExamTerms());
  }, []);
  return (
    <Box
      sx={{
        background: "white",
        height: "max-content",
      }}
    >
      <CTabView
        data={[
          { label: "Attendance", component: <AttendancePanel /> },
          { label: "Progress", component: <ProgressPanel /> },
            { label: "Event", component: <EventPanel /> },
            { label: "Assignment", component: <AssignmentPanel /> },
        ]}
      />
    </Box>
  );
};
