import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "src/configs/firebase";
import { BaseUrl } from "src/constants";
import * as CONSTANTS from "src/constants";
import { sendRequestJSON } from "src/lib/axios";

import { setProductState, setServiceModel } from ".";
import { setEndUserClusterInfoSP } from "../auth";
import { fetchClusterInfo } from "../auth/thunks";
import { toastMessage } from "../commonSlice";
import {
  IProductSlice,
  ProductFilterModel,
  ProductSearchInformationConfig,
} from "./types";

const getServerListForServiceProviderByAdditionalType = (
  euci: any,
  type: string | null,
  dispatch
): string[] | null => {
  if (euci === null) {
    dispatch(
      toastMessage({
        error: true,
        message: "Unable to determine Cluster Info",
      })
    );
    return null;
  }

  if (type === null) {
    return euci.serviceprovider;
  }

  switch (type) {
    case CONSTANTS.prodat_cnamedef:
      return euci.product;
    case "REALESTATE":
      return euci.realestate;
    case "PET":
      return euci.pet;
    case CONSTANTS.prodat_homestay:
    case CONSTANTS.prodat_hotel:
    case CONSTANTS.prodat_hospital:
    case CONSTANTS.prodat_banquet:
    case CONSTANTS.prodat_farm:
      return euci!.rentalh;
    case "JOB":
      return euci.job;
    case "VEHICLE":
      return euci.vehicle;
    case CONSTANTS.prodat_classifiedprod:
      return euci.cproduct;
    case CONSTANTS.prodat_farmproduct:
      return euci.farmprod;
    case "REPO":
      return euci.repo;
    case "TRIP":
      return euci.trip;
    case "spp": 
    case CONSTANTS.prodat_lab:
    case CONSTANTS.prodat_doctor:
    case CONSTANTS.prodat_foodjoint:
    case CONSTANTS.prodat_medicine: 
    case CONSTANTS.prodat_genericservice:
    case CONSTANTS.prodat_gen:
    case CONSTANTS.prodat_rentprod:
      return euci.product;
    default:
      return euci.serviceprovider;
  }
};

const getServerListForCname = async (
  cname: string,
  addtype: string = null,
  enduserclusterInfoMap,
  enduserclusterInfoMapSP,
  dispatch
) => {
  if (enduserclusterInfoMapSP[cname]) {
    const res = getServerListForServiceProviderByAdditionalType(
      enduserclusterInfoMapSP[cname],
      addtype,
      dispatch
    );
    if (!res) {
      return null;
    }
    return res;
  } else {
    const kd = await getEndUserClusterInfoSP(
      cname,
      enduserclusterInfoMap,
      enduserclusterInfoMapSP,
      dispatch
    );
    if (!kd) {
      dispatch(
        toastMessage({
          error: true,
          message:
            "Unable to locate servers for ServiceProvider, please contact admin",
        })
      );
      return null;
    }
  }
};

const getEndUserClusterInfoSP = async (
  clustername: string,
  enduserclusterInfoMap,
  enduserclusterInfoMapSP,
  dispatch
) => {
  // if (enduserclusterInfoMap[clustername]) {
  //   return enduserclusterInfoMapSP[clustername];
  // }

  let enduserclusterInfoSP = {};
  const kd: any = await getDoc(doc(db, "ENDUSERCLUSTERINFOSP", clustername));
  if (kd.exists()) {
    let data = kd.data();
    if (data.adata) {
      Object.keys(data.adata).forEach((key) => {
        data[key] = data.adata[key];
      });
    }
    enduserclusterInfoSP[clustername] = data;
    dispatch(setEndUserClusterInfoSP(enduserclusterInfoSP));
    // dispatch(setEndUserClusterInfo(enduserclusterInfoMap));
    return [enduserclusterInfoSP, data];
  } else {
    return null;
  }
};

function toJsonTimeStamp(time) {
  if (time !== null && time instanceof Date) {
    const utcTime = time.toISOString(); // Convert to UTC string
    const millisecondsSinceEpoch =
      new Date(utcTime).getTime() -
      new Date(utcTime).getTimezoneOffset() * 60000; // Get milliseconds since epoch
    return Math.round(millisecondsSinceEpoch / 1000);
  }
  return null;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Earth radius in kilometers

  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceInKilometers = R * c;
  // If you want the distance in meters, multiply by 1000
  const distanceInMeters = distanceInKilometers * 1000;

  return distanceInMeters;
}

export const fetchProductFilterResultP = createAsyncThunk(
  "product/fetchProductFilterResultP",
  async (payload: any, { dispatch, getState }) => {
    dispatch(setServiceModel(payload));

    const loginState = (getState() as any).loginInformation;
    const state: IProductSlice = (getState() as any).product;

    let kd: ProductFilterModel = {
      psc: {
        ...state.productState.productSearchInformationConfig,
        lsm: payload.serviceModel,
      },
      atyp: payload.prodtype,
      filtertype: 1,
      facetparalist: [],
      termquery: "",
      sortf: null,
      filters: [],
      serviceproviderid: payload.serviceModel.serviceID,
      isservice: payload.isService,
      offset: payload?.offset ?? 0,
    };

    //     var um = await HelpUtil.getUserRepository()
    const um = await getEndUserClusterInfoSP(
      payload.serviceModel.cname,
      loginState.endUserClusterInfo,
      loginState.endUserClusterInfoSP,
      dispatch
    );
    const newEndUserClusterInfoSP = um[0];
    const newUm = um[1];
    if (newUm === null) {
      dispatch(
        toastMessage({
          error: true,
          message: "Unable to find the ServiceProvider, please try again",
        })
      );
      return;
    }

    let serverList = [];
    if (um) {
      serverList = await getServerListForCname(
        payload.serviceModel.cname,
        payload?.prodtype ?? null,
        loginState.endUserClusterInfo,
        newEndUserClusterInfoSP,
        dispatch
      );
      if (!serverList) return;
    }

    if (serverList.length === 0) {
      dispatch(
        toastMessage({
          error: true,
          message: "Unable to locate servers, please contact admin",
        })
      );
      return;
    }

    let filterResultModel;
    try {
      let response = await sendRequestJSON(
        { dynamicProperty: kd },
        "/api/Search/fetchProductFilterResult",
        "post",
        BaseUrl.custom,
        serverList[0],
        newEndUserClusterInfoSP[payload.serviceModel.cname]?.docid ?? ""
      );
      filterResultModel = response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
    const a = {
      list1: filterResultModel?.tousecatinfo,
      curpath: ["A1"],
      curindex: 0,
    };

    kd = {
      ...kd,
      filtertype: 0,
    };

    dispatch(
      setProductState({
        loading: false,
        mycurcatinfo: a,
        productFilterModel: kd,
        productFilterResultModel: filterResultModel,
      })
    );

    return true;
  }
);

const getCoords = async () => {
  const pos: any = await new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      return {
        long: 0,
        lat: 0,
      };
    }
  });

  return {
    long: pos?.coords?.longitude,
    lat: pos?.coords?.latitude,
  };
};

export const rentalSpFilterGetDataEvent = createAsyncThunk(
  "product/rentalSpFilterGetDataEvent",
  async (payload: any, { dispatch, getState }) => {
    const state: IProductSlice = (getState() as any).product;

    const locationData = payload.location;

    const productState = (getState() as any).product;
    const loginState = (getState() as any).loginInformation;

    const token = loginState.user?.stsTokenManager?.accessToken;

    const locationServerMap = await getLocationServers("INDIA");

    const updateStateWithLocation = async () => {
      let psc = {
        ...state.productState.productSearchInformationConfig,

        atyp: state.productState.productSearchInformationConfig?.atyp ?? null,
        cust: state.productState.productSearchInformationConfig?.cust ?? null,
        edt: state.productState.productSearchInformationConfig?.edt ?? null,
        entrypoint:
          state.productState.productSearchInformationConfig?.entrypoint ?? null,
        favsidlist:
          state.productState.productSearchInformationConfig?.favsidlist ?? null,
        forsid:
          state.productState.productSearchInformationConfig?.forsid ?? null,
        grouptype:
          state.productState.productSearchInformationConfig?.grouptype ?? null,
        isrepo:
          state.productState.productSearchInformationConfig?.isrepo ?? null,
        isservice:
          state.productState.productSearchInformationConfig?.isservice ?? null,
        isshop:
          state.productState.productSearchInformationConfig?.isshop ?? null,
        lsm: state.productState.productSearchInformationConfig?.lsm ?? null,
        sdt: state.productState.productSearchInformationConfig?.sdt ?? null,
        servicesselected:
          state.productState.productSearchInformationConfig?.servicesselected ??
          null,
        servicetype:
          state.productState.productSearchInformationConfig?.servicetype ??
          null,
        sp: state.productState.productSearchInformationConfig?.sp ?? null,
        spoffset:
          state.productState.productSearchInformationConfig?.spoffset ?? null,
      };

      const now = new Date();
      const stateTimestamp = state.productState.secondfromepoch * 1000; // Convert seconds to milliseconds
      const lastimediff = Math.abs(now.getTime() - stateTimestamp);
      if ([null, undefined].includes(psc!.sp) || lastimediff > 360000) {
        // const locationData = await getCoords();
        // const locationData = {
        //   long: 0,
        //   lat: 0,
        // };
        let newspatial = null;
        if (psc.sp !== null) {
          let distanceInMeters = calculateDistance(
            psc.sp!.lati!,
            psc.sp!.lati!,
            locationData.lat,
            locationData.long
          );

          if (distanceInMeters > 500) {
            const mState = await getLocationInformationInitiate(
              {
                Latitude: locationData.lat,
                Longitude: locationData.long,
              },
              token,
              locationServerMap["locationservers"],
              "l" + "@@" + locationServerMap["cid"]
            );

            const r = mState.split("@");
            const cState = r[1];
            const cdist = r[0];

            // const locationResponse = await sendRequestJSON(
            //   {
            //     Latitude: locationData.lat ?? 0,
            //     Longitude: locationData.long ?? 0,
            //   },
            //   "/Search/GetLocationInformation",
            //   "post",
            //   BaseUrl.ubuntu,
            // );
            if (mState === null) return null;
            newspatial = {
              ...psc.sp,
              lati: locationData.lat,
              longi: locationData.long,
              statename: cState,
              curDistrictname: cdist,
              curStatename: cState,
            };
          } else {
            if (psc.sp!.statename !== null) {
              newspatial = {
                ...psc.sp,
                lati: locationData.lat,
                longi: locationData.long,
              };
            } else {
              const mState = await getLocationInformationInitiate(
                {
                  Latitude: locationData.lat,
                  Longitude: locationData.long,
                },
                token,
                locationServerMap["locationservers"],
                "l" + "@@" + locationServerMap["cid"]
              );

              const r = mState.split("@");
              const cState = r[1];
              const cdist = r[0];

              // const locationResponse = await sendRequestJSON(
              //   {
              //     Latitude: locationData.lat,
              //     Longitude: locationData.long,
              //   },
              //   "/Search/GetLocationInformation",
              //   "post",
              //   BaseUrl.ubuntu
              // );
              if (mState === null) return null;

              newspatial = {
                ...psc.sp,
                lati: locationData.lat,
                longi: locationData.long,
                statename: cState,
                curDistrictname: cdist,
                curStatename: cState,
              };
            }
          }
        } else {
          const mState = await getLocationInformationInitiate(
            {
              Latitude: locationData.lat,
              Longitude: locationData.long,
            },
            token,
            locationServerMap["locationservers"],
            "l" + "@@" + locationServerMap["cid"]
          );

          const r = mState.split("@");
          const cState = r[1];
          const cdist = r[0];

          newspatial = {
            ...psc.sp,
            lati: locationData.lat,
            longi: locationData.long,
            statename: cState,
            curDistrictname: cdist,
            curStatename: cState,
          };
        }

        return newspatial;
      }
      if (psc!.sp === null) return null;
      else return psc.sp;
    };

    let productFilterResultModel = {
      docwithdata1: [],
      docwithdata2: [],
      docwithdata3: [],
      docwithdata4: [],
      docwithdata5: [],
      rentalpagedata: null,
    };

    let newstate = {
      ...state.productState,
      atype: payload.rrfi.servicetype,
      productFilterResultModel: productFilterResultModel,
      error: "",
    };
    dispatch(setProductState(newstate));
    let mystate = state.productState?.productSearchInformationConfig?.sp;
    mystate = await updateStateWithLocation();

    if (mystate === null) {
      dispatch(
        toastMessage({
          error: true,
          message:
            "Please try again, unable to get current location from phone",
        })
      );
      return;
    }

    let psc = {
      lsm: null,
      sp: mystate,
      isshop: true,
      atyp: payload.rrfi.servicetype,
      grouptype: payload.rrfi.servicetype,
    } as ProductSearchInformationConfig;

    let filtermodel = {
      psc: psc,
      rpsf: {
        ...payload.rrfi,
        locstate:
          payload.rrfi.usecurloc !== null && payload.rrfi.usecurloc
            ? mystate.statename
            : "UseCur",
      },
    };

    newstate = {
      ...state.productState,
      productFilterResultModel: productFilterResultModel,
      productFilterModel: filtermodel,
      productSearchInformationConfig: psc,
      error: "",
    };
    dispatch(setProductState(newstate));

    const params = {
      ...filtermodel,
      psc: { ...psc, spoffset: 0 },
    };
    dispatch(
      toastMessage({
        message: JSON.stringify({
          dynamicProperty: {
            psc: params.psc,
            rpsf: {
              ...params.rpsf,
              sdt: toJsonTimeStamp(
                // addMinutes(
                CONSTANTS.getJustDatePart(params?.rpsf?.sdt)
                // , -30)
              ),
              edt: toJsonTimeStamp(
                // addMinutes(
                CONSTANTS.getJustDatePart(params?.rpsf?.edt)
                // , -30)
              ),
            },
          },
        }),
      })
    );
    const serverList =
      loginState.endUserClusterInfo[
        `INDIA#${
          productState.productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
          "GEORGIA"
        }`
      ]?.rentalh ?? [];

    const cid =
      loginState.endUserClusterInfo[
        `INDIA#${
          productState.productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
          "GEORGIA"
        }`
      ]?.docid ?? "";

    let server = "";
    if (serverList.length > 0) {
      if (serverList.length === 1) server = serverList[0];
      else server = serverList[getRandomInt(0, serverList.length - 1)];
    }
    let response = await sendRequestJSON(
      {
        dynamicProperty: {
          psc: params.psc,
          rpsf: {
            ...params.rpsf,
            sdt: toJsonTimeStamp(
              // addMinutes(
              CONSTANTS.getJustDatePart(params?.rpsf?.sdt)
              //  -30)
            ),
            edt: toJsonTimeStamp(
              // addMinutes(
              CONSTANTS.getJustDatePart(params?.rpsf?.edt)
              // , -30)
            ),
          },
        },
      },
      "/api/Search/FetchProductFilterResultRentalProduct",
      "post",
      BaseUrl.custom,
      server,
      cid
    );
    let filterResultModel = response.result.data;
    filterResultModel = filterResultModel ?? productFilterResultModel;
    newstate = {
      ...newstate,
      productSearchInformationConfig: psc,
      productFilterModel: filtermodel,
      productFilterResultModel: {
        ...filterResultModel,
        topleveltiletype:
          state.productState.productSearchInformationConfig?.atyp,
      },
    };
    dispatch(setProductState(newstate));
    return true;
  }
);

export const rentalSpSelectedEvent = createAsyncThunk(
  "product/rentalSpSelectedEvent",
  async (payload: any, { dispatch, getState }) => {
    const state: IProductSlice = (getState() as any).product;
    const loginState = (getState() as any).loginInformation;

    const serverList =
      loginState.endUserClusterInfo[
        `INDIA#${
          state.productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
          "GEORGIA"
        }`
      ]?.rentalh ?? [];

    const cid =
      loginState.endUserClusterInfo[
        `INDIA#${
          state.productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
          "GEORGIA"
        }`
      ]?.docid ?? "";

    let server = "";
    if (serverList.length > 0) {
      if (serverList.length === 1) server = serverList[0];
      else server = serverList[getRandomInt(0, serverList.length - 1)];
    }

    let filterResultModel;
    try {
      let response = await sendRequestJSON(
        {
          dynamicProperty: {
            ...state.productState.productFilterModel,
            rpsf: {
              ...state.productState.productFilterModel.rpsf,
              spid: payload.sid,
              sdt: toJsonTimeStamp(
                // addMinutes(
                CONSTANTS.getJustDatePart(
                  state.productState?.productFilterModel?.rpsf?.sdt
                  // ),
                  // -30
                )
              ),
              edt: toJsonTimeStamp(
                // addMinutes(
                CONSTANTS.getJustDatePart(
                  state.productState.productFilterModel.rpsf.edt
                  // ),
                  // -30
                )
              ),
            },
          },
        },
        "/api/Search/FetchProductFilterResultSingleRentalProduct",
        "post",
        BaseUrl.custom,
        server,
        cid
      );
      filterResultModel = response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
    if (filterResultModel.rpds?.activesp !== null) {
      dispatch(
        setProductState({
          hasError: false,
          productFilterResultModel: {
            ...filterResultModel,
            rpds: {
              ...state.productState.productFilterResultModel.rpds,
              activesp: filterResultModel.rpds?.activesp,
            },
          },
        })
      );
    } else {
      dispatch(
        setProductState({
          hasError: true,
          error: "The Resource is no longer available",
          productFilterResultModel: {
            ...filterResultModel,
            rpds: {
              ...state.productState.productFilterResultModel.rpds,
              activesp: filterResultModel.rpds?.activesp,
            },
          },
        })
      );
    }
    return true;
  }
);

async function getLocationServers(mcountry: string): Promise<any> {
  try {
    const documentSnapshot = await getDoc(
      doc(db, `ENDUSERLOCATIONSERVERS/${mcountry}`)
    );

    const mdata = documentSnapshot.data();
    const locationservers: string[] =
      mdata !== null && mdata["data"] !== null
        ? (mdata["data"] as string[])
        : [];
    const cid = mdata !== null && mdata["cid"] !== null ? mdata["cid"] : "";

    return { locationservers: locationservers, cid: cid };
  } catch (error) {
    // Handle the error as needed
    console.error("Error in getLocationServers:", error);
    return null;
  }
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getServerFromServerList = (serverlist: string[]) => {
  if (serverlist.length > 0) {
    let server: any = null;
    if (serverlist.length === 1) {
      server = serverlist[0];
    } else {
      server = serverlist[getRandomInt(0, serverlist.length - 1)];
    }
    return server;
  }
  return "";
};

const getLocationInformationInitiate = async (
  locationModel,
  token: string,
  serverlist: string[],
  cid: string
) => {
  if (serverlist.length > 0) {
    let server: any = null;
    if (serverlist.length === 1) {
      server = serverlist[0];
    } else {
      server = serverlist[getRandomInt(0, serverlist.length - 1)];
    }

    try {
      const locationResponse = await sendRequestJSON(
        {
          Latitude: locationModel.Latitude,
          Longitude: locationModel.Longitude,
        },
        "/api/Search/GetLocationInformation",
        "post",
        BaseUrl.custom,
        server,
        cid
      );

      if (
        locationResponse.result.status >= 200 &&
        locationResponse.result.status < 300
      ) {
        if (
          locationResponse.result?.data === "" ||
          !locationResponse.result?.data
        ) {
          return "Georgia";
        } else {
          return locationResponse.result?.data;
        }
      }
      return "Georgia";
    } catch (e) {
      return "Georgia";
    }
  }
};

export const updateSearchConfigWithSpatial = createAsyncThunk(
  "product/updateSearchConfigWithSpatial",
  async (payload: any, { dispatch, getState }) => {
    try {
      const productState = (getState() as any).product;
      const loginState = (getState() as any).loginInformation;

      const locationData = {
        Longitude: payload.productSearchInformationConfig.sp!.longi,
        Latitude: payload.productSearchInformationConfig.sp!.lati,
      };

      const token = loginState.user?.stsTokenManager?.accessToken;

      const locationServerMap = await getLocationServers("INDIA");

      const mState = await getLocationInformationInitiate(
        locationData,
        token,
        locationServerMap["locationservers"],
        "l" + "@@" + locationServerMap["cid"]
      );

      const r = mState.split("@");
      const cState = r[1];
      const cdist = r[0];
      const response = await dispatch(fetchClusterInfo(cState));

      const kd = {
        curStatename: cState,
        curDistrictname: cdist,
        statename: cState,
        longi: locationData.Longitude,
        lati: locationData.Latitude,
      };
      if (!response.payload) {
        let psc1 = null;

        if (!productState.productSearchInformationConfig) {
          psc1 = {
            ...payload.productSearchInformationConfig,
            sp: kd,
          };
        } else {
          psc1 = {
            ...productState.productSearchInformationConfig,
            sp: productState.productSearchInformationConfig.sp
              ? {
                  ...productState.productSearchInformationConfig.sp,
                  statename: cState,
                  curStatename: productState.productSearchInformationConfig.sp
                    .aii
                    ? productState.productSearchInformationConfig.sp
                        .curStatename
                    : cState,
                  longi: locationData.Longitude,
                  lati: locationData.Latitude,
                }
              : kd,
          };
        }
        dispatch(
          setProductState({
            ...productState.productState,
            secondfromepoch: Date.now(),
            productSearchInformationConfig: psc1,
          })
        );
        return cState;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
);
