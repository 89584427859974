import { createContext, useContext, useState, useEffect } from "react";

interface DisplaySettingsContextType {
  heading1: number | string;
  heading2: number | string;
  heading3: number | string;
  text1: number | string;
  text2: number | string;
  text3: number | string;
  backgroundColor: string;
  foregroundColor: string;
  textColor: string;
}

const DisplaySettingsContext = createContext<DisplaySettingsContextType | undefined>(undefined);

export const useDisplaySettingsContext = (): DisplaySettingsContextType => {
  const context = useContext(DisplaySettingsContext);
  if (!context) {
    throw new Error(
      "useDisplaySettingsContext must be used within a DisplaySettingsProvider"
    );
  }
  return context;
};
export const DisplaySettingsProvider = ({ children }) => {
  const [value, setValue] = useState({
    heading1: "28px",
    heading2: "22px",
    heading3: "16px",
    text1: "14px",
    text2: "12px",
    text3: "10px",
    backgroundColor: "#ffffff",
    // foregroundColor: "#05aa6d",
    foregroundColor: "#014876",
    textColor: '#FFFFFF'
  });
  
  return (
    <DisplaySettingsContext.Provider value={{ ...value }}>
      {children}
    </DisplaySettingsContext.Provider>
  );
};
