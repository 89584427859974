import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { signInAnonymously } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import CToastInfoDialog from "./components/CToastInfoDialog";
import CustomLoader from "./components/CustomLoader";
import { TopBar } from "./components/TopBar";
import { auth } from "./configs/firebase";
import { Slices } from "./constants";
import { useCurrentLocation } from "./hooks/useCurrentLocation";
import { selectIsAppointmentLoading } from "./slices/appointment/selectors";
import { setLoginUser } from "./slices/auth";
import { selectIsCartLoading } from "./slices/cart/selectors";
import { toastMessage } from "./slices/commonSlice";
import { selectIsHomeLoading } from "./slices/home/selectors";
import { selectIsWebpageDataLoading } from "./slices/landing-page/selectors";
import { selectIsHistoryLoading } from "./slices/order-history/selectors";
import {
  selectIsProductLoading,
  selectProductState,
} from "./slices/product/selectors";
import { updateSearchConfigWithSpatial } from "./slices/product/thunks";
import { ProductState } from "./slices/product/types";
import { selectIsTripLoading } from "./slices/trip/selectors";
import { AppDispatch, RootState } from "./store";
import { selectIsUserPanelLoading } from "./slices/user-panel/selectors";

function DashboardContent() {
  const location = useLocation();
  const locationData = useCurrentLocation();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isBaseUrl = useMemo(() => location.pathname === "/", [location]);

  const { isLoggedIn, user } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );

  const productState: ProductState = useSelector(selectProductState);

  useEffect(() => {
    if (isLoggedIn) {
      if (locationData.latitude !== 0 && locationData.longitude !== 0) {
        const p = {
          ...productState.productSearchInformationConfig,
          cust: user.uid,
          sp: {
            lati: locationData?.latitude,
            longi: locationData?.longitude,
          },
        };
        dispatch(
          updateSearchConfigWithSpatial({ productSearchInformationConfig: p })
        );
      }
    }
  }, [locationData, isLoggedIn]);

  const [showLogoutIcon, setShowLogoutIcon] = useState(false);

  useEffect(() => {
    // fetchClusterInfo("GEORGIA");
    if (!auth?.currentUser?.uid) {
      loginAnonymouslyAPI();
    } else {
      setShowLogoutIcon(true);
    }
  }, []);

  const loginAnonymouslyAPI = async () => {
    try {
      const persistedUser = JSON.parse(localStorage.getItem("user"));
      if (persistedUser) {
        if (!persistedUser.isAnonymous) {
          setShowLogoutIcon(true);
        }
        dispatch(setLoginUser({ isLoggedIn: true, user: persistedUser }));
        auth?.updateCurrentUser(persistedUser);
        return;
      }
      const userCredential = await signInAnonymously(auth);
      localStorage.setItem("user", JSON.stringify(userCredential.user));
      dispatch(setLoginUser({ isLoggedIn: true, user: userCredential.user }));
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        dispatch(
          toastMessage({
            error: true,
            message: "Email is not correct. kindly enter a corrent email!",
          })
        );
      } else if (error.code === "auth/user-not-found") {
        dispatch(
          toastMessage({
            error: true,
            message: "Entered email is incorrect!",
          })
        );
      } else if (error.code === "auth/wrong-password") {
        dispatch(
          toastMessage({
            error: true,
            message: "Entered password is incorrect!",
          })
        );
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: error.message,
          })
        );
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <TopBar showLogoutIcon={showLogoutIcon} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />

        <Container
          maxWidth="xl"
          style={isBaseUrl ? { padding: 0 } : null}
          sx={{ p: isBaseUrl ? 0 : 2, my: isBaseUrl ? 0 : 1 }}
        >
          <Box sx={{ p: 0, display: "flex", flexDirection: "column" }}>
            <Outlet />
          </Box>
        </Container>

        {/* <Footer /> */}
      </Box>
    </Box>
  );
}

export default function App() {
  const isHomeLoading = useSelector((state: RootState) =>
    selectIsHomeLoading(state)
  );
  const isCartLoading = useSelector((state: RootState) =>
    selectIsCartLoading(state)
  );
  const isProductLoading = useSelector((state: RootState) =>
    selectIsProductLoading(state)
  );
  const isAppointmentLoading = useSelector((state: RootState) =>
    selectIsAppointmentLoading(state)
  );
  const isHistoryLoading = useSelector((state: RootState) =>
    selectIsHistoryLoading(state)
  );
  const isTripLoading = useSelector((state: RootState) =>
    selectIsTripLoading(state)
  );
  const isWebpageDataLoading = useSelector((state: RootState) =>
    selectIsWebpageDataLoading(state)
  );
  const isUserPanelLoading = useSelector((state: RootState) =>
    selectIsUserPanelLoading(state)
  );
  const { isLoading } = useSelector(
    (state: RootState) => state[Slices.COMMON_REDUCER]
  );
  return (
    <>
      {isLoading ||
      isHomeLoading ||
      isCartLoading ||
      isProductLoading ||
      isAppointmentLoading ||
      isHistoryLoading ||
      isWebpageDataLoading ||
      isUserPanelLoading ||
      isTripLoading ? (
        <CustomLoader />
      ) : null}
      <CToastInfoDialog />
      <DashboardContent />
    </>
  );
}
