import React, { useState, useCallback, useRef, useEffect, FC } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { ILatLng } from "src/slices/cart/types";

const mapStyle = {
  height: "60vh",
  width: "100%",
};

interface IAddressPicker {
  currentLocation?: ILatLng;
  onLocationSelected: (latlng: ILatLng) => void;
}

export const AddressPicker: FC<IAddressPicker> = ({
  currentLocation = {
    latitude: 0,
    longitude: 0,
  },
  onLocationSelected,
}) => {
  const DEFAULT_ZOOM = 15;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: currentLocation.latitude,
    lng: currentLocation.longitude,
  });

  const [geolocation, setGeolocation] = useState({
    lat: currentLocation.latitude,
    lng: currentLocation.longitude,
  });

  const inputRef = useRef();
  const autoComplete = new window.google.maps.places.Autocomplete(
    inputRef.current
  );

  autoComplete.addListener("place_changed", () => {
    const place = autoComplete.getPlace();
    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      // alert("this location not available");
    } else {
      if (place.geometry.viewport || place.geometry.location) {
        // do something
        onLocationSelected({
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        setGeolocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    }
  });

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handelClickOnMap = (e: google.maps.MapMouseEvent) => {
    onLocationSelected({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
    setGeolocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  useEffect(() => {
    if (map) {
      setMarkerPosition(geolocation);
      // const bounds = new window.google.maps.LatLngBounds(geolocation);
      //   map.fitBounds(bounds);
      //   map.zoom(5);
    }
  }, [geolocation]);

  const inputStyle = {
    boxShadow: "inset 0 0 10px #eee !important",
    border: "2px solid #eee",
    width: "456px",
    height: "40px",
    marginLeft: "16px",
    borderRadius: "20px",
    fontWeight: "300 !important",
    outline: "none",
    padding: "10px 20px",
    marginBottom: "10px",
  };

  return (
    <div>
      <label>Location</label>
      <input
        placeholder="type your location"
        ref={inputRef}
        style={inputStyle}
      />
      <div>
        {isLoaded ? (
          <GoogleMap
            onLoad={onLoad}
            center={geolocation}
            zoom={DEFAULT_ZOOM}
            mapContainerStyle={mapStyle}
            onClick={handelClickOnMap}
            onUnmount={onUnmount}
          >
            <MarkerF position={markerPosition} />
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
