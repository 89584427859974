import { Box, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CKeyValueList from 'src/components/CKeyValueList';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { clearProductFilterItemDetails } from 'src/slices/home';
import { selectIsHomeLoading, selectProductFilterItemDetails } from 'src/slices/home/selectors';
import { fetchProductFilterItemDetails } from 'src/slices/home/thunks';
import { AppDispatch } from 'src/store';

export function JobDetailsPage() {
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();
  
  const selectIsLoading = useSelector(selectIsHomeLoading);
  const jobDetails = useSelector(selectProductFilterItemDetails);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productid = queryParams.get("productid");
  const serviceid = queryParams.get("serviceid");
  const userid = queryParams.get("userid");

  const [selectVehicleData, setSelectVehicleData] = useState<any>();

  const [selectedImage, setSelectedImage] = useState<string>();

  useEffect(() => {
    dispatch(
      fetchProductFilterItemDetails({
        data: {
          productactionrequest: {
            qtype: null,
            origin: "SERVICEPROVIDERINFO",
            entitytype: "SERVICEPROVIDERINFO",
            serviceid: serviceid,
            userid: userid,
            producttype: "JOB",
            classifiedtype: "JOB",
            productid: productid,
          },
          qrytype: "GETSINGLEDSPRODUCT",
        },
      })
    );
    return () => {
      dispatch(clearProductFilterItemDetails());
    };
  }, []);

  useEffect(() => {
    if (
      jobDetails &&
      jobDetails.data.jolist &&
      jobDetails.data.jolist.length !== 0
    ) {
      setSelectVehicleData(jobDetails.data.jolist[0]);
      setSelectedImage(jobDetails.data.jolist[0].adata.companylogo);
    }
  }, [jobDetails]);

  return (
    <>
      {selectVehicleData ? (
        <>
          <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
                <Box
                  minHeight={isMobile ? "200px" : "200px"}
                  minWidth={isMobile ? "100%" : "200px"}
                  sx={{
                    borderRadius: "5px",
                    marginRight: "10px",
                    backgroundImage: `url(${selectedImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: isMobile ? "contain" : "contain",
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={3}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    display: "flex",
                    flexDirection: "row",
                  },
                }}
              >
                <Box
                  onClick={() => {
                    setSelectedImage(selectVehicleData.adata.companylogo);
                  }}
                  minHeight={isMobile ? "50px" : "150px"}
                  minWidth={isMobile ? "50px" : "150px"}
                  maxHeight="400px"
                  sx={{
                    overFlow: "auto",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    backgroundImage: `url(${selectVehicleData.adata.companylogo})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: isMobile ? "contain" : "contain",
                    border:
                      selectedImage === selectVehicleData.adata.companylogo
                        ? "1px solid black"
                        : "",
                    "&:hover": {
                      border: "1px solid black",
                    },
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={text1}>
                  {selectVehicleData.adata.title}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Description",
                      value: selectVehicleData.adata.description,
                    },
                    {
                      key: "Company Name",
                      value: selectVehicleData.adata.companyname,
                    },
                    {
                      key: "Fresher Allowed",
                      value: selectVehicleData.adata.arefreshersallowed
                        ? "Yes"
                        : "No",
                    },
                    {
                      key: "Years of Experience",
                      value: selectVehicleData.adata.minyearexperience,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Min Salary",
                      value: selectVehicleData.adata.minsalaryrange,
                    },
                    {
                      key: "Max Salary",
                      value: selectVehicleData.adata.maxsalaryrange,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Address",
                      value:
                        selectVehicleData.adata.contactdetails.address
                          .addressline,
                    },
                    {
                      key: "Email",
                      value: selectVehicleData.adata.contactdetails.email,
                    },
                    {
                      key: "Phone",
                      value: selectVehicleData.adata.contactdetails.phonenum,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
}
