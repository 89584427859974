import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Slices } from "../constants";

export interface IinitialState {
  dialog: any;
}

const initialState: IinitialState = {
  dialog: {
    confirmApproval: false,
    confirmRejection: false,
    ConfirmDelete: false,
  },
};

const slice = createSlice({
  name: Slices.UI_SETTINGS,
  initialState,
  reducers: {
    setDialog(state, action: PayloadAction<any>): void {
      const { open, dialogName } = action.payload;
      state.dialog[dialogName] = open;
    },
  },
});

export const { reducer } = slice;
export const { setDialog } = slice.actions;
