import { Box, Rating, Stack, Typography, styled } from "@mui/material";
import RatingBar from "./RatingBar";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const TypographyHeading = styled(Typography)(() => ({
  fontWeight: 600,
  lineHeight: "38px",
  color: "white",
}));

const TypographyTitle = styled(Typography)(() => ({
  fontWeight: 400,
  color: "white",
}));

const OverallRating = () => {
  const { heading1, text2 } = useDisplaySettingsContext();
  
  const RatingBarComponent = ({ index, value }) => (
    <Stack
      direction="row"
      alignItems="center"
      gap="12px"
      sx={{ cursor: "pointer" }}
    >
      <TypographyTitle>{index} star</TypographyTitle>
      <Box sx={{ width: "280px" }}>
        <RatingBar value={value} />
      </Box>
    </Stack>
  );

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="12px"
      sx={{ width: "414px" }}
    >
      <TypographyHeading fontSize={heading1}>Review</TypographyHeading>
      <Stack direction="row" alignItems="center" gap="12px">
        <Rating size="large" name="read-only" value={3} readOnly />
        <TypographyTitle fontSize={text2}>4.7 out of 5</TypographyTitle>
      </Stack>
      <TypographyTitle fontSize={text2}>400 customer reviews</TypographyTitle>
      <RatingBarComponent index={5} value={50} />
      <RatingBarComponent index={4} value={40} />
      <RatingBarComponent index={3} value={30} />
      <RatingBarComponent index={2} value={20} />
      <RatingBarComponent index={1} value={10} />
    </Stack>
  );
};

export default OverallRating;
