import { FC } from "react";
import PropTypes from "prop-types";
import InputField from "./InputField";
import Textarea from "./Textarea";
import SelectField from "./SelectField";
import SelectMultipleFields from "./SelectMultipleFields";
import CalendarTimeDate from "./CalendarTimeDate";

// import RadioBtn from './RadioBtn';
// import CheckBoxGrp from './CheckBoxGrp';
import PasswordInputField from "./PasswordInputFeild";
// import VirtualizedSelectField from './VirtualizedSelectField';
// import SearchableSelect from './SearchableSelect';
import VirtualizedSearchableSelect from "./VirtualizedSearchableSelect";
import RadioBtn from "./RadioBtn";
// import VirtualizedSelectField2 from './VirtualizedSelectField2';

interface IFormikControl {
  control?: string;
  label?: string;
  name?: string;
  error?: boolean;
  helperText?: any;
  groupLabel?: string;
  inlineGroupLabel?: boolean;
  rest?: any;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  options?: any[];
  defaultRadioBtn?: number;
  value?: string;
  showInRow?: boolean;
  size?: "small" | "medium";
  disabled?: boolean;
  handleDispatch?: Function;
  dateOrTimeOnly?: "date" | "time";
  readOnly?: boolean;
  defaultValue?: string;
  fullWidth?: boolean;
  views?: Array<"day" | "month" | "year">;
  handleChange?: any;
  placeholder?: string;
  minDateTime?: Date;
  maxDateTime?: Date;
  inputProps?: any;
  selectedvalue?: any;
  format?: string;
  radioLabelsx?: any;
  maxLength?: number;
  customOnBlur?: any;
  customOnFocus?: any;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const FormikControl: FC<IFormikControl> = (props) => {
  const {
    control,
    label,
    name,
    error,
    helperText,
    defaultRadioBtn,
    readOnly = false,
    showInRow = false,
    groupLabel = '',
    inlineGroupLabel = false,
    required,
    multiline,
    defaultValue,
    rows,
    value,
    size = 'small',
    options,
    disabled,
    handleDispatch,
    dateOrTimeOnly,
    fullWidth = true,
    views,
    handleChange,
    placeholder = "",
    minDateTime,
    maxDateTime,
    inputProps,
    radioLabelsx,
    maxLength,
    customOnBlur,
    customOnFocus,
    disableFuture,
    disablePast,
    ...rest
  } = props;
  // const { t } = useTranslation();
  switch (control) {
    case "InputField":
      return (
        <InputField
          disabled={disabled}
          label={label}
          inputProps={inputProps}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          readOnly={readOnly}
          placeholder={placeholder}
          customOnBlur={customOnBlur}
          customOnFocus={customOnFocus}
          onChange={handleChange}
          {...rest}
        />
      );
    // case 'SearchableSelect':
    // 	return (
    // 		<SearchableSelect
    // 			disabled={disabled}
    // 			label={label}
    // 			error={error}
    // 			name={name}
    // 			helperText={helperText}
    // 			required={required}
    // 			options={options}
    // 			reflectChange={handleChange}
    // 			{...rest}
    // 		/>
    // 	);
    case "VirtualizedSearchableSelect":
      return (
        <VirtualizedSearchableSelect
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          options={options}
          reflectChange={handleChange}
          {...rest}
        />
      );

    case "PasswordInputField":
      return (
        <PasswordInputField
          disabled={disabled}
          label={label}
          inputProps={inputProps}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          readOnly={readOnly}
          placeholder={placeholder}
          customOnBlur={customOnBlur}
          {...rest}
        />
      );

    case "Textarea":
      return (
        <Textarea
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          multiline={multiline}
          rows={rows}
          maxLength={maxLength}
          readOnly={readOnly}
          {...rest}
        />
      );

    case "SelectField":
      return (
        <SelectField
          readOnly={readOnly}
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          options={options}
          handleChange={handleChange}
          // selectedvalue={selectedvalue}
          {...rest}
        />
      );

    case "SelectMultipleFields":
      return (
        <SelectMultipleFields
          readOnly={readOnly}
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          options={options}
          handleChange={handleChange}
          // selectedvalue={selectedvalue}
          {...rest}
        />
      );

    // case 'checkbox':
    // 	return (
    // 		<CheckBoxGrp
    // 			inlineGroupLabel={inlineGroupLabel}
    // 			groupLabel={groupLabel}
    // 			options={options}
    // 			showInRow={showInRow}
    // 			size={size}
    // 			name={name}
    // 			helperText={helperText}
    // 			handleChange={handleChange}
    // 			error={error}
    // 			{...rest}
    // 		/>
    // 	);

    case 'Radio':
      return (
				<RadioBtn
					inlineGroupLabel={inlineGroupLabel}
					groupLabel={groupLabel}
					options={options}
					required={required}
					showInRow={showInRow}
					defaultValue={defaultValue}
					size={size}
					name={name}
					handleChange={handleChange}
					helperText={helperText}
					radioLabelsx={radioLabelsx}
					{...rest}
				/>
			);

    case "CalendarTime":
      // pass dateOrTimeOnly = "date" as prop to open datePicker only component
      // pass dateOrTimeOnly = "date" as prop to open timePicker only component
      // bydefault dateTimePicker works
      return (
        <CalendarTimeDate
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          helperText={helperText}
          required={required}
          dateOrTimeOnly={dateOrTimeOnly}
          readOnly={readOnly}
          fullWidth={fullWidth}
          views={views}
          disableFuture={disableFuture}
          disablePast={disablePast}
          {...rest}
        />
      );

    // case 'VirtualizedSelectField':
    // 	return (
    // 		<VirtualizedSelectField
    // 			readOnly={readOnly}
    // 			disabled={disabled}
    // 			label={label}
    // 			error={error}
    // 			name={name}
    // 			helperText={helperText}
    // 			required={required}
    // 			options={options}
    // 			handleChange={handleChange}
    // 			{...rest}
    // 		/>
    // 	);
    // // works with the default selection value
    // case 'VirtualizedSelectField2':
    // 	return (
    // 		<VirtualizedSelectField2
    // 			readOnly={readOnly}
    // 			disabled={disabled}
    // 			label={label}
    // 			error={error}
    // 			name={name}
    // 			helperText={helperText}
    // 			required={required}
    // 			options={options}
    // 			handleChange={handleChange}
    // 			{...rest}
    // 		/>
    // 	);

    default:
      return null;
  }
};

FormikControl.propTypes = {
  control: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rest: PropTypes.any,
  error: PropTypes.bool,
  required: PropTypes.bool,
  groupLabel: PropTypes.string,
  inlineGroupLabel: PropTypes.bool,
  helperText: PropTypes.any,
  defaultRadioBtn: PropTypes.number,
  showInRow: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  dateOrTimeOnly: PropTypes.oneOf(["date", "time"]),
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  views: PropTypes.any,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  minDateTime: PropTypes.any,
  maxDateTime: PropTypes.any,
  selectedvalue: PropTypes.any,
  format: PropTypes.string,
  maxLength: PropTypes.number,
  customOnBlur: PropTypes.func,
};

export default FormikControl;
