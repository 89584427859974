import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "src/configs/firebase";
import { BaseUrl } from "src/constants";
import * as CONSTANTS from "src/constants";
import { sendRequestJSON } from "src/lib/axios";

// import { updateHistory } from '.';
import { toastMessage } from "../commonSlice";
import { selectLsm } from "../landing-page/selectors";

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function toJsonTimeStamp(time) {
  if (time !== null && time instanceof Date) {
    const utcTime = time.toISOString(); // Convert to UTC string
    const millisecondsSinceEpoch = new Date(utcTime).getTime(); // Get milliseconds since epoch
    return Math.round(millisecondsSinceEpoch / 1000);
  }
  return null;
}

// attendance
export const loadAttendances = createAsyncThunk(
  "attendance/loadAttendances",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            acadmsg: {
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              idcard: payload?.idCardNumber?.id,
              sesterm: payload?.idCardNumber?.st,
              vr: payload?.idCardNumber?.vr,
              action: "getattedanceelectiveidcard",
              dt: toJsonTimeStamp(payload.startDate),
              edt: toJsonTimeStamp(payload.endDate),
            },
            qrytype: "ATTENDANCEDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

// progress
export const loadExamTerms = createAsyncThunk(
  "progress/loadExamTerms",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            acadmsg: {
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              action: "getinitialdataidcard",
            },
            qrytype: "PROGRESSDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const loadProgress = createAsyncThunk(
  "progress/loadProgress",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            acadmsg: {
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              kind: payload?.kind,
              idcard: payload?.idCardNumber?.id,
              sesterm: payload?.idCardNumber?.st,
              vr: payload?.idCardNumber?.vr,
              action: "getprogresselectiveidcard",
            },
            qrytype: "PROGRESSDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

// event
export const loadEvents = createAsyncThunk(
  "event/loadEvents",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            acadmsg: {
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              sesterm: payload?.idCardNumber?.st,
              vr: payload?.idCardNumber?.vr,
              action: "geteventidcard",
              dt: toJsonTimeStamp(payload.startDate),
            },
            qrytype: "DAILYEVENTDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

// assignment
export const loadSubjects = createAsyncThunk(
  "assignment/loadSubjects",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            gsi: {
              id: payload?.class,
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              action: "getsubjectforgrade",
            },
            qrytype: "GRADEINFODBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const loadAssignments = createAsyncThunk(
  "assignment/loadAssignments",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const loginState = (state as any).loginInformation;
    const lsm = selectLsm(state);
    try {
      const serverList =
        loginState.endUserClusterInfo[
          `INDIA#${payload.state?.toUpperCase() ?? "GEORGIA"}`
        ]?.serviceprovider ?? [];

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      const response = await sendRequestJSON(
        {
          dynamicProperty: {
            offset: 0,
            datatype: "STUSEARCHASG",
            qrytxt: null,
            entityid: lsm.serviceID,
            date: null,
            enddate: null,
            timeoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            nidlst: null,
            studentexe:
              payload.type === "ELECTION"
                ? {
                    vrname: payload.virtualRoom,
                    asgtype: payload.type,
                    atype: 1,
                    searchqry: payload.assignment,
                    isstu: true,
                  }
                : {
                    vrname: payload.virtualRoom,
                    offeringname: payload.subject,
                    asgtype: payload.type,
                    atype: 1,
                    searchqry: payload.assignment,
                    isstu: true,
                  },
          },
        },
        "/api/Search/GetTripRelatedLookups",
        "post",
        BaseUrl.custom,
        server
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const loadResults = createAsyncThunk(
  "assignment/loadResults",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              id: payload?.id,
              identifier: payload?.cnic,
            },
            qrytype: "GETINITIALDATAFORRESULTALLTYPEENDUSER",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const loadPracticeQuestions = createAsyncThunk(
  "assignment/loadPracticeQuestions",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              entitytype: "SERVICEPROVIDERINFO",
              exeasgaction: "PRACTICE",
              entityid: lsm?.serviceID,
              id: payload?.id,
              identifier: payload?.cnic,
            },
            qrytype: "GETINITIALDATAFOREXECUTEALLTYPEENDUSER",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);


export const loadPollQuestions = createAsyncThunk(
  "assignment/loadPracticeQuestions",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              entitytype: "SERVICEPROVIDERINFO",
              exeasgaction: "POLL",
              entityid: lsm?.serviceID,
              id: payload?.id,
              identifier: payload?.cnic,
            },
            qrytype: "GETINITIALDATAFOREXECUTEALLTYPEENDUSER",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);


export const submitPracticeQuestions = createAsyncThunk(
  "assignment/submitPracticeQuestions",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              asganswerlist: {
                asgid: payload.asgid,
                answerList: payload.answerList,
                // answerlist: [
                //   {
                //     id: "000001",
                //     date: 1716263042,
                //     name: "Mohan ",
                //     answerlist: [{ qid: "1", choices: [1], score: 0.0 }],
                //   },
                // ],
              },
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              id: payload.asgid,
            },
            qrytype: "ENDUSERSUBMITRESULTPOLLELECTIONKNOWLEDGE",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const executeSurvey = createAsyncThunk(
  "assignment/executeSurvey",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              entitytype: "SERVICEPROVIDERINFO",
              exeasgaction: "EXECUTE",
              entityid: lsm?.serviceID,
              id: payload?.id,
              identifier: payload?.cnic,
            },
            qrytype: "GETINITIALDATAFOREXECUTEALLTYPEENDUSER",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const submitElection = createAsyncThunk(
  "assignment/submitElection",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              asganswerlist: {
                asgid: payload.asgid,
                answerList: payload.answerList,
                // answerlist: [
                //   {
                //     id: "000001",
                //     date: 1716417517,
                //     name: "Mohan ",
                //     answerlist: [{ qid: "1", text: "user1 ", choices: [0] }],
                //   },
                // ],
              },
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              id: payload.asgid,
            },
            qrytype: "ENDUSERSUBMITRESULTPOLLELECTIONKNOWLEDGE",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const submitPoll = createAsyncThunk(
  "assignment/submitPoll",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState();
    const lsm = selectLsm(state);
    try {
      const response = await sendRequestJSON(
        {
          data: {
            dsrequest: {
              asganswerlist: {
                asgid: payload.asgid,
                answerList: payload.answerList,
                // answerlist: [
                //   {
                //     id: "000001",
                //     date: 1716484699,
                //     name: "Mohan ",
                //     answerlist: [{ qid: "1", choices: [0], score: 10.0 }],
                //   },
                // ],
              },
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
              id: payload.asgid,
            },
            qrytype: "ENDUSERSUBMITRESULTPOLLELECTIONKNOWLEDGE",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);
