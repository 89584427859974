import { productData } from "./providers/product";
import { LimitedServiceModel, RateRules } from "./slices/cart/types";
import { compareAsc, format, parseISO } from "date-fns";

const DEVICE_TYPES = {
  MOBILE: "MOBILE",
  TABLET: "TABLET",
  DESKTOP: "DESKTOP",
};

export enum BaseUrl {
  ubuntu,
  digital,
  custom
}

// URLs
export const BASE_URL_UBUNTU =
  "https://ubuntu-s-1vcpu-1gb-intel-nyc1-01.vthepeople.in/api";
export const BASE_URL_DIGITAL =
  // "https://digitaloceaninteractionweb-zrnpim3tdq-uc.a.run.app";
  "https://digitaloceaninteractionweb-6q6k7qenhq-em.a.run.app/api/main/handle";

// REGEXs
const REGEX_IS_NUMBER = /^\d+$/;

// Routes
const RoutePaths = {
  LOGIN: "/login",
  SIGN_UP: "/signup",
  REGISTER: "/register",
  OTP: "/otp",
};

// Slices
const Slices = {
  LOGIN_INFORMATION: "loginInformation",
  COMMON_REDUCER: "commonReducer",
  UI_SETTINGS: "uiSettings",
};

const GENDER = {
  M: "Male",
  F: "Female",
};

const ORDER_STATUS = {
  "NEW ORDER": "1",
  "READY FOR PICKUP": "2",
  "IN PROGRESS": "3",
  "READY FOR DELIVERY": "4",
  DELIVERED: "5",
};

// CONSTANT STRINGS
export const deloption_nodel = "NO-DELIVERY";

export const prodat_cnamedef = "DEFCNAME";
export const prodat_gen = "spp";
export const prodat_lab = "mls";
export const prodat_doctor = "dds";
export const prodat_genericservice = "sps";
export const prodat_rentprod = "RENTPROD";
export const prodat_farmproduct = "FARMPROD";
export const prodat_classifiedprod = "ccp";
export const prodat_foodjoint = "FOODJOINT";
export const prodat_repo = "REPOALL";
export const prodat_medicine = "medicine";
export const prodat_homestay = "HOMESTAY";
export const prodat_hotel = "HOTELROOM";
export const prodat_banquet = "BANQUET";
export const prodat_farm = "FARM";
export const prodat_realestate = "REALESTATE";
export const prodat_vehicle = "VEHICLE";
export const prodat_job = "JOB";
export const prodat_pet = "PET";

export const ecom_enduser_search = "textsearch";

export const prodat_hospital = "HOSPITALBED";
export const ecomhotel = "Hotel,Halls And Banquets";
export const ecomfarmandlivestock = "Farming And LiveStock";
export const ecomservices = "Services";
export const ecomfitness = "Fitness";
export const ecomlogistics = "Logistics";
export const ecommasstransit = "Mass Tranist";
export const ecomngo = "Groups and NGO";
export const ecomschool = "School,Tutoring And Academy";
export const ecombroker = "Agents/Brokers - RealEstate/Property/Job/Pet-Farm";
export const ecomrideshare = "Delivery And RideShare(Taxi)";
export const ecomindus = "Cottage And Small Scale Industry";
export const ecomprod = "Sell Product";
export const ecomrentprod = "Rent Product";

export const ecomstschool = "SCHOOL";
export const ecomstplayschool = "PLAYSCHOOL";
export const ecomrestrestuarnat = "Restuarant, Dhabha & Caterers";
export const ecomstresidentwelfare = "RESIDENT-WELFARE";
export const ecomstspwelfare = "SERVICEPROVIDER-WELFARE";
export const ecomstsmallscale = "SMALL SCALE INDUSTRY";

export const st_hotel = "HOTEL";
export const st_banquet = "BANQUET";
export const st_homestay = "HOMESTAY";
export const st_wedding = "WEDDING GARDEN & FARMHOUSE";
export const st_hosptial = "HOSPITAL";
export const st_medicine = "MEDICINE";
export const st_doctor = "DOCTOR";

export const st_medicallab = "MEDICAL-TEST";
export const st_realestate = "PROPERTY DEALER - REAL ESTATE AGENT";
export const st_job = "Placement Services";
export const st_pet = "PETS/FARM ANIMAL SALE";
export const st_vehicle = "USED VEHICLE SALE-PURCHASE";

export const apt_initialstate = "req";
export const apt_instorestate = "LINE";
export const apt_active = "active";
export const apt_close = "close";

export const apt_checkin = "checkIn";
export const apt_regular = "regularApt";
export const apt_homevisit = "homeVisit";

//this is producttype
export const sert_hotel = "HOTELROOM";
export const sert_banquet = "BANQUET";
export const sert_homestay = "HOMESTAY";
export const sert_wedding = "FARM";
export const sert_hospitalbed = "HOSPITALBED";
export const sert_rentalprod = "RENTPROD";

export const dispt_hotel = "Hotel";
export const dispt_banquet = "Banquet";
export const dispt_homestay = "HomeStay";
export const dispt_wedding = "Wedding Garden";
export const dispt_hospitalbed = "Hospital Bed";

export const deloption_thirdpartymanaged = "3rd-PARTY-MANAGED";

export const order_deloption_nodel = 0;
export const order_deloption_storemanaged = 1;
export const order_deloption_thirdpartymanaged = 2;

export const noimageurl =
"https://appstorage.sgp1.digitaloceanspaces.com/emptyimage/no-image-available.jpeg";

export const sphasappointmentenduser = (sm?: LimitedServiceModel) => {
  if (!sm) return false;

  if (sm.servicecat === ecomservices) {
    return true;
  }
  return false;
};

export const hasHotelInSt = (st?: string[]) => {
  if (!st) return false;
  if (st.includes(st_hotel)) {
    return true;
  } else return false;
};

export const hasHospitalInSt = (st?: string[]) => {
  if (st === null) return false;
  if (st.includes(st_hosptial)) {
    return true;
  } else
    return false;
};

export const hasBanquetInSt = (st?: string[]) => {
  if (st === null) return false;
  if (st.includes(st_banquet)) {
    return true;
  } else
    return false;
};

export const hasHomeStayInSt = (st?: string[]) => {
  if (st === null) return false;
  if (st.includes(st_homestay)) {
    return true;
  } else
    return false;
};

export const hasWeddingHallInSt = (st?: string[]) => {
  if (st === null) return false;
  if (st.includes(st_wedding)) {
    return true;
  } else
    return false;
};


export const lmspwanttosellproduct = (lsm?: LimitedServiceModel) => {
  if (!lsm) return false;
  if (lsm.servicecat === ecomprod) return true;
  else return false;
};

export const lmsphasfoodjoint = (lsm?: LimitedServiceModel) => {
  if (lsm.servicecat === ecomrestrestuarnat) return true;
  else return false;
};

export const lmsphasrentalprod = (lsm?: LimitedServiceModel) => {
  if (lsm.servicecat == ecomrentprod) return true;
  else return false;
};
export const lmsphasmedicine = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_medicine)) return true;
  else return false;
};
export const lmsphasdoctor = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_doctor)) return true;
  else return false;
};
export const lmsphasmedicallab = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_medicallab)) return true;
  else return false;
};

export const lmsphasjob = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_job)) return true;
  else return false;
};

export const lmsphasvehicle = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_vehicle)) return true;
  else return false;
};

export const lmsphasrealestate = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_realestate)) return true;
  else return false;
};

export const lmsphaspet = (lsm?: LimitedServiceModel) => {
  var st = lsm.servicetype ?? [];
  if (st.includes(st_pet)) return true;
  else return false;
};

export enum OrderDetailsOrigin {
  UNDEFINED,
  APT,
  SELL,
  SELLWITHDEL,
  COOK,
  SERVER,
  RENTAL,
  RENTALPRODUCT,
  RENTALPRODUCTDEL,
  HISTORY,

  SELLCOOK,
  CATEGORYPROP,
  REPO,
  ORDERFORSERVICE,
  RENTALENDUSER,
  RENTALPRODUCTENDUSER,
  SELLENDUSER,
  SELLWITHDELENDUSER,
};

export const getFinalDeliveryOptionForCartEndUserSide = (
  sm,
  prodtype
): OrderDetailsOrigin => {
  if ([prodat_doctor, prodat_lab, prodat_genericservice].includes(prodtype)) {
    return OrderDetailsOrigin.APT;
  } else {
    var pq = sm.servicecat;
    if (pq === ecomhotel) {
      return OrderDetailsOrigin.RENTAL;
    } else if (pq == ecomrentprod) {
      var pj = OrderDetailsOrigin.RENTALPRODUCT;
      if (
        sm.providerdelivery !== null &&
        sm.providerdelivery !== deloption_nodel &&
        sm.mindelcharges != null &&
        sm.delchargeperunit !== null
      ) {
        pj = OrderDetailsOrigin.RENTALPRODUCTDEL;
      }
      return pj;
    } else {
      if (
        sm.providerdelivery !== null &&
        sm.providerdelivery !== deloption_nodel &&
        sm.mindelcharges !== null &&
        sm.delchargeperunit !== null
      ) {
        return OrderDetailsOrigin.SELLWITHDEL;
      } else {
        return OrderDetailsOrigin.SELL;
      }
    }
  }
};

export const getRateForStartDateForRateRule = (
  rr: RateRules,
  sdt: Date,
  edt: Date
): number => {
  if (!rr) return 0;
  if (rr?.pratelist) {
    for (var k of rr!.pratelist!) {
      if (
        k.startdate != null &&
        compareAsc(sdt, k.startdate!) >= 0 &&
        compareAsc(edt, k.enddate!) <= 0
      ) {
        return k.gr ?? 0;
      }
    }
  }

  return 0;
};

export const getJustDatePart = (dt: Date) => {
  return new Date(`${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`);
};

export const toTimeStamp = (dateTime: any): number => {
  if (!dateTime) {
    const utcStart = new Date(Date.UTC(1970, 0, 1, 0, 0, 0));
    return Math.round(utcStart.getTime() / 1000);
  } else {
    if (dateTime.getTimezoneOffset() === 0) {
      return Math.round(dateTime.getTime() / 1000);
    } else {
      const utcMs =
        dateTime.getTime() - dateTime.getTimezoneOffset() * 60 * 1000;
      return Math.round(utcMs / 1000);
    }
  }
};

export function toJsonTimeStamp(time) {
  if (time !== null && time instanceof Date) {
    const utcTime = time.toISOString(); // Convert to UTC string
    const millisecondsSinceEpoch = new Date(utcTime).getTime() - new Date(utcTime).getTimezoneOffset() * 60000; // Get milliseconds since epoch
    return Math.round(millisecondsSinceEpoch / 1000);
  }
  return null;
}

export function formatDate(date: Date | null, formatString: string): string {
  if (!date) {
    return ''; // Handle null date
  }

  return format(date, formatString);
}

export function getJustDatePartWithTZAdjustment(
  dt: Date,
  tzoffset: number | null,
  applyoffset: boolean
): Date {
  if (!applyoffset) {
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  } else {
    const dt1 = new Date(dt.getTime() + (tzoffset ?? 0) * 1000);
    return new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate());
  }
}


export const fetchProductBySectionName = (sectionName: string) => {
  const data = productData.servicelistshop.filter(section => section.sectionname === sectionName);
  return data;
};


export function getRateForStartDate(lrsp, sdt, edt) {
  if (!lrsp) return 0;
  return getRateForStartDateForRateRule(lrsp.rr, sdt, edt);
}

export function getLimitedAddressStringFromAreaModel(address) {
  if (!address) return "No Address Defined";
  else {
    let myaddress = address.area_sector ? address.area_sector + ", " : "";
    myaddress += address.town_village ? address.town_village + ", " : "";
    myaddress += address.district ? address.district + ", " : "";
    myaddress += address.state ? address.state + ", " : "";
    return myaddress;
  }
}

// Example usage:
const lrsp = {}; // Replace with your LimitedRentalSPProd object
const sdt = new Date(); // Replace with your start date
const edt = new Date(); // Replace with your end date
const rate = getRateForStartDate(lrsp, sdt, edt);
console.log(rate);


export { REGEX_IS_NUMBER, RoutePaths, Slices, GENDER, ORDER_STATUS, DEVICE_TYPES };
