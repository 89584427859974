import { Box, Grid, TextField, Typography } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { auth } from "src/configs/firebase";
import { RoutePaths, Slices } from "src/constants";
import logo from "src/icons/logo.png";
import { setLoginUser } from "src/slices/auth";
import {
  initiateOTPFlowRequest,
  validateOTPRequest,
} from "src/slices/auth/thunks";
import { selectCartType } from "src/slices/cart/selectors";
import { toastMessage } from "src/slices/commonSlice";
import { selectSelectedAtypeCategory } from "src/slices/landing-page/selectors";
import { AppDispatch } from "src/store";
import * as Yup from "yup";

export const OTPPage = () => {
  const formikRef = useRef();
  const itemsRef = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { state } = useLocation();

  const { isLoggedIn } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validationSchemaModel = Yup.object({
    code: Yup.array().of(Yup.string().required("Code is required")),
  });

  useEffect(() => {
    dispatch(initiateOTPFlowRequest(state?.uid));
  }, []);

  // if (isLoggedIn && !auth?.currentUser?.isAnonymous) {
  //   // Redirect the user to the home page
  //   return <Navigate to="/" />;
  // }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ p: 0, m: 0, height: "95vh", backgroundColor: "#FAFAFA" }}
    >
      <Grid item xs={8} md={4}>
        <Formik
          initialValues={{
            code: ["", "", "", ""],
          }}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={async (values) => {
            dispatch(
              validateOTPRequest({
                uid: state?.uid,
                otpn: values.code.join(""),
              })
            ).then((response) => {
              const res = response?.payload?.data;
              if (!res?.errorid || res?.errorid !== -1) {
                dispatch(
                  toastMessage({
                    error: true,
                    message: "Unable to verify OTP",
                  })
                );
              } else {
                dispatch(
                  toastMessage({
                    message: "Successfully verified",
                  })
                );
                navigate("/");
              }
            });
          }}
        >
          {({ values, touched, errors, handleBlur, setFieldValue }) => (
            <Form id="Verification">
              <Grid container spacing={3} textAlign="center">
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={logo}
                    alt="logo"
                    height={100}
                    justifySelf="center"
                    sx={{
                      backgroundColor: "primary.main",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#1E1E1E",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    4 digit OTP sent to {state?.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#1E1E1E",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Please enter OTP here
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "grid",
                      columnGap: "16px",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      pt: 1,
                      height: "0.058%",
                    }}
                  >
                    {[1, 2, 3, 4].map((_, i) => (
                      <TextField
                        error={Boolean(
                          Array.isArray(touched.code) &&
                            touched.code.length === 4 &&
                            errors.code
                        )}
                        fullWidth
                        inputRef={(el) => (itemsRef.current[i] = el)}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`code-${i}`}
                        name={`code[${i}]`}
                        onBlur={handleBlur}
                        onKeyDown={(event) => {
                          if (event.code === "ENTER") {
                            if (values.code[i]) {
                              setFieldValue(`code[${i}]`, "");
                              return;
                            }

                            if (i > 0) {
                              setFieldValue(`code[${i}]`, "");
                              itemsRef.current[i - 1].focus();
                              return;
                            }
                          }

                          if (Number.isInteger(parseInt(event.key, 10))) {
                            setFieldValue(`code[${i}]`, event.key);

                            if (i < 3) {
                              itemsRef.current[i + 1].focus();
                            }
                          }
                        }}
                        onPaste={(event) => {
                          const paste = event.clipboardData.getData("text");
                          const pasteArray = paste.split("");

                          if (pasteArray.length !== 4) {
                            return;
                          }

                          let valid = true;

                          pasteArray.forEach((x) => {
                            if (!Number.isInteger(parseInt(x, 10))) {
                              valid = false;
                            }
                          });

                          if (valid) {
                            setFieldValue("code", pasteArray);
                            itemsRef.current[3].focus();
                          }
                        }}
                        value={values.code[i]}
                        sx={{
                          display: "inline-block",
                          textAlign: "center",
                          "& .MuiInputBase-input": {
                            textAlign: "center",
                          },
                          "& .MuiInputLabel-root": { color: "#626262" },
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "#454545" },
                          },
                        }}
                        variant="outlined"
                        InputProps={{ sx: { borderRadius: "5rem" } }}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ContainedButton
                    fullWidth
                    type="submit"
                    sx={{ color: "white" }}
                    disabled={isSubmitted}
                  >
                    Submit
                  </ContainedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
