import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import CModal from "src/components/CModal";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { toastMessage } from "src/slices/commonSlice";
import { setDialog } from "src/slices/uiSettingsSlice";
import { executeSurvey } from "src/slices/user-panel/thunks";
import { AppDispatch } from "src/store";
import { ViewAnswersDialog } from "./view-answers-dialog";

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H"];

interface IExecuteSurveyDialog {
  dialogName: string;
  resultData: any;
  setActionButton: any;
}

export const ExecuteSurveyDialog: FC<IExecuteSurveyDialog> = ({
  dialogName,
  resultData,
  setActionButton,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [surveys, setSurveys] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [answerList, setAnswerList] = useState([]);
  const [mcqs, setMcqs] = useState([]);

  const [choices, setChoices] = useState([]);

  const { dialog } = useSelector((state: any) => state.uiSettings);

  const { foregroundColor, heading3, text1 } = useDisplaySettingsContext();

  const ActionButtons = () => [
    {
      text: "Submit",
      color: "primary",
      variant: "contained",
      click: () => {
        console.log(resultData);
        console.log(answerList);
        // dispatch(
        //   submitExecuteQuestions({
        //     asgid: resultData.id,
        //     answerList: [
        //       {
        //         id: resultData.id,
        //         name: resultData.name,
        //         date: resultData.date,
        //         answerList: answerList.map((data) => ({
        //           qid: data.qid,
        //           choices: data.choices,
        //           score: data.score,
        //         })),
        //       },
        //     ],
        //   })
        // ).then(response => {
        //   if(response.payload.data.errorid === -1){
        //     dispatch(toastMessage({
        //       message: 'Answers Submitted'
        //     }));
        //   } else {
        //     dispatch(toastMessage({
        //       error: true,
        //       message: 'Unable to submit anwers'
        //     }));
        //   }
        // });
        // dispatch(
        //   setDialog({
        //     open: false,
        //     dialogName,
        //   })
        // );
      },
    },
    {
      text: "Cancel",
      color: "secondary",
      variant: "contained",
      click: () => {
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
  ];

  useEffect(() => {
    setActionButton(ActionButtons());
  }, [answerList, surveys, resultData]);

  useEffect(() => {
    if (resultData) {
      dispatch(executeSurvey(resultData)).then((response) => {
        setIsLoading(false);
        setSurveys(
          response.payload?.data?.asgsurveyanswerlist?.surveydatalist[0]
            ?.answerlist ?? []
        );
        setMcqs(response.payload?.data?.rawasg?.qlist ?? null);
        // setAnswerList(
        //   response.payload?.data?.rawasg?.qlist?.map((_) => null) ?? []
        // );
      });
    }
  }, [resultData]);

  const KeyValue = ({ label, value }) => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {surveys ? (
        <Grid container sx={surveys && { maxHeight: "70vh" }}>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
              mb: 1,
            }}
          >
            Answered Times: {surveys.length}/{3}
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: "5px",
            }}
          >
            {surveys.map((survey, index) => (
              <Grid
                container
                sx={{
                  border: "1px solid black",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "5px",
                }}
              >
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Typography sx={{ fontSize: heading3, fontWeight: "bold" }}>
                      {index + 1} Answering
                    </Typography>
                    <Stack direction="row" gap="100px">
                      <Typography sx={{ fontSize: text1, fontWeight: "bold" }}>
                        Name:
                      </Typography>
                      <Typography sx={{ fontSize: text1 }}>
                        {survey.name}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap="100px">
                      <Typography sx={{ fontSize: text1, fontWeight: "bold" }}>
                        Phone:
                      </Typography>
                      <Typography sx={{ fontSize: text1 }}>
                        {survey.phone}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={true} sx={{ my: 1 }}>
                  <Button
                    variant="outlined"
                    sx={{ height: "100%" }}
                    onClick={() => {
                      setChoices(survey?.answerlist ?? []);
                      dispatch(
                        setDialog({ open: true, dialogName: "ViewAnswers" })
                      );
                    }}
                  >
                    View Answers
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!isLoading ? (
            <Typography>No Result Found</Typography>
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </Box>
      )}
      {dialog.ViewAnswers !== undefined && dialog.ViewAnswers ? (
        <CModal
          dialogName="ViewAnswers"
          title={"Result"}
          maxWidth="sm"
          showSaveButton={false}
          handleCancel={() => {
            setChoices([]);
          }}
          open={dialog.ViewAnswers === undefined ? false : dialog.ViewAnswers}
          content={
            <ViewAnswersDialog
              dialogName="ViewAnswers"
              data={mcqs}
              choices={choices}
            />
          }
        />
      ) : null}
    </>
  );
};
