/*eslint-disable*/
import { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@mui/material";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

const InputLabelStyled = styled(InputLabel)(({ theme, className }) => ({
  transform:
    className === "small"
      ? `translate(14px, ${theme.spacing(1)}) scale(1)`
      : `translate(14px, ${theme.spacing(2)}) scale(1)`,
  "&.MuiInputLabel-shrink.MuiFormLabel-filled": {
    transform:
      className === "small"
        ? `translate(14px, -9px) scale(0.75)`
        : `translate(14px, -9px) scale(0.75)`,
  },
}));

type SelectFieldProps = SelectProps & {
  name: string;
  label: string;
  helperText?: any;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  options: any[];
  addNone?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  handleChange?: (e: any) => void;
  // selectedvalue?: string;
};

const SelectField: FC<SelectFieldProps> = (props) => {
  const { setFieldValue } = useFormikContext();
  //   const classes = useStyles();
  const [personName, setPersonName] = useState<string[]>([]);
  const [selected, setSelected] = useState([]);
  const [eve, setEve] = useState<any>("");

  const {
    name,
    label,
    helperText,
    placeholder,
    size = "small",
    fullWidth = true,
    variant = "outlined",
    required = false,
    options,
    disabled,
    addNone = false,
    readOnly = false,
    handleChange = false,
  } = props;

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  useEffect(() => {
    if (handleChange) handleChange(selected);
  }, [selected]);
  const myHandleChange = (event) => {
    const {
      target: { value },
    } = event;

    setFieldValue(event.target.name, event.target.value);
    const newvalue = value.join(",");
    console.log("value", value);
    if (newvalue.indexOf("all") !== -1) {
      const finalOptions = options.map(function (obj) {
        return obj.value;
      });

      setSelected(
        selected.length === finalOptions.length ? [] : [...finalOptions]
      );
      return;
    }
    setSelected(value);
  };

  return (
    <Field as="Select" name={name}>
      {({ field, form }) => (
        <FormControl
          fullWidth={fullWidth}
          error={form.errors[name] && form.touched[name]}
          required={required}
        >
          <InputLabelStyled className={size} id={`select-${name}`}>
            {label}
          </InputLabelStyled>

          <Select
            readOnly={readOnly}
            disabled={disabled}
            labelId={`select-labelId-${name}`}
            id={`select-${name}`}
            {...props}
            {...field}
            name={name}
            autoComplete="off"
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            required={required}
            placeholder={placeholder}
            label={label}
            MenuProps={MenuProps}
            onChange={(e) => {
              myHandleChange(e);
              if (handleChange) setEve(e);
            }}
            value={selected}
            multiple
            renderValue={(selected: any) => {
              const newOptions = [];
              options.forEach(function (obj) {
                if (selected.includes(obj.value)) {
                  newOptions.push(obj.label);
                }
              });
              return newOptions.join(", ");
            }}
          >
            {addNone && <MenuItem value="None">None</MenuItem>}

            {options.map((option) => {
              return (
                <MenuItem value={option.value}>
                  <ListItemIcon>
                    <Checkbox checked={selected.includes(option.value)} />
                  </ListItemIcon>
                  <ListItemText primary={option.label} />
                  {/* {option.label} */}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  size: PropTypes.any,
  variant: PropTypes.any,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  addNone: PropTypes.bool,
  handleChange: PropTypes.func,
};
export default SelectField;
