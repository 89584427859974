import { Box, Stack, Typography, styled } from "@mui/material";
import ReviewHero from "./ReviewsHero";
import OverallRating from "./OverallRating/OverallRating";
import { DEVICE_TYPES } from "src/constants";
import { selectTestimonialList } from "src/slices/landing-page/selectors";
import { useSelector } from "react-redux";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const HeroBox = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  background: "white",
  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  color: "#000",
  margin: 0,
  padding: 0,
}));

export const Review = ({ deviceType }) => {
  const testimonialList = useSelector(selectTestimonialList);
  const { foregroundColor } = useDisplaySettingsContext();
  
  return (
    <>
      {testimonialList && (
        <Stack
          direction={deviceType !== DEVICE_TYPES.DESKTOP ? "column" : "row"}
          gap="96px"
          justifyContent={
            deviceType !== DEVICE_TYPES.DESKTOP ? "center" : "center"
          }
          alignItems={deviceType !== DEVICE_TYPES.DESKTOP ? "center" : "center"}
          sx={{ backgroundColor: foregroundColor, px: "46px", py: "50px" }}
        >
          <OverallRating />
          <HeroBox sx={{ textAlign: "-webkit-center", width: "70%" }}>
            <ReviewHero deviceType={deviceType} />
          </HeroBox>
        </Stack>
      )}
    </>
  );
};
