import { Search } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  styled
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "src/configs/firebase";
import * as CONSTANTS from "src/constants";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import { getServiceProviders } from "src/slices/home/thunks";
import { AppDispatch } from "src/store";

const SearchTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid #0294cf;
    }

    &.Mui-focused active {
      border: 1px solid #0294cf;
    }å

    .Mui-focused focus {
      border: 1px solid #0294cf;
    }
  }
`;

export const AppSearch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { district, updateDistrict, latitude, longitude } =
    useGeolocationContext();
  const { text2, foregroundColor } = useDisplaySettingsContext();
  return (
    <>
      <SearchTextField
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        size="small"
        label="Search Shop"
        variant="outlined"
        sx={{ width: { xs: "200px", sm: "300px", md: "600px" } }}
        inputProps={{
          autoComplete: "off",
            style: {  fontSize: text2 }
        }}
        InputProps={{
          style: { paddingRight: 5, borderRadius: "50px", fontSize: text2 },
          endAdornment: (
            <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>
              <IconButton
                sx={{
                  backgroundColor: foregroundColor,
                  m: 0,
                  p: 0,
                  width: "50px",
                  height: "25px",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (searchText.trim() !== "") {
                    dispatch(
                      getServiceProviders({
                        dynamicProperty: {
                          servicetype: CONSTANTS.ecom_enduser_search,
                          grouptype: CONSTANTS.ecom_enduser_search,
                          isshop: true,
                          sp: {
                            statename: district,
                            lati: latitude,
                            longi: longitude,
                          },
                          cust: {
                            customeruserid: auth.currentUser.uid,
                          },
                          spoffset: 0,
                        },
                        Query: searchText,
                      })
                    ).then(() => {
                      navigate(`${CONSTANTS.ecom_enduser_search}`);
                    });
                  }
                }}
              >
                <Search sx={{ color: "white" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
