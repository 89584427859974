import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as CONSTANTS from "src/constants";
import {
  CartOrigin,
  LimitedServiceModel,
  orderinfomodel,
} from "src/slices/cart/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store";
import { rentalSpSelectedEvent } from "src/slices/product/thunks";
import { auth } from "src/configs/firebase";
import { fetchedCartEventWithSP } from "src/slices/cart";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const BookingListItem = ({ data, productState, state }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { heading3, text2 } = useDisplaySettingsContext();

  const KeyValue = ({ title, value }) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: text2, fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontSize: text2 }}>{value}</Typography>
        </Grid>
      </>
    );
  };

  const SpCardClick = async (lrsp) => {
    await dispatch(rentalSpSelectedEvent({ sid: lrsp.sid }));

    let of: orderinfomodel = {
      oorigin: "CartOrigin.OrderManagementOwner",
      productlist: [],
      totalcalculatedprice: 0,
      totaltax: 0,
      isrental: true,
      sdate: productState.productFilterModel?.rpsf?.sdt,
      edate: productState.productFilterModel?.rpsf?.edt,
      rtype: productState.atype,
      askedprice: 0,
      orderstate: "PROCESSING",
      version: 0,
      sid: null,
      custname: auth.currentUser?.displayName,
      customerid: null,
      custphonenum: auth.currentUser?.phoneNumber,
      custuserid: auth.currentUser?.uid,
    };

    dispatch(
      fetchedCartEventWithSP({
        entitytype: "SERVICEPROVIDERINFO",
        entityid: lrsp.sid,
        ordermodel: null,
        sdt: productState.productFilterModel!.rpsf?.sdt,
        edt: productState.productFilterModel!.rpsf?.edt,
        prodtype: state.servicetype, //its not used in filter
        calledfrom: CartOrigin.EcomEndUser,
        lsm: {
          serviceID: lrsp.sid,
          servicecat: CONSTANTS.ecomhotel,
        } as LimitedServiceModel,
      })
    );

    navigate("/rental-sp-list-with-filter/rentals-product-list-sp-view");
  };

  return (
    <Box
      key={data.prodid}
      onClick={() => {
        SpCardClick(data);
      }}
      sx={{
        padding: "10px",
        marginTop: "5px",
        marginBottom: "5px",
        wordBreak: "break-word",
        background: "white",
        borderRadius: "10px",
        "&:hover": {
          border: "1px solid #000000",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          minHeight="150px"
          minWidth="150px"
          sx={{
            borderRadius: "5px",
            marginRight: "10px",
            backgroundImage: `url(${data.sidpho})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: heading3,
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            {data.spname}
          </Typography>
          <Grid container>
            <KeyValue title="Name" value={data.spname} />
            <KeyValue title="type" value={data.category ?? "NoRoomType"} />
            <KeyValue title="Address" value={data.addressline ?? "NoAddress"} />
            <KeyValue title="Phone" value={data.phone ?? "NoPhone"} />
            <KeyValue
              title="PerDay"
              value={CONSTANTS.getRateForStartDate(
                data,
                productState.productFilterResultModel!.rpds?.sdt!,
                productState.productFilterResultModel!.rpds?.edt!
              ).toString()}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
