import { createSlice } from '@reduxjs/toolkit';
import * as CONSTANTS from 'src/constants';

import {
  fetchProductFilterResultP,
  rentalSpFilterGetDataEvent,
  rentalSpSelectedEvent,
  updateSearchConfigWithSpatial,
} from './thunks';
import { IProductSlice } from './types';

export const productSlice = createSlice({
  name: "product",
  initialState: {
    productState: {
      secondfromepoch: null,
      serviceGroups: null,
      productFilterResultModel: null,
      productFilterModel: {
        filtertype: null,
        psc: null,
        atyp: null,
        categorytype: null,
        reqfacet: null,
        offset: null,
        termquery: null,
        sortf: null,
        filters: null,
        facetparalist: null,
        serviceproviderid: null,
        isown: null,
        isservice: null,
        rpsf: null,
      },
      productSearchInformationConfig: {
        lsm: null,
        favsidlist: null,
        servicetype: null,
        grouptype: null,
        isshop: null,
        isrepo: null,
        forsid: null,
        atyp: null,
        sp: null,
        cust: null,
        entrypoint: null,
        servicesselected: null,
        spoffset: null,
        isservice: null,
        sdt: null,
        edt: null,
      },
    },
    isError: false,
    isLoading: false,
  } as IProductSlice,
  reducers: {
    setProductState(state, action): void {
      state.productState = {
        ...state.productState,
        loading: false,
        ...action.payload,
      };
    },
    setServiceModel(state, action): void {
      let productFilterResultModel = {
        docwithdata1: [],
        docwithdata2: [],
        docwithdata3: [],
        docwithdata4: [],
        docwithdata5: [],
        rentalpagedata: null,
      };

      state.productState = {
        ...state.productState,
        atype: action.payload.prodtype,
        productFilterResultModel: productFilterResultModel,
        productSearchInformationConfig: {
          ...action.payload.productSearchInformationConfig,
          lsm: action.payload.serviceModel,
          isshop: true,
          atyp: action.payload.prodtype,
        },
        hasError: false,
        error: "",
      };

      // var um = await HelpUtil.getUserRepository()
      //       .getEndUserClusterInfoSP(event.serviceModel.cname);
      //   if (um == null) {
      //     throw new EcommerceException(
      //         "Unable to find the ServiceProvider, please try again");
      //   }
    },
    doingTextSearchFromMainExplore(state): void {
      state.productState.productSearchInformationConfig = {
        ...state.productState.productSearchInformationConfig,
        servicetype: CONSTANTS.ecom_enduser_search,
        grouptype: CONSTANTS.ecom_enduser_search,
        servicesselected: [],
        isshop: true,
      }
    }
  },
  extraReducers: (builder) => {
    // getServiceProviders
    builder.addCase(fetchProductFilterResultP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductFilterResultP.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchProductFilterResultP.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // rentalSpFilterGetDataEvent
    builder.addCase(rentalSpFilterGetDataEvent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rentalSpFilterGetDataEvent.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(rentalSpFilterGetDataEvent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // rentalSpSelectedEvent
    builder.addCase(rentalSpSelectedEvent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rentalSpSelectedEvent.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(rentalSpSelectedEvent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //updateSearchConfigWithSpatial
    builder.addCase(updateSearchConfigWithSpatial.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(
      updateSearchConfigWithSpatial.fulfilled,
      (state, action) => {
        // state.isLoading = false;
      }
    );
    builder.addCase(updateSearchConfigWithSpatial.rejected, (state) => {
      // state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { setServiceModel, setProductState } = productSlice.actions;
export const { reducer } = productSlice;
