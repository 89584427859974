import { Box, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CKeyValueList from 'src/components/CKeyValueList';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { clearProductFilterItemDetails } from 'src/slices/home';
import { selectIsHomeLoading, selectProductFilterItemDetails } from 'src/slices/home/selectors';
import { fetchProductFilterItemDetails } from 'src/slices/home/thunks';
import { AppDispatch } from 'src/store';

export function RealEstateDetailsPage() {
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const selectIsLoading = useSelector(selectIsHomeLoading);
  const realEstateDetails = useSelector(selectProductFilterItemDetails);
  const { heading2, text1, text2, text3 } = useDisplaySettingsContext();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productid = queryParams.get("productid");
  const serviceid = queryParams.get("serviceid");
  const userid = queryParams.get("userid");

  const [selectVehicleData, setSelectVehicleData] = useState<any>();

  const [selectedImage, setSelectedImage] = useState<string>();

  useEffect(() => {
    dispatch(
      fetchProductFilterItemDetails({
        data: {
          productactionrequest: {
            qtype: null,
            origin: "SERVICEPROVIDERINFO",
            entitytype: "SERVICEPROVIDERINFO",
            serviceid: serviceid,
            userid: userid,
            producttype: "REALESTATE",
            classifiedtype: "REALESTATE",
            productid: productid,
          },
          qrytype: "GETSINGLEDSPRODUCT",
        },
      })
    );
    return () => {
      dispatch(clearProductFilterItemDetails());
    };
  }, []);

  useEffect(() => {
    if (
      realEstateDetails &&
      realEstateDetails.data.rdlist &&
      realEstateDetails.data.rdlist.length !== 0
    ) {
      setSelectVehicleData(realEstateDetails.data.rdlist[0]);
      if (realEstateDetails?.data?.rdlist?.length > 0) {
        if (realEstateDetails?.data?.rdlist[0].adata?.imagelist?.length > 0) {
          if (realEstateDetails.data?.rdlist[0].adata?.imagelist?.length > 0) {
            setSelectedImage(
              realEstateDetails.data.rdlist[0].adata.imagelist[0]
            );
          }
        }
      }
    }
  }, [realEstateDetails]);

  return (
    <>
      {selectVehicleData ? (
        <>
          <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontSize={text1}>
                  {selectVehicleData.adata.title}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Box
                  minHeight={isMobile ? "200px" : "600px"}
                  minWidth={isMobile ? "100%" : "600px"}
                  sx={{
                    borderRadius: "5px",
                    marginRight: "10px",
                    backgroundImage: `url(${selectedImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: isMobile ? "contain" : "contain",
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={2}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    display: "flex",
                    flexDirection: "row",
                  },
                }}
              >
                {selectVehicleData?.adata?.imagelist?.map((img) => (
                  <Box
                    onClick={() => {
                      setSelectedImage(img);
                    }}
                    minHeight={isMobile ? "50px" : "150px"}
                    minWidth={isMobile ? "50px" : "150px"}
                    maxHeight="400px"
                    sx={{
                      overFlow: "auto",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      backgroundImage: `url(${img})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: isMobile ? "contain" : "contain",
                      border: selectedImage === img ? "1px solid black" : "",
                      "&:hover": {
                        border: "1px solid black",
                      },
                    }}
                  ></Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography fontSize={text2}>Details</Typography>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Price",
                      value: `₹ ${selectVehicleData.adata.price}`,
                    },
                    {
                      key: "Description",
                      value: selectVehicleData.adata.description,
                    },
                    {
                      key: "Property Type",
                      value: selectVehicleData.adata.propertytype,
                    },
                    {
                      key: "Construction Status",
                      value: selectVehicleData.adata.constructionstatus,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography fontSize={text2}>Interior Features</Typography>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Number of Bedrooms",
                      value: selectVehicleData.adata.numrooms,
                    },
                    {
                      key: "Number of Bathrooms",
                      value: selectVehicleData.adata.numbath,
                    },
                    {
                      key: "Living Area",
                      value: selectVehicleData.adata.sqfeetarea,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography fontSize={text2}>Amentities</Typography>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Parking",
                      value: selectVehicleData.adata.hasparking
                        ? "Available"
                        : "Not Available",
                    },
                    {
                      key: "Green Area",
                      value: selectVehicleData.adata.hasgreenarea
                        ? "Available"
                        : "Not Available",
                    },
                    {
                      key: "Security",
                      value: selectVehicleData.adata.hassecurity
                        ? "Available"
                        : "24/7 Not Available",
                    },
                    {
                      key: "Water",
                      value: selectVehicleData.adata.has24hrwater
                        ? "Available"
                        : "24/7 Not Available",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography fontSize={text2}>Personal Details</Typography>
                <CKeyValueList
                  labelWidth={100}
                  size="medium"
                  list={[
                    {
                      key: "Address",
                      value:
                        selectVehicleData.adata.contactdetails.address
                          .addressline,
                    },
                    {
                      key: "Email",
                      value: selectVehicleData.adata.contactdetails.email,
                    },
                    {
                      key: "Phone",
                      value: selectVehicleData.adata.contactdetails.phonenum,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
}
