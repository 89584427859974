import { Grid } from "@mui/material";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { getUserById } from "src/slices/auth/thunks";
import { toastMessage } from "src/slices/commonSlice";
import { selectLsm } from "src/slices/landing-page/selectors";
import { AppDispatch } from "src/store";
import * as CONSTANTS from "src/constants";
import { loadAttendances, loadProgress } from "src/slices/user-panel/thunks";
import {
  selectAttendances,
  selectExamTerms,
  selectProgress,
} from "src/slices/user-panel/selectors";

interface IformInitialValue {
  idCardNumber: string;
  examTerm: string;
}

export const ProgressPanel = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [user, setUser] = useState<any>(null);
  const [rows, setRows] = useState([]);
  const lsm = useSelector(selectLsm);
  const examTerms = useSelector(selectExamTerms);
  const progress = useSelector(selectProgress);
  const formikRef = useRef(null);

  //   const idCardNumbers: IExpenseTypes[] = useSelector(selectExpenseTypes);
  //   const unitPriceItems: IUnitPriceItem[] = useSelector(selectUnitPriceItems);

  // Form Inital Values
  const formInitialValue: IformInitialValue = {
    idCardNumber: "",
    examTerm: "",
  };

  useEffect(() => {
    dispatch(getUserById()).then((response) => {
      if (response?.payload?.data?.um) {
        setUser(response?.payload?.data?.um);
      }
    });
    // dispatch(fetchExpenseTypes());
    // dispatch(fetchUnitPriceItems());
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (attendances?.stuintdata) {
  //     const studentListData = attendances?.stuintdata;
  //     const rowsKind: any = Array.from(
  //       new Set(studentListData.map((data) => data.kind))
  //     );
  //     const columns: any = Array.from(
  //       new Set(studentListData.map((data) => data.dt))
  //     ).map((d: number) => d * 1000);

  //     const resultCols = [
  //       {
  //         accessorKey: "id",
  //         header: "Subject",
  //         size: 100,
  //       },
  //       ...columns.map((d) => ({
  //         accessorKey: d.toString(),
  //         header: new Date(d).toLocaleDateString(),
  //         size: 100,
  //       })),
  //     ];
  //     setCols(resultCols);

  //     const data = {};
  //     for (let sd of studentListData) {
  //       data[sd.kind] = { ...data[sd.kind], [sd.dt * 1000]: sd.attval };
  //     }

  //     const finalRows = [];
  //     for (let row of rowsKind) {
  //       const obj = {
  //         id: row,
  //       };
  //       for (let date of columns) {
  //         console.log(data);
  //         obj[date] = data[row][date] ?? "-";
  //       }
  //       finalRows.push(obj);
  //     }
  //     setRows(finalRows);
  //   }
  // }, [attendances]);

  return (
    <>
      <Formik
        initialValues={formInitialValue}
        innerRef={formikRef}
        onSubmit={(values: IformInitialValue) => {
          const payload = {
            kind: values.examTerm,
            ...values,
          };
          console.log("payload", payload);
          dispatch(loadProgress(payload));
        }}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form id="Progress">
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Grid container spacing={2} pb={3} alignItems="center">
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikControl
                      control="SelectField"
                      name="idCardNumber"
                      label={"Id Card Number"}
                      required
                      options={(
                        user?.services?.filter(
                          (service) => lsm?.serviceID === service?.serviceid
                        )[0]?.stulist ?? []
                      )?.map(
                        (student) =>
                          ({
                            value: student,
                            label: student.id,
                          } ?? [])
                      )}
                      error={Boolean(
                        touched.idCardNumber && errors.idCardNumber
                      )}
                      helperText={touched.idCardNumber && errors.idCardNumber}
                      {...getFieldProps("idCardNumber")}
                    />
                  </Grid>{" "}
                  {values.idCardNumber && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikControl
                        control="SelectField"
                        name="examTerm"
                        label={"Exam Term"}
                        required
                        options={examTerms?.vrlist?.map(
                          (term) =>
                            ({
                              value: term,
                              label: term,
                            } ?? [])
                        )}
                        error={Boolean(touched.examTerm && errors.examTerm)}
                        helperText={touched.examTerm && errors.examTerm}
                        {...getFieldProps("examTerm")}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <ContainedButton size="medium" type="submit">
                      Load Data
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MaterialReactTable
                      columns={[
                        {
                          accessorKey: "subject",
                          header: "Subject",
                          size: 100,
                        },
                        {
                          accessorKey: "maxScore",
                          header: "Max Score",
                          size: 100,
                        },
                        {
                          accessorKey: "score",
                          header: "Score",
                          size: 100,
                        },
                      ]}
                      data={progress?.stuintdata?.map(p => ({
                        subject: p.kind,
                        maxScore: p.maxscore,
                        score: p.score
                      })) ?? []}
                      initialState={{
                        density: "compact",
                        pagination: { pageSize: 5, pageIndex: 0 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
