import { addDays, format, isSunday } from "date-fns";
import { DEVICE_TYPES } from "src/constants";
import Image from "src/types/image";

export const Utils = {
  isSundayBetween: (startDate: Date, endDate: Date) => {
    const currentDate = new Date();
    for (let i = 0; i < 3; i++) {
      const nextDay = addDays(currentDate, i);
      if (isSunday(nextDay)) {
        return true;
      }
    }
    return false;
  },
};

export const bytesToSize = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const dayMonthYearFormat = (date: Date, showTime: boolean = false) => {
  return showTime
    ? format(date, "dd/MM/yyyy hh:mm:ss aa")
    : format(date, "dd/MM/yyyy");
};

export const getBestFitImage = (deviceType: string, imagesList?: []) => {
  if (!imagesList) {
    return null;
  }

  if (deviceType === DEVICE_TYPES.MOBILE) {
    return imagesList.find((image: Image) => image.info.includes("mob"));
  } else if (
    deviceType === DEVICE_TYPES.TABLET ||
    deviceType === DEVICE_TYPES.DESKTOP
  ) {
    return imagesList.find((image: Image) => image.info.includes("other"));
  } else {
    return imagesList.find((image: Image) => image.info.includes("orignal"));
  }
};

export const getBestFitImageForHero = (imagesList?: []) => {
  if (!imagesList) {
    return null;
  }

  return imagesList.find((image: Image) => image.info.includes("orignal"));
};

export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
