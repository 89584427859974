import { ArrowForwardIos } from "@mui/icons-material";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { selectEntityId } from "src/slices/landing-page/selectors";

interface IMenuItemProps {
  name: string;
  hasSubMenu: boolean;
  menuItems?: IMenuItemProps[];
}

interface INestedMenuProps {
  id: string;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>;
  menuItems?: IMenuItemProps[];
  onClickLeafChild?(name: string): void;
  onClickSubChild?(name: string): void;
}

export const NestedMenu: React.FC<INestedMenuProps> = ({
  id,
  anchorEl,
  setAnchorEl,
  menuItems,
  onClickLeafChild,
  onClickSubChild,
}) => {
  const { text3 } = useDisplaySettingsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElS, setAnchorElS] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorElS(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElS(null);
  };

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: id.split("/").length > 1 ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {menuItems?.map((item) =>
        item.hasSubMenu ? (
          <>
            <MenuItem
              sx={{
                width: !isMobile ? "150px" : "100px",
              }}
              onClick={(e) => {
                if (onClickSubChild) {
                  onClickSubChild(`${id}/${item.name}`);
                } else {
                  handleClick(e);
                }
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Typography fontSize={text3}>{item.name}</Typography>
                <ArrowForwardIos sx={{ fontSize: text3 }} />
              </Stack>
            </MenuItem>

            <NestedMenu
              id={`${id}_${item.name}`}
              anchorEl={anchorElS}
              setAnchorEl={setAnchorElS}
              menuItems={item.menuItems}
            />
          </>
        ) : (
          <MenuItem
            sx={{
              width: !isMobile ? "150px" : "100px",
            }}
            onClick={() => {
              if (onClickLeafChild) {
                onClickLeafChild(`${id}/${item.name}`);
              }
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        )
      )}
    </Menu>
  );
};
