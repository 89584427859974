export interface IChatSlice {
  roomlst?: any[];
  cmdict?: any;
  fcl?: any;
  roomStatus?: any;
  isLoading?: boolean;
  isError?: boolean;
}


export enum ChannelRoomUpdatedStatus {
  INACTIVE = 0,
  PREINITIAL = 1,
  INITIAL = 2,
  LOADING = 3,
  SUCCESS = 4,
  ERROR = 5,
  METAMODEL = 6,
  DATALIST = 7,
  MSGIND = 8,
  MSGRSP = 9
}