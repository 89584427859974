import { MainService, ServiceListShop } from "src/types/exploreTypes";

export const productData = {
  servicelistshop: [
    {
      sectionname: "Essentials",
      icon: "assets/ParentCategoryIcons/DailyNeeds.png",
      displaylist: "double",
      ordernum: 1,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Food & Beverage",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "GROCERY",
              subsectionname: "Food & Beverage",
              atyp: "spp",
              grptype: "shop",
              querystr: "GROCERY",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Grocery.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355390&Signature=hKiP%2BeOIfko0NbPv1Txwe97v6DY%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "GROCERY",
            },
            {
              servicetype: "VEGETABLE",
              subsectionname: "Food & Beverage",
              atyp: "spp",
              grptype: "shop",
              querystr: "VEGETABLE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Vegetable.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387335&Signature=YeX1ej55AcNBs9pfXO6O05KCu3c%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "VEGETABLE",
            },
            {
              servicetype: "FRUITS",
              subsectionname: "Food & Beverage",
              atyp: "spp",
              grptype: "shop",
              querystr: "FRUITS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Fruits.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355416&Signature=r7jYnT6xWBgs7V058eqZLvnpExY%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "FRUITS",
            },
            {
              servicetype: "MILK",
              subsectionname: "Food & Beverage",
              atyp: "spp",
              grptype: "shop",
              querystr: "MILK",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MILK.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355417&Signature=o4HCdPRiPAR6B9MZ9VXCtEvG%2BIU%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "MILK",
            },
            {
              servicetype: "MEAT-FISH-POULTRY",
              subsectionname: "Food & Beverage",
              atyp: "spp",
              grptype: "shop",
              querystr: "MEAT-FISH-POULTRY",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MEAT-FISH-POULTRY.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387202&Signature=DL78JnqPvqB1crebjlpot%2BXJdEE%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "MEAT-FISH-POULTRY",
            },
            {
              servicetype: "Restuarant",
              subsectionname: "Food & Beverage",
              atyp: "FOODJOINT",
              grptype: "shop",
              querystr: "RESTUARANT",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Restuarant.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387203&Signature=mPKq3NHFEzc3SZ2um15hE6eB1cY%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Restuarant, Dhabha & Caterers",
              name: "Restuarant",
            },
            {
              servicetype: "Tea & Coffee",
              subsectionname: "Food & Beverage",
              atyp: "FOODJOINT",
              grptype: "shop",
              querystr: "TEA & COFFEE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Tea%20%26%20Coffee.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387208&Signature=iXlKQ%2FxhAisr3k7W5r80JA58P%2Bc%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Restuarant, Dhabha & Caterers",
              name: "Tea & Coffee",
            },
          ],
          name: "Food & Beverage",
        },
        {
          servicetype: "Travel,Events,Education & More",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            // {
            //   servicetype: "ADHOCTRIPENDUSER",
            //   subsectionname: "Travel,Events,Education & More",
            //   atyp: "",
            //   grptype: "ADHOCTRIPENDUSER",
            //   querystr: "",
            //   icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/ADHOCTRIPENDUSER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355418&Signature=K6yAOfn0lgXCrW5%2BZwDb4NhA%2B6o%3D",
            //   displaylist: "single",
            //   specialtype: "ADHOCTRIPENDUSER",
            //   isspecial: true,
            //   servicecat: "Special",
            //   name: "ADHOCTRIPENDUSER",
            // },
            {
              servicetype: "TRIPSEARCH",
              subsectionname: "Travel,Events,Education & More",
              atyp: "",
              grptype: "TRIPSEARCH",
              querystr: "",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/TRIPSEARCH.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387210&Signature=ZKnv8Kl%2FRVtIB5P7bNoaTn5FrqQ%3D",
              displaylist: "single",
              specialtype: "TRIPSEARCH",
              isspecial: true,
              servicecat: "Special",
              name: "TRIPSEARCH",
            },
            // {
            //   servicetype: "ENDUSEREVENT",
            //   subsectionname: "Travel,Events,Education & More",
            //   atyp: "",
            //   grptype: "ENDUSEREVENT",
            //   querystr: "",
            //   icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/ENDUSEREVENT.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387211&Signature=c8SmFlPY2roPZEgEPWzV3p02crc%3D",
            //   displaylist: "single",
            //   specialtype: "ENDUSEREVENT",
            //   isspecial: true,
            //   servicecat: "Special",
            //   name: "ENDUSEREVENT",
            // },
            {
              servicetype: "SCHOOLADMISSIONS",
              subsectionname: "Travel,Events,Education & More",
              atyp: "school",
              grptype: "SHOPDEALS",
              querystr: "SCHOOL",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SCHOOLADMISSIONS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387212&Signature=93ZsL4qdqrD%2B22lz9raPiBLSFoY%3D",
              displaylist: "single",
              specialtype: "ADMISSIONS",
              isspecial: true,
              servicecat: "Special",
              name: "SCHOOLADMISSIONS",
            },
            {
              servicetype: "ACADEMYADMISSIONS",
              subsectionname: "Travel,Events,Education & More",
              atyp: "tutor",
              grptype: "SHOPDEALS",
              querystr: "TUTOR",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/ACADEMYADMISSIONS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387214&Signature=vL5%2FUZqlEKayuWDfL7wtr6FjLUA%3D",
              displaylist: "single",
              specialtype: "ADMISSIONS",
              isspecial: true,
              servicecat: "Special",
              name: "ACADEMYADMISSIONS",
            },
            {
              servicetype: "PROPERTYRENTALS",
              subsectionname: "Travel,Events,Education & More",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PROPERTYRENTALS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387215&Signature=62R0pA3tB5EfVRy97qdpOqD9Bxo%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "Classified",
              name: "PROPERTYRENTALS",
            },
          ],
          name: "Travel,Events,Education & More",
        },
        {
          servicetype: "Deals And Specials",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "Grocery",
              subsectionname: "Deals And Specials",
              atyp: "spp",
              grptype: "SHOPDEALS",
              querystr: "GROCERY",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Grocery%20Deals.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387216&Signature=5qsoAN8aT64cvP%2F3jY8P3F6jiBc%3D",
              displaylist: "single",
              specialtype: "SHOPDEALS",
              isspecial: true,
              servicecat: "Special",
              name: "Grocery Deals",
            },
            {
              servicetype: "Garment",
              subsectionname: "Deals And Specials",
              atyp: "spp",
              grptype: "SHOPDEALS",
              querystr: "GARMENTS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Garments%20Deals.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387217&Signature=k1KvIuCKMd%2Boo6OKk81cxOFSfDo%3D",
              displaylist: "single",
              specialtype: "SHOPDEALS",
              isspecial: true,
              servicecat: "Special",
              name: "Garment Deals",
            },
          ],
          name: "Deals And Specials",
        },
      ],
    },
    {
      sectionname: "Health, Fitness & WellBeing",
      icon: "assets/ParentCategoryIcons/DailyNeeds.png",
      displaylist: "double",
      ordernum: 2,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Health",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "DOCTOR",
              subsectionname: "Health",
              atyp: "dds",
              grptype: "shop",
              querystr: "DOCTOR",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/DOCTOR.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387218&Signature=%2BD8kjAWv5cnc6cFgjl8p%2FwaVots%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Medical Related -Doctor, Lab , Medicine",
              name: "DOCTOR",
            },
            {
              servicetype: "DENTIST",
              subsectionname: "Health",
              atyp: "dds",
              grptype: "shop",
              querystr: "DENTIST",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/DENTIST.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387220&Signature=nbo9wPqDP9jYfrB9KiHGTmLMpUs%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Medical Related -Doctor, Lab , Medicine",
              name: "DENTIST",
            },
            {
              servicetype: "MEDICINE",
              subsectionname: "Health",
              atyp: "medicine",
              grptype: "shop",
              querystr: "MEDICINE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MEDICINE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387221&Signature=x0xRZSEg%2B%2F0XshTUSZnc7A2wpao%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Medical Related -Doctor, Lab , Medicine",
              name: "MEDICINE",
            },
            {
              servicetype: "MEDICAL-TEST",
              subsectionname: "Health",
              atyp: "mls",
              grptype: "shop",
              querystr: "MEDICAL-TEST",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MEDICAL-TEST.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355420&Signature=y5vCoTfiH4Opu43t%2Fz6HHKKDhKM%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Medical Related -Doctor, Lab , Medicine",
              name: "MEDICAL-TEST",
            },
            {
              servicetype: "HOSPITAL",
              subsectionname: "Health",
              atyp: "HOSPITAL",
              grptype: "shop",
              querystr: "HOSPITAL",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HOSPITAL.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387226&Signature=QErLMD2lYMCvVhipJ8pVsmriPkU%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Medical Related -Doctor, Lab , Medicine",
              name: "HOSPITAL",
            },
            {
              servicetype: "HEALTH AND SURGICAL EQUIPMENT",
              subsectionname: "Health",
              atyp: "spp",
              grptype: "shop",
              querystr: "HEALTH AND SURGICAL EQUIPMENT",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HEALTH%20AND%20SURGICAL%20EQUIPMENT.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387228&Signature=R448yic0N0%2F3scrINHYMfE5Ktww%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Medical Related -Doctor, Lab , Medicine",
              name: "HEALTH AND SURGICAL EQUIPMENT",
            },
          ],
          name: "Health",
        },
        {
          servicetype: "Fitness & Well Being",
          displaylist: "double",
          ordernum: 2,
          childservicetype: [
            {
              servicetype: "GYM",
              subsectionname: "Fitness & Well Being",
              atyp: "",
              grptype: "shop",
              querystr: "GYM",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/GYM.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355421&Signature=DuCEZEio29Ca01N%2FR5exhjcZMgY%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Fitness",
              name: "GYM",
            },
            {
              servicetype: "SPORTSACADEMY",
              subsectionname: "Fitness & Well Being",
              atyp: "tutor",
              grptype: "shop",
              querystr: "SPORTSACADEMY",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SPORTS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387230&Signature=V4BvvQRl7q%2BffMerYcQWO%2BkxCQo%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "SPORTS",
            },
            {
              servicetype: "YOGACLASSES",
              subsectionname: "Fitness & Well Being",
              atyp: "tutor",
              grptype: "shop",
              querystr: "YOGACLASSES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/YOGA.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355422&Signature=TKIRtdJdtTlX4mDac%2F3lHuxb3YA%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "YOGA",
            },
            {
              servicetype: "SWIMCLASSES",
              subsectionname: "Fitness & Well Being",
              atyp: "tutor",
              grptype: "shop",
              querystr: "SWIMCLASSES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SWIM.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387233&Signature=SyXBY4tDvngGIRXFgHbSEOs8ue4%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "SWIM",
            },
            {
              servicetype: "Music",
              subsectionname: "Fitness & Well Being",
              atyp: "tutor",
              grptype: "shop",
              querystr: "MUSIC",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Music.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355423&Signature=VmYvfqzDoKn3bgfBtb2dl5x9zgk%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "Music",
            },
            {
              servicetype: "Dance",
              subsectionname: "Fitness & Well Being",
              atyp: "tutor",
              grptype: "shop",
              querystr: "DANCE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Dance.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387236&Signature=iIvSseA5x5toTRfydg0ODYVBHH0%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "Dance",
            },
          ],
          name: "Fitness & Well Being",
        },
      ],
    },
    {
      sectionname: "School , Tutors And Academy",
      icon: "assets/ParentCategoryIcons/DailyNeeds.png",
      displaylist: "double",
      ordernum: 3,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "School",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "Admissions for School",
              subsectionname: "School",
              atyp: "tutor",
              grptype: "SHOPDEALS",
              querystr: "SCHOOL",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Admissions.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387238&Signature=5u07a5PtI9cIKM2Qz0zEpZmoxm4%3D",
              displaylist: "single",
              specialtype: "ADMISSIONS",
              isspecial: true,
              servicecat: "Classified",
              name: "Admissions",
            },
            {
              servicetype: "School",
              subsectionname: "School",
              atyp: "tutor",
              grptype: "shop",
              querystr: "SCHOOL",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Schools.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387237&Signature=%2By4Dg8jlNFJcf3mrupwJI33EuTw%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "Schools",
            },
          ],
          name: "School",
        },
        {
          servicetype: "Tutor And Academy",
          displaylist: "double",
          ordernum: 2,
          childservicetype: [
            {
              servicetype: "Admissions for tution",
              subsectionname: "Tutor And Academy",
              atyp: "tutor",
              grptype: "SHOPDEALS",
              querystr: "TUTOR",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Admissions.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387238&Signature=5u07a5PtI9cIKM2Qz0zEpZmoxm4%3D",
              displaylist: "single",
              specialtype: "ADMISSIONS",
              isspecial: true,
              servicecat: "Classified",
              name: "Admissions",
            },
            {
              servicetype: "TUTION & COACHING",
              subsectionname: "Tutor And Academy",
              atyp: "tutor",
              grptype: "shop",
              querystr: "TUTION & COACHING",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Academic%20Tutors.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355424&Signature=aNBqzJTc%2BGsJ%2F4UHcyFDu8vHz%2BY%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "School,Tutoring And Academy",
              name: "Academic Tutors",
            },
          ],
          name: "Tutor And Academy",
        },
      ],
    },
    {
      sectionname: "Home Services",
      icon: "assets/ParentCategoryIcons/Services.png",
      displaylist: "double",
      ordernum: 4,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Maintainence",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "ELECTRICIAN",
              subsectionname: "Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "ELECTRICIAN",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/ELECTRICIAN.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387240&Signature=fKlDConbaJIaJbOTANlc7Y1u8BA%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "ELECTRICIAN",
            },
            {
              servicetype: "PLUMBER",
              subsectionname: "Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "PLUMBER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PLUMBER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387243&Signature=BmPFd8q8qz2e5%2B3AUOgM40HBhEo%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "PLUMBER",
            },
            {
              servicetype: "PAINTER",
              subsectionname: "Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "PAINTER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PAINTER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387244&Signature=4MxN3%2FQDK%2BWsLaHjJBhfPHe7maw%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "PAINTER",
            },
            {
              servicetype: "LOCKSMITH",
              subsectionname: "Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "LOCKSMITH",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/LOCKSMITH.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355425&Signature=VyioRKuiR5vbbBVNR7pMq1OWZ3U%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "LOCKSMITH",
            },
            {
              servicetype: "CARPAINTER",
              subsectionname: "Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "CARPAINTER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/CARPAINTER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355426&Signature=RbosQZQjOqqEJPwDfdxJYtZOYMI%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "CARPAINTER",
            },
            {
              servicetype: "REPAIR HOUSE APPLIANCES",
              subsectionname: "Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "REPAIR HOUSE APPLIANCES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/REPAIR%20HOUSE%20APPLIANCES.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355427&Signature=9OkxSq6Ig0oqs%2BzxPNoLEGPqpv8%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "REPAIR HOUSE APPLIANCES",
            },
          ],
          name: "Maintainence",
        },
        {
          servicetype: "Help",
          displaylist: "double",
          ordernum: 2,
          childservicetype: [
            {
              servicetype: "GARDENER",
              subsectionname: "Help",
              atyp: "sps",
              grptype: "shop",
              querystr: "GARDENER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/GARDENER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355428&Signature=RwURo3PGBMhrCDHemi1OwiBAoU4%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "GARDENER",
            },
            {
              servicetype: "HOUSEHELP",
              subsectionname: "Help",
              atyp: "sps",
              grptype: "shop",
              querystr: "HOUSEHELP",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HOUSEHELP.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387250&Signature=ynACKyNTp5b%2BDXtaEcw2hErt0jQ%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "HOUSEHELP",
            },
            {
              servicetype: "COOK",
              subsectionname: "Help",
              atyp: "sps",
              grptype: "shop",
              querystr: "COOK",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/COOK.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355429&Signature=KIpusXsv5ZFzZjhzwFh9TdhUUy0%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "COOK",
            },
            {
              servicetype: "CLEANINGSERVICES",
              subsectionname: "Help",
              atyp: "sps",
              grptype: "shop",
              querystr: "CLEANINGSERVICES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/CLEANINGSERVICES.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355430&Signature=dhM3iExIVK1LBCqoT1dywLZA%2Bcs%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "CLEANINGSERVICES",
            },
            {
              servicetype: "DRIVER",
              subsectionname: "Help",
              atyp: "sps",
              grptype: "shop",
              querystr: "DRIVER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/DRIVER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355431&Signature=%2BexH5Zhw0ZCvl0Oy0PS3bE1%2FGBU%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "DRIVER",
            },
            {
              servicetype: "SECURITY",
              subsectionname: "Help",
              atyp: "sps",
              grptype: "shop",
              querystr: "SECURITY",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SECURITY.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387254&Signature=viQSx%2BoIvq3LA2ze5C6Ou7TBly4%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "SECURITY",
            },
          ],
          name: "Help",
        },
        {
          servicetype: "General Services",
          displaylist: "double",
          ordernum: 3,
          childservicetype: [
            {
              servicetype: "BARBER",
              subsectionname: "General Services",
              atyp: "sps",
              grptype: "shop",
              querystr: "BARBER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BARBER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387255&Signature=eN09hGBRrgxfuYlvM%2FqrX2LMIKU%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "BARBER",
            },
            {
              servicetype: "BEAUTICIAN",
              subsectionname: "General Services",
              atyp: "sps",
              grptype: "shop",
              querystr: "BEAUTICIAN",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BEAUTICIAN.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387256&Signature=EG15o0Zv3aEmC%2BF7ZciJqb2LgWc%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "BEAUTICIAN",
            },
            {
              servicetype: "ASTROLOGER",
              subsectionname: "General Services",
              atyp: "sps",
              grptype: "shop",
              querystr: "ASTROLOGER",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/ASTROLOGER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387257&Signature=TYbnqBO%2BkDOcxNwNhD%2BqhWC%2FTGo%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "ASTROLOGER",
            },
          ],
          name: "General Services",
        },
      ],
    },
    {
      sectionname: "Shopping Needs",
      icon: "assets/ParentCategoryIcons/shopping.png",
      displaylist: "single",
      ordernum: 5,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "KITCHEN & DINING",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "KITCHEN & DINING",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/KITCHEN%20%26%20DINING.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387258&Signature=okhAzzNDJ7dM3elreGqtgCFxkKM%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "KITCHEN & DINING",
        },
        {
          servicetype: "HOME DECOR",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "HOME DECOR",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HOME%20DECOR.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387259&Signature=XJUmzqJRlJhItY9oerkjAKGuDvs%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "HOME DECOR",
        },
        {
          servicetype: "HOME FURNISHING",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "HOME FURNISHING",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HOME%20FURNISHING.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387260&Signature=%2Fo083eOfTtA5xm6YGrqfh82WNrY%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "HOME FURNISHING",
        },
        {
          servicetype: "GARMENTS",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "GARMENTS",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/GARMENTS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387261&Signature=5wtt96Z2RDETQ2yOPc8zdSibuss%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "GARMENTS",
        },
        {
          servicetype: "FASHION & TRAVEL ACCESSORIES",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "FASHION & TRAVEL ACCESSORIES",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FASHION%20%26%20TRAVEL%20ACCESSORIES.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387262&Signature=9QcRgoDM4sb7HQRRttWummKJrpE%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "FASHION & TRAVEL ACCESSORIES",
        },
        {
          servicetype: "BEAUTY & HEALTHCARE PRODUCTS",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "BEAUTY & HEALTHCARE PRODUCTS",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BEAUTY%20%26%20HEALTHCARE%20PRODUCTS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355433&Signature=Wt3QgRowklAqksCOvV0kH7p3rl8%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "BEAUTY & HEALTHCARE PRODUCTS",
        },
        {
          servicetype: "HARDWARE STORE",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "HARDWARE STORE",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HARDWARE%20STORE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387265&Signature=k1mFtTSfaEJRzT1SLLMNVsxR9EA%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "HARDWARE STORE",
        },
        {
          servicetype: "FURNITURE",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "FURNITURE",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FURNITURE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387333&Signature=mFk4camxsR9U%2Bm8yqA4WEkki0S8%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "FURNITURE",
        },
        {
          servicetype: "Phone",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "Phone",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Phone.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387267&Signature=XPsGYZ%2FWudqGx%2FrFGAXG7Sg6Slo%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "Phone",
        },
        {
          servicetype: "Laptop And Computers",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "Laptop And Computers",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Laptop%20And%20Computers.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387268&Signature=V0kBWgiDbu1EAYg0R2KP%2BJmo6uw%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "Laptop And Computers",
        },
        {
          servicetype: "Phone Accessories",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "Phone Accessories",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Phone%20Accessories.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387269&Signature=z1MdBJuOzPKk7d91D1lXe0HmaM4%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "Phone Accessories",
        },
        {
          servicetype: "SportsGoods",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "SportsGoods",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SportsGoods.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387270&Signature=I%2B%2B5gxXxPLLaC%2BvXYavACxS3HUE%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "SportsGoods",
        },
        {
          servicetype: "Music Instruments And Accessories",
          subsectionname: "Shopping Needs",
          atyp: "spp",
          grptype: "shop",
          querystr: "Music Instruments And Accessories",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Music%20Instruments%20And%20Accessories.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355434&Signature=iTfLee%2B3HC9iYL6GDp79Z8F4m0k%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Sell Product",
          name: "Music Instruments And Accessories",
        },
      ],
    },
    {
      sectionname: "VEHICLE",
      icon: "assets/ParentCategoryIcons/Vehicle.png",
      displaylist: "double",
      ordernum: 6,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Used Vehicles",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "CAR",
              subsectionname: "Used Vehicles",
              atyp: "VEHICLE",
              grptype: "VEHICLE",
              querystr: "VEHICLE/BUY/CAR AND SUV",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/CAR.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355435&Signature=xK%2BRidTV%2B7GwKJspwnUYGqsJsWI%3D",
              displaylist: "single",
              specialtype: "VEHICLE",
              isspecial: true,
              servicecat: "Vehicle",
              name: "Car And Suv",
            },
            {
              servicetype: "MOTORCYCLE",
              subsectionname: "Used Vehicles",
              atyp: "VEHICLE",
              grptype: "VEHICLE",
              querystr: "VEHICLE/BUY/MOTORCYCLE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MOTORCYCLE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355436&Signature=9a3N4z2dhkM5nhHY1noJ7D4bqxk%3D",
              displaylist: "single",
              specialtype: "VEHICLE",
              isspecial: true,
              servicecat: "Vehicle",
              name: "MOTORCYCLE",
            },
            {
              servicetype: "BUS",
              subsectionname: "Used Vehicles",
              atyp: "VEHICLE",
              grptype: "VEHICLE",
              querystr: "VEHICLE/BUY/BUS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BUS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355437&Signature=UUc1guLJEZNiIgvEXH7BSjPuYw8%3D",
              displaylist: "single",
              specialtype: "VEHICLE",
              isspecial: true,
              servicecat: "Vehicle",
              name: "BUS",
            },
            {
              servicetype: "TRUCK",
              subsectionname: "Used Vehicles",
              atyp: "VEHICLE",
              grptype: "VEHICLE",
              querystr: "VEHICLE/BUY/TRUCK",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/TRUCK.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355438&Signature=EOLjfav9eEZ7AqIcVVriBTxujPo%3D",
              displaylist: "single",
              specialtype: "VEHICLE",
              isspecial: true,
              servicecat: "Vehicle",
              name: "TRUCK",
            },
          ],
          name: "Used Vehicles",
        },
        {
          servicetype: "Vehicle Maintainence",
          displaylist: "double",
          ordernum: 2,
          childservicetype: [
            {
              servicetype: "REPAIR TWO WHEELERS",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "Two Wheeler",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Two%20Wheeler.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387278&Signature=hYqCJRXvBRAawkoh0QfrS5oCnb4%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Two Wheeler",
            },
            {
              servicetype: "REPAIR FOUR WHEELERS",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "Four Wheeler",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Four%20Wheeler.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387280&Signature=kfZs7iuCkpOk10GTTkhUiFP3r%2Fs%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Four Wheeler",
            },
            {
              servicetype: "REPAIR TRUCKS BUSES",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "Trucks Buses ",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Trucks%20Buses.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387282&Signature=4g3YIvSa4iaiE6YggQgnaKjBB9g%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Trucks Buses ",
            },
            {
              servicetype: "BODYSHOP TWO WHEELERS",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "Bodyshop Two",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BODYSHOP%20TWO%20WHEELERS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355440&Signature=aJyJW%2Bki%2BgCAjmQ2%2B1AoOIRAsxQ%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Bodyshop Two",
            },
            {
              servicetype: "BODYSHOP FOUR WHEELERS",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "Bodyshop Four",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BODYSHOP%20FOUR%20WHEELERS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387284&Signature=G2y1bURPyXAzJTZ6ggvmYXJCwZk%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Bodyshop Four",
            },
            {
              servicetype: "BODYSHOP - TRUCKS BUSES",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "BodyShop Truck",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BODYSHOP%20-%20TRUCKS%20BUSES.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355441&Signature=6J3lpaeujQAoe%2FnT1IagJOiybm0%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "BodyShop Truck",
            },
            {
              servicetype: "WHEEL ALLIGNMENT",
              subsectionname: "Vehicle Maintainence",
              atyp: "sps",
              grptype: "shop",
              querystr: "Wheel Allignment",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/WHEEL%20ALLIGNMENT.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387287&Signature=FDlP59FJw%2BoeJlMxfU6l9tR5CFs%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Wheel Allignment",
            },
          ],
          name: "Vehicle Maintainence",
        },
        {
          servicetype: "Vehicle Parts And Accesory",
          displaylist: "double",
          ordernum: 3,
          childservicetype: [
            {
              servicetype: "AUTOMOTIVE PARTS",
              subsectionname: "Vehicle Parts And Accesory",
              atyp: "spp",
              grptype: "shop",
              querystr: "Automative Parts",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/AUTOMOTIVE%20PARTS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355445&Signature=JREx8DMnTtlf9dwYp37crXjwmf0%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "Automative Parts",
            },
            {
              servicetype: "AUTOMOTIVE ACCESSORIES",
              subsectionname: "Vehicle Parts And Accesory",
              atyp: "spp",
              grptype: "shop",
              querystr: "Automative Accessories",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Automative%20Accessories.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355446&Signature=xtI4nY6VXwNoiFxNsq8CH3e8faA%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Sell Product",
              name: "Automative Accessories",
            },
          ],
          name: "Vehicle Parts And Accesory",
        },
        {
          servicetype: "Used Vehicles Dealer",
          displaylist: "double",
          ordernum: 3,
          childservicetype: [
            {
              servicetype: "USED VEHICLE SALE-PURCHASE",
              subsectionname: "Used Vehicles Dealer",
              atyp: "VEHICLE",
              grptype: "shop",
              querystr: "USED VEHICLE SALE-PURCHASE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/USED%20VEHICLE%20SALE-PURCHASE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355448&Signature=MVm8kcXoSEuSO1QOE06nkiG58hw%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Agents/Brokers - RealEstate/Property/Job/Pet-Farm",
              name: "Sale Purchase Agents",
            },
          ],
          name: "Used Vehicles Dealer",
        },
      ],
    },
    {
      sectionname: "REAL ESTATE",
      icon: "assets/ParentCategoryIcons/DailyNeeds.png",
      displaylist: "double",
      ordernum: 7,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Real Estate:Buy",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "House",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/HOUSES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Houses.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355449&Signature=O7Jlf2yVg0OXuIILwZ1YAblsctQ%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Houses",
            },
            {
              servicetype: "Flat",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/FLATS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Flats.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387317&Signature=EgOo3McaSj1qBU5wmu%2BJCsUT4sQ%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Flats",
            },
            {
              servicetype: "Plot,Land",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/PLOT,LAND",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Plot%2CLand.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387296&Signature=X2fBhSarakzxdFGO0XVsi2ePewM%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Plot,Land",
            },
            {
              servicetype: "Office Space",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/OFFICE SPACE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Office%20Space.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387308&Signature=zfktdOr3krRfj1cDwDuVVbdguOI%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Office Space",
            },
            {
              servicetype: "Shop,Showroom",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/SHOP,SHOWROOM",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Shop%2CShowroom.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387311&Signature=dc%2Fbl5QCIeNqnO%2By0E4kLhybScU%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Shop,Showroom",
            },
            {
              servicetype: "Warehouse",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/WAREHOUSE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Warehouse.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387312&Signature=cFM7aeVR4AJedKl%2FpW9tzUDJHuo%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Warehouse",
            },
            {
              servicetype: "Industrial Building",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/INDUSTRIAL BUILDING",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Industrial%20Building.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387313&Signature=%2B3Wljp4kqi6Oc%2F7X0%2F3cM3c8xz4%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Industrial Building",
            },
            {
              servicetype: "Industrial Shed",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/INDUSTRIAL SHED",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Industrial%20Shed.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387315&Signature=joc0HTnTj9waGJzo%2FVtKxtbMfDM%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Industrial Shed",
            },
            {
              servicetype: "Commercial Land",
              subsectionname: "Real Estate:Buy",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/SALE/COMMERCIAL LAND",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Commercial%20Land.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355450&Signature=2YS7Cx%2BmDnu9m%2BkA9GcsqqPOIXI%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Commercial Land",
            },
          ],
          name: "Real Estate:Buy",
        },
        {
          servicetype: "Real Estate:Rent",
          displaylist: "double",
          ordernum: 2,
          childservicetype: [
            {
              servicetype: "Houses",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/HOUSES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Houses.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355453&Signature=%2FGx%2FlAzwQPhGVFy5vduzzBOzY8o%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Houses",
            },
            {
              servicetype: "Flats",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/FLATS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Flats.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387317&Signature=EgOo3McaSj1qBU5wmu%2BJCsUT4sQ%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Flats",
            },
            {
              servicetype: "Office Space",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/OFFICE SPACE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Office%20Space.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387308&Signature=zfktdOr3krRfj1cDwDuVVbdguOI%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Office Space",
            },
            {
              servicetype: "Shop,Showroom",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/SHOP,SHOWROOM",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Shop%2CShowroom.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387311&Signature=dc%2Fbl5QCIeNqnO%2By0E4kLhybScU%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Shop,Showroom",
            },
            {
              servicetype: "Warehouse",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/WAREHOUSE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Warehouse.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387312&Signature=cFM7aeVR4AJedKl%2FpW9tzUDJHuo%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Warehouse",
            },
            {
              servicetype: "Industrial Building",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/INDUSTRIAL BUILDING",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Industrial%20Building.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387313&Signature=%2B3Wljp4kqi6Oc%2F7X0%2F3cM3c8xz4%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Industrial Building",
            },
            {
              servicetype: "Industrial Shed",
              subsectionname: "Real Estate:Rent",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/INDUSTRIAL SHED",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Industrial%20Shed.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387315&Signature=joc0HTnTj9waGJzo%2FVtKxtbMfDM%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Industrial Shed",
            },
          ],
          name: "Real Estate:Rent",
        },
        {
          servicetype: "Real Estate:PG",
          displaylist: "double",
          ordernum: 3,
          childservicetype: [
            {
              servicetype: "Houses",
              subsectionname: "Real Estate:PG",
              atyp: "REALESTATE",
              grptype: "REALESTATE",
              querystr: "REALESTATE/RENT/HOUSES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Houses.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355454&Signature=X7XZBQ8I2GtnVzGB1HW1kSswB50%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Houses",
            },
            {
              servicetype: "Flats",
              subsectionname: "Real Estate:PG",
              atyp: "REALESTATE",
              grptype: "FARMPROD",
              querystr: "REALESTATE/RENT/FLATS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Flats.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387317&Signature=EgOo3McaSj1qBU5wmu%2BJCsUT4sQ%3D",
              displaylist: "single",
              specialtype: "REALESTATE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Flats",
            },
          ],
          name: "Real Estate:PG",
        },
        {
          servicetype: "Real Estate:Brokers & Contractors",
          displaylist: "double",
          ordernum: 4,
          childservicetype: [
            {
              servicetype: "PROPERTY DEALER - REAL ESTATE AGENT",
              subsectionname: "Real Estate:Brokers & Contractors",
              atyp: "REALESTATE",
              grptype: "shop",
              querystr: "PROPERTY DEALER - REAL ESTATE AGENT",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Agent.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387322&Signature=c%2BLXGbhSbj3A66vq1XucodNip68%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Agents/Brokers - RealEstate/Property/Job/Pet-Farm",
              name: "Agent",
            },
            {
              servicetype: "Building Contractors",
              subsectionname: "Real Estate:Brokers & Contractors",
              atyp: "sps",
              grptype: "shop",
              querystr: "Building Contractors",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Building%20Contractors.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355455&Signature=Zek8uiEsfhv2hw367qRom9oW5CY%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Services",
              name: "Building Contractors",
            },
          ],
          name: "Real Estate:Brokers & Contractors",
        },
      ],
    },
    {
      sectionname: "Farm Animal & Pets",
      icon: "assets/ParentCategoryIcons/FarmAnimalPets.png",
      displaylist: "single",
      ordernum: 8,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "FARMING",
          subsectionname: "Farm Animal & Pets",
          atyp: "PET",
          grptype: "PET",
          querystr: "PET/FARMING",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FARMING.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355457&Signature=rxiz6kJl9WvAwbnFZpz8iEM%2F%2F1M%3D",
          displaylist: "single",
          specialtype: "PET",
          isspecial: true,
          servicecat: "CLASSIFIED",
          name: "FARMING",
        },
        {
          servicetype: "PET",
          subsectionname: "Farm Animal & Pets",
          atyp: "PET",
          grptype: "PET",
          querystr: "PET/PET",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PET.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355458&Signature=97xOKBikSq4yIDEssaaQeyPVYsk%3D",
          displaylist: "single",
          specialtype: "PET",
          isspecial: true,
          servicecat: "CLASSIFIED",
          name: "PET",
        },
        {
          servicetype: "MILK",
          subsectionname: "Farm Animal & Pets",
          atyp: "PET",
          grptype: "PET",
          querystr: "PET/MILK",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MILK.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355459&Signature=uBoLkhLZvIPTITD13x3YdyBf0Ew%3D",
          displaylist: "single",
          specialtype: "PET",
          isspecial: true,
          servicecat: "CLASSIFIED",
          name: "MILK",
        },
        {
          servicetype: "PETS/FARM ANIMAL SALE",
          subsectionname: "Farm Animal & Pets",
          atyp: "PET",
          grptype: "shop",
          querystr: "PETS/FARM ANIMAL SALE",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/USED%20VEHICLE%20SALE-PURCHASE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355460&Signature=YW6PTZ2B3865hxIZC1jLkpfuCbc%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Agents/Brokers - RealEstate/Property/Job/Pet-Farm",
          name: "Sale Purchase Agents",
        },
      ],
    },
    {
      sectionname: "JOB",
      icon: "assets/ParentCategoryIcons/jobs.png",
      displaylist: "single",
      ordernum: 9,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "PartTime",
          subsectionname: "JOB",
          atyp: "JOB",
          grptype: "JOB",
          querystr: "JOB/PARTTIME",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PartTime.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387323&Signature=6gNpmLB74rBOgIB%2BxSskOPBy84M%3D",
          displaylist: "single",
          specialtype: "JOB",
          isspecial: true,
          servicecat: "CLASSIFIED",
          name: "PartTime",
        },
        {
          servicetype: "FullTime",
          subsectionname: "JOB",
          atyp: "JOB",
          grptype: "JOB",
          querystr: "JOB/FULLTIME",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FullTime.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387324&Signature=daOTDZt6BqK6voeywVlWepEC%2FyQ%3D",
          displaylist: "single",
          specialtype: "JOB",
          isspecial: true,
          servicecat: "CLASSIFIED",
          name: "FullTime",
        },
        {
          servicetype: "Placement Services",
          subsectionname: "JOB",
          atyp: "JOB",
          grptype: "shop",
          querystr: "Placement Services",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Placement%20Services.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387325&Signature=SU8eBGGmKLRC58EclVf6W5cPtL0%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Agents/Brokers - RealEstate/Property/Job/Pet-Farm",
          name: "Placement Services",
        },
      ],
    },
    {
      sectionname: "Booking and Rental",
      icon: "assets/ParentCategoryIcons/agreement.png",
      displaylist: "double",
      ordernum: 10,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Hotel & Wedding Garder",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "Hotel",
              subsectionname: "Hotel & Wedding Garder",
              atyp: "HOTELROOM",
              grptype: "HOTELROOM",
              querystr: "HOTELROOM",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Hotel.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387327&Signature=m%2BRxg2moEZoAFhS%2BSx6l68dMClU%3D",
              displaylist: "single",
              specialtype: "HOTELROOM",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "Hotel",
            },
            {
              servicetype: "HomeStay",
              subsectionname: "Hotel & Wedding Garder",
              atyp: "HOMESTAY",
              grptype: "HOMESTAY",
              querystr: "HOMESTAY",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/HomeStay.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387329&Signature=ED9osN08f%2F%2B4WWfGxRTVHGCRs6U%3D",
              displaylist: "single",
              specialtype: "HOMESTAY",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "HomeStay",
            },
            {
              servicetype: "BANQUET",
              subsectionname: "Hotel & Wedding Garder",
              atyp: "BANQUET",
              grptype: "BANQUET",
              querystr: "BANQUET",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/BANQUET.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387331&Signature=zS2vT6qMtDrIPLfxk62DClNPJbk%3D",
              displaylist: "single",
              specialtype: "BANQUET",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "BANQUET",
            },
            {
              servicetype: "GARDEN & FARMHOUSE",
              subsectionname: "Hotel & Wedding Garder",
              atyp: "GARDEN & FARMHOUSE",
              grptype: "GARDEN & FARMHOUSE",
              querystr: "GARDEN & FARMHOUSE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/GARDEN%20%26%20FARMHOUSE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387333&Signature=VB%2F5PutS2D0HM4YnjLYbCIqIyQ8%3D",
              displaylist: "single",
              specialtype: "GARDEN & FARMHOUSE",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "GARDEN & FARMHOUSE",
            },
          ],
          name: "Hotel & Wedding Garder",
        },
        {
          servicetype: "Rentals",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "FURNITURE",
              subsectionname: "Rentals",
              atyp: "ccp",
              grptype: "RENTPROD",
              querystr: "FURNITURE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FURNITURE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387333&Signature=mFk4camxsR9U%2Bm8yqA4WEkki0S8%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "CLASSIFIED",
              name: "FURNITURE",
            },
            {
              servicetype: "LAPTOP",
              subsectionname: "Rentals",
              atyp: "ccp",
              grptype: "RENTPROD",
              querystr: "LAPTOP",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/LAPTOP.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355462&Signature=4kQ5oktzKURQVwN70dNhYu7E%2FwE%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "CLASSIFIED",
              name: "LAPTOP",
            },
          ],
          name: "Rentals",
        },
      ],
    },
    {
      sectionname: "Direct From Farm",
      icon: "assets/ParentCategoryIcons/DailyNeeds.png",
      displaylist: "double",
      ordernum: 12,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Direct From Farm",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "VEGETABLE",
              subsectionname: "Direct From Farm",
              atyp: "FARMPROD",
              grptype: "FARMPROD",
              querystr: "VEGETABLE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Vegetable.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387335&Signature=YeX1ej55AcNBs9pfXO6O05KCu3c%3D",
              displaylist: "single",
              specialtype: "FARMPROD",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "VEGETABLE",
            },
            {
              servicetype: "FRUITS",
              subsectionname: "Direct From Farm",
              atyp: "FARMPROD",
              grptype: "FARMPROD",
              querystr: "FRUITS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/Fruits.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355463&Signature=vyJUwoDM7T1qrRG0gbWavAhQNe0%3D",
              displaylist: "single",
              specialtype: "FARMPROD",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "FRUITS",
            },
            {
              servicetype: "PULSES",
              subsectionname: "Direct From Farm",
              atyp: "FARMPROD",
              grptype: "FARMPROD",
              querystr: "PULSES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PULSES.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387339&Signature=87WtmUulloR9hZ25%2FvZZUI6hDf8%3D",
              displaylist: "single",
              specialtype: "FARMPROD",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "PULSES",
            },
            {
              servicetype: "WHEAT",
              subsectionname: "Direct From Farm",
              atyp: "FARMPROD",
              grptype: "FARMPROD",
              querystr: "WHEAT",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/WHEAT.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387339&Signature=1eeYOS0xTZCvIynoQ7cSnCpmB84%3D",
              displaylist: "single",
              specialtype: "FARMPROD",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "WHEAT",
            },
            {
              servicetype: "RICE",
              subsectionname: "Direct From Farm",
              atyp: "FARMPROD",
              grptype: "FARMPROD",
              querystr: "RICE",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/RICE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387340&Signature=1t4cdA42i4EB0LS%2FJzWrSSsDnd0%3D",
              displaylist: "single",
              specialtype: "FARMPROD",
              isspecial: true,
              servicecat: "CLASSIFIED",
              name: "RICE",
            },
          ],
          name: "Direct From Farm",
        },
        {
          servicetype: "Farmers",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "FARMER",
              subsectionname: "Farmers",
              atyp: "",
              grptype: "shop",
              querystr: "",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FARMER.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387341&Signature=E%2Bkw8pjXcY3SaAdZ8VEZMMv1ILc%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Farming And LiveStock",
              name: "FARMER",
            },
          ],
          name: "Farmers",
        },
      ],
    },
    {
      sectionname: "Logistics And Mass Transit",
      icon: "assets/ParentCategoryIcons/DailyNeeds.png",
      displaylist: "double",
      ordernum: 13,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "Logistics",
          displaylist: "double",
          ordernum: 1,
          childservicetype: [
            {
              servicetype: "MOVERS AND LOGISTICS",
              subsectionname: "Logistics",
              atyp: "",
              grptype: "shop",
              querystr: "MOVERS AND LOGISTICS",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/MOVERS%20AND%20LOGISTICS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355464&Signature=AS5Gn7DFuKAToUzXIxr27Ku6unw%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Logistics",
              name: "MOVERS AND LOGISTICS",
            },
            {
              servicetype: "FLEETMANAGEMENT",
              subsectionname: "Logistics",
              atyp: "",
              grptype: "shop",
              querystr: "FLEETMANAGEMENT",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/FLEETMANAGEMENT.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387343&Signature=1%2BkjmgWOvWWuy0kqwZ3o%2F7nmgW0%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Logistics",
              name: "FLEETMANAGEMENT",
            },
          ],
          name: "Logistics",
        },
        {
          servicetype: "Mass Tranist",
          displaylist: "double",
          ordernum: 2,
          childservicetype: [
            {
              servicetype: "PUBLIC BUS TRAVEL",
              subsectionname: "Mass Tranist",
              atyp: "",
              grptype: "shop",
              querystr: "PUBLIC BUS TRAVEL",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PUBLIC%20BUS%20TRAVEL.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387345&Signature=%2FeU%2BBZSg9vRZCBF32HsJ8207WeU%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Mass Tranist",
              name: "PUBLIC BUS TRAVEL",
            },
            {
              servicetype: "PRIVATE BUS TRAVEL",
              subsectionname: "Mass Tranist",
              atyp: "",
              grptype: "shop",
              querystr: "PRIVATE BUS TRAVEL",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PRIVATE%20BUS%20TRAVEL.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387345&Signature=Go0LnzHrbJshqlO7u2vOEeAGQQ8%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Mass Tranist",
              name: "PRIVATE BUS TRAVEL",
            },
            {
              servicetype: "PRIVATE RIDE SHARE - VAN/JEEP",
              subsectionname: "Mass Tranist",
              atyp: "",
              grptype: "shop",
              querystr: "PRIVATE RIDE SHARE - VAN/JEEP",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/PRIVATE%20RIDE%20SHARE%20-%20VAN%20JEEP.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355465&Signature=2oadXcRaLImSwttdlnrQq8g0Y68%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Mass Tranist",
              name: "PRIVATE RIDE SHARE - VAN/JEEP",
            },
            {
              servicetype: "OFFICE PICK AND DROP SERVICES",
              subsectionname: "Mass Tranist",
              atyp: "",
              grptype: "shop",
              querystr: "OFFICE PICK AND DROP SERVICES",
              icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/OFFICE%20PICK%20AND%20DROP%20SERVICES.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355473&Signature=XdiFCebKWuDTFv6Qu8aCIDGsExI%3D",
              displaylist: "single",
              specialtype: "",
              servicecat: "Mass Tranist",
              name: "OFFICE PICK AND DROP SERVICES",
            },
          ],
          name: "Mass Tranist",
        },
      ],
    },
    {
      sectionname: "Groups and NGO",
      icon: "assets/ParentCategoryIcons/officeComplex.png",
      displaylist: "single",
      ordernum: 14,
      type: "shop",
      servicetypelist: [
        {
          servicetype: "WORKER-WELFARE",
          subsectionname: "Groups and NGO",
          atyp: "",
          grptype: "shop",
          querystr: "WORKER-WELFARE",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/WORKER-WELFARE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355474&Signature=41tJ0xiZq%2FzSFS50Qkd2D2d%2BEbM%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Groups and NGO",
          name: "WORKER-WELFARE",
        },
        {
          servicetype: "SERVICEPROVIDER-WELFARE",
          subsectionname: "Groups and NGO",
          atyp: "",
          grptype: "shop",
          querystr: "SERVICEPROVIDER-WELFARE",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SERVICEPROVIDER-WELFARE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387352&Signature=w7jaxSpW%2BEGzojkQHasG%2BSsRf04%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Groups and NGO",
          name: "SERVICEPROVIDER-WELFARE",
        },
        {
          servicetype: "NGO",
          subsectionname: "Groups and NGO",
          atyp: "",
          grptype: "shop",
          querystr: "NGO",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/NGO.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2017355476&Signature=z2KCo9xjx%2F9oasf9q%2FkEKnKW04A%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Groups and NGO",
          name: "NGO",
        },
        {
          servicetype: "RESIDENT-WELFARE",
          subsectionname: "Groups and NGO",
          atyp: "",
          grptype: "shop",
          querystr: "RESIDENT-WELFARE",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/RESIDENT-WELFARE.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387354&Signature=htUco%2B9%2BrOYEoBPASZmrPrtubgE%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Groups and NGO",
          name: "RESIDENT-WELFARE",
        },
        {
          servicetype: "SPECIAL INTEREST GROUPS",
          subsectionname: "Groups and NGO",
          atyp: "",
          grptype: "shop",
          querystr: "SPECIAL INTEREST GROUPS",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/SPECIAL%20INTEREST%20GROUPS.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387355&Signature=Mb44GTg8mCJL4T8ggzjHwFXZHLM%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Groups and NGO",
          name: "SPECIAL INTEREST GROUPS",
        },
        {
          servicetype: "COMPLEX",
          subsectionname: "Groups and NGO",
          atyp: "",
          grptype: "shop",
          querystr: "COMPLEX",
          icon: "https://appstorage.sgp1.digitaloceanspaces.com/EXPLORECONFIG/IMAGE/COMPLEX.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2016387358&Signature=95LIJowD5aZqxlL1auSdHXtyPcs%3D",
          displaylist: "single",
          specialtype: "",
          servicecat: "Groups and NGO",
          name: "COMPLEX",
        },
      ],
    },
  ],
};
