import { LimitedPackageData } from "src/types/generatedTypes";

export interface ICartSlice {
  cartState?: ICartState;
  isLoading?: boolean;
  isError?: boolean;
}

export interface ILatLng {
  latitude?: number;
  longitude?: number;
}

export interface itemreturnstatus {
  itemcount?: number;
  returndate?: Date;
  reslst?: string[];
  specialcharges?: number;
  comments?: string;
}

export interface productinorder {
  id?: string;
  productid?: string;
  worksta?: string;
  itemid?: string;
  tileimage?: string;
  ratecard?: RateRules;
  perioddiscountlst?: longanddouble[];
  title?: string;
  unit?: string;
  unitprice?: string;
  varianttype?: string;
  finid?: string;
  qty?: number;
  builderid?: string;
  price?: number;
  discountedprice?: number;
  taxid?: string;
  totalprice?: number;
  reportid?: string[];
  taxbracket?: number;
  gsttoken?: string;
  category?: string;
  rcat?: string;
  rtype?: string;
  returncomplete?: boolean;
  forcedcomplete?: boolean;
  shortqty?: number;
  returnstatus?: itemreturnstatus[];
  namedrentalres?: boolean;
  reslist?: string[];
}

export interface PerioddowRate {
  startdate?: Date;
  enddate?: Date;
  type?: string;
  gr?: number;
  sunr?: number;
  monr?: number;
  tuer?: number;
  wedr?: number;
  thur?: number;
  frir?: number;
  satr?: number;
}

export interface longanddouble {
  name?: number;
  value?: number;
}

export interface OccupancyRateSurcharge {
  occup?: number;
  surcharge?: number;
}

export interface RateRules {
  docid?: string;
  prodid?: string;
  rulename?: string;
  startdate?: Date;
  enddate?: Date;
  prior?: number;
  o1A0c?: number;
  o1A1c?: number;
  o1A2c?: number;
  o2A0c?: number;
  o2A1c?: number;
  o2A2c?: number;
  onew?: number;
  onem?: number;
  threem?: number;
  sixm?: number;
  oneyr?: number;
  basep?: number;
  rateruletype?: string;
  pratelist?: PerioddowRate[];
  orslist?: OccupancyRateSurcharge;
  durslist?: OccupancyRateSurcharge;
  perioddiscountlst?: longanddouble[];
  namelist?: string[];
  updatedby?: string;
  version?: number;
  lpversion?: number;
  sid?: string;
}

export interface MemberFreeFlowNotes {
  date?: Date;
  data?: string;
  idenitier?: string;
  shareddate?: Date;
  vendorid?: string;
  vendorname?: string;
}

export interface DoctorCaseShareInfo {
  customername?: string;
  customerphone?: string;
  dob?: Date;
  sex?: string;
  serviceproviderid?: string;
  sname?: string;
  drname?: string;
  sharedate?: Date;
  sharedinfo?: MemberFreeFlowNotes[];
}

export interface ServiceMemberGrpInfo {
  name?: string;
  cid?: string;
  dob?: Date;
  sex?: string;
}

export interface productinorderdevice {
  id?: string;
  productid?: string;
  itemid?: string;
  tileimage?: string;
  title?: string;
  unit?: string;
  unitprice?: string;
  qty?: number;
  price?: number;
  discountedprice?: number;
  taxid?: string;
  totalprice?: number;
  returncomplete?: boolean;
  returnstatus?: itemreturnstatus[];
}

export interface orderinfomodel {
  custaddress?: AddressDataModel;
  aptid?: string;
  tbllst?: string[];
  shareinfo?: DoctorCaseShareInfo;
  encshareinfostr?: string;
  customerid?: string;
  dorigin?: string;
  custuserid?: string;
  custname?: string;
  grpmem?: ServiceMemberGrpInfo;
  advanceamount?: number;
  productlist?: productinorder[];
  dproductlist?: productinorderdevice[];
  totaltax?: number;
  totalcalculatedprice?: number;
  extradiscount?: number;
  askedprice?: number;
  finalprice?: number;
  amountpaid?: number;
  orderdate?: number;
  orderstate?: string;
  serviceproviderid?: string;
  custphonenum?: string;
  omsgnumber?: string;
  oorigin?: string;
  staffid?: string;
  lineid?: string;
  id?: string;
  sts?: string;
  ordertype?: string;
  refspid?: string;
  refspname?: string;
  refspnum?: string;
  delid?: string;
  reqdel?: number;
  delstate?: string;
  complocaldate?: Date;
  remoteiden?: string;
  changenum?: number;
  isopen?: boolean;
  delamt?: number;
  // detitemlist?: deldetails[];
  sdate?: Date;
  edate?: Date;
  edatests?: string;
  resname?: string;
  mstaffid?: string;
  mstaffname?: string;
  isrental?: boolean;
  isservice?: boolean;
  version?: number;
  sid?: string;
  updatedby?: string;
  addtoledger?: boolean;
  masterorder?: any;
  childorders?: any[];
  rtype?: string;
  cusphotoid?: string;
}

export interface AddressDataModel {
  country?: string;
  state?: string;
  district?: string;
  town_village?: string;
  area_sector?: string;
  societyname?: string;
  addressline?: string;
  latitude?: number;
  longitude?: number;
  pincode?: string;
  caddr?: string;
}

export enum CartOrigin {
  NotSP,
  AppointmentEndUser,
  EcomEndUser,

  OrderManagementOwner,
  OrderManagementOwnerline,
  OrderManagementOwnerbuilder,
  AppointmentOwner,

  OrderHistory,
}

export interface SPPaymentInfo {
  payeeto?: string | null;
  phonenum?: string | null;
  allowedmech?: string | null;
  address?: string | null;
  pqrcodeimage?: string | null;
  tinnum?: string | null;
  gsttype?: string | null;
  flatrate?: number | null;
}

export interface LimitedServiceModel {
  phone?: string[] | null;
  servicetype?: string[] | null;
  address?: string | null;
  adm?: AddressDataModel | null;
  hasprivyecom?: boolean | null;
  hasprivyapt?: boolean | null;
  hascustbrack?: boolean | null;
  onlinetripbooking?: boolean | null;
  email?: string | null;
  photo1?: string | null;
  servicename?: string | null;
  timeinfo?: string | null;
  serviceID?: string | null;
  ratingcount?: number | null;
  totalratings?: number | null;
  hasapt?: boolean | null;
  hasadhoctrips?: boolean | null;
  hasecom?: boolean | null;
  hasproductcatalog?: boolean | null;
  hasdevice?: boolean | null;
  cname?: string | null;
  hasqapt?: boolean | null;
  hasrapt?: boolean | null;
  hasvapt?: boolean | null;
  apttype?: string | null;
  serviceoffered?: string[] | null;
  providerdelivery?: string | null;
  delchargeperunit?: number | null;
  mindelcharges?: number | null;
  maxradius?: number | null;
  spi?: SPPaymentInfo | null;
  defaulttapaction?: string | null;
  crid?: string | null;
  appname?: string | null;
  tz?: number | null;
  servicecat?: string | null;
}

export interface ICartState {
  readyfordetailorigin?: boolean;
  selectedItem?: LimitedPackageData | null;
  loading: boolean;
  cart?: orderinfomodel | null;
  hasError: boolean;
  prodtype?: string | null;
  ordersaved: boolean;
  error?: string | null;
  calledfrom?: CartOrigin | null;
  entitytype?: string | null;
  entityid?: string | null;
  customerid?: string | null;
  loggedinuser?: boolean | null;
  lsm?: LimitedServiceModel | null;
  sm?: any | null;
  orderid?: string | null;
  forshowingprod?: boolean | null;
  custaddresslist?: any[] | null;
  rentalitemreslst?: any[] | null;
  useraddressloaded?: boolean | null;
  rrfi?: any | null;
  pfrm?: any | null;
  tableresourcelst?: string[] | null;
  resavailsta?: any[] | null;
  respid?: string | null;
  resprice?: number | null;
  resavail?: boolean | null;
  sdt?: Date | null;
  edt?: Date | null;
  inventsta?: any[] | null;
}

export interface UserSavedAddress {
  shortname?: string;
  adm?: AddressDataModel | null;
}

export interface IFetchUserAddresses {
  data: {
    ugm: {
      useraddresslist: [
        {
          shortname: string;
          adm: {
            country: string;
            state: string;
            district: string;
            town_village: string;
            area_sector: string;
            societyname: string;
            addressline: string;
            latitude: number;
            longitude: number;
          };
        }
      ];
      uid: string;
      action: string;
      sid: string;
      tzoffset: number;
    };
    qrytype: string;
  };
}

export interface ISaveUserAddress {
  address: UserSavedAddress;
  sid: string;
  uid: string;
}

export interface IUpdateUserAddressResult {
  data: {
    signupreq: any | null;
    favsidlst: any | null;
    genstr: any | null;
    genfieldupdate: any | null;
    userentity: any | null;
    useraddresslist: [
      {
        shortname: string;
        adm: {
          country: string;
          state: string;
          district: string;
          town_village: string;
          area_sector: string;
          societyname: string;
          addressline: string;
          latitude: number;
          longitude: number;
          pincode?: any;
          caddr?: any;
        };
      }
    ];
    tokeninfo?: any | null;
    sm?: any | null;
    errormsg?: any | null;
    errorid?: number;
    uid?: string;
    um?: any | null;
    action?: any | null;
    sid?: any | null;
    chslist?: any | null;
    chdatalist?: any | null;
    appnamewithroomforsp?: any | null;
    credinfolist?: any | null;
    information?: any | null;
    ccr?: any | null;
    tzoffset?: any | null;
    tpl?: any | null;
    lssr?: any | null;
    lrela?: any | null;
    prodidlst?: any | null;
    useridlst?: any | null;
    spidlst?: any | null;
    proddatalst?: any | null;
    userdatalst?: any | null;
    spdatalst?: any | null;
  };
}
