import { useState } from "react";
import { AppBar } from "./AppBar";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppSearch } from "./pages/AppSearch";
import { TopbarLocation } from "./pages/TopbarLocation";
import { Home, AccountCircle, ShoppingCart, Person } from "@mui/icons-material";
import { auth } from "src/configs/firebase";
import { setLogout } from "src/slices/auth";
import { UserContact } from "./pages/UserContact";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import RegisterIcon from "@mui/icons-material/HowToReg";
import HistoryIcon from "@mui/icons-material/History";
import logo from "src/icons/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import {
  selectLsm,
  selectShopAbout,
  selectWebpageData,
} from "src/slices/landing-page/selectors";
import { getBestFitImage } from "src/lib/utils";
import { DEVICE_TYPES } from "src/constants";
import { selectCartState } from "src/slices/cart/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const drawerWidth: number = 240;

export const TopBar = ({ showLogoutIcon }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { text1, foregroundColor } = useDisplaySettingsContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const aboutShop = useSelector(selectShopAbout);
  const wpd = useSelector(selectWebpageData);
  const lsm = useSelector(selectLsm);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cartState = useSelector(selectCartState);

  return (
    <AppBar
      position="absolute"
      elevation={0}
      open={open}
      drawerWidth={drawerWidth}
    >
      <Toolbar
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        style={{ padding: "0 50px" }}
      >
        {aboutShop?.logoImage && (
          <Box
            height={isMobile ? "30px" : "50px"}
            component="img"
            src={
              getBestFitImage(
                isMobile ? DEVICE_TYPES.MOBILE : DEVICE_TYPES.DESKTOP,
                aboutShop.logoImage.difresimglst
              )?.value
            }
            alt="logo"
            sx={{
              "&:hover": showLogoutIcon && { cursor: "pointer" },
            }}
            onClick={() => {
              if (showLogoutIcon) {
                navigate("/");
              }
            }}
          />
        )}
        {!aboutShop?.logoImage && (
          <div
            style={{
              height: isMobile ? "30px" : "50px",
              flexGrow: 1,
              width: isMobile ? "50px" : "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: aboutShop?.logoImage
                ? "transparent"
                : foregroundColor,
              borderRadius: "10px",
              marginRight: "20px",
            }}
          >
            <Box
              height={isMobile ? "30px" : "50px"}
              component="img"
              src={logo}
              alt="logo"
              sx={{
                "&:hover": showLogoutIcon && { cursor: "pointer" },
              }}
              onClick={() => {
                if (showLogoutIcon) {
                  navigate("/");
                }
              }}
            />
          </div>
        )}

        <div style={{ flexGrow: 1 }}>
          {!wpd || wpd?.isMarket ? (
            <AppSearch />
          ) : (
            <Box sx={{ width: "50vw", pl: 3 }}>
              {/* <Stack direction="row" gap={10}>
                <Typography>Home</Typography>
                <Typography>Contact Us</Typography>
                <Typography>About Us</Typography>
                <Typography>Home</Typography>
              </Stack> */}
            </Box>
          )}
        </div>

        {!isMobile && (
          <div style={{ flexGrow: 1, maxWidth: "300px" }}>
            <TopbarLocation />
          </div>
        )}
        {!isMobile && aboutShop?.phoneNo && (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UserContact contactNumber={aboutShop?.phoneNo[0]} />
          </div>
        )}
        {cartState?.cart?.productlist?.length > 0 && (
          <IconButton
            sx={{ color: foregroundColor }}
            onClick={() => {
              if (!auth?.currentUser?.isAnonymous) {
                navigate("/cart");
              } else {
                navigate("/login");
              }
            }}
          >
            <Badge
              badgeContent={cartState?.cart?.productlist?.length ?? 0}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ShoppingCart />
            </Badge>
          </IconButton>
        )}
        {/* {!isMobile && (
          <IconButton
            sx={{ color: foregroundColor }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Home />
          </IconButton>
        )}

        {!isMobile && showLogoutIcon && (
          <Grid item>
            <IconButton
              sx={{ color: foregroundColor }}
              onClick={
                showLogoutIcon
                  ? () => {
                      navigate("/order-history");
                    }
                  : null
              }
            >
              <HistoryIcon />
            </IconButton>
          </Grid>
        )}
        {!isMobile && !showLogoutIcon && (
          <Grid item>
            <IconButton
              sx={{ color: foregroundColor }}
              onClick={async () => {
                try {
                  navigate("/login");
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <LoginIcon />
            </IconButton>
          </Grid>
        )}
        {!isMobile && showLogoutIcon && (
          <Grid item>
            <IconButton
              sx={{ color: foregroundColor }}
              onClick={async () => {
                try {
                  navigate("/register");
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <RegisterIcon />
            </IconButton>
          </Grid>
        )}
        {!isMobile && showLogoutIcon && (
          <Grid item>
            <IconButton
              sx={{ color: foregroundColor }}
              onClick={async () => {
                try {
                  await auth.signOut();
                  dispatch(setLogout());
                  localStorage.clear();
                  navigate("/login");
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Grid>
        )} */}

        {
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{ color: foregroundColor }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {isMobile && (
                <MenuItem onClick={handleClose}>
                  <TopbarLocation />
                </MenuItem>
              )}
              <MenuItem
                sx={{ text1 }}
                onClick={() => {
                  navigate("/");
                  handleClose();
                }}
              >
                <Home sx={{ color: foregroundColor, mr: 1 }} />
                <Typography fontSize="11px">Home</Typography>
              </MenuItem>
              {showLogoutIcon && (
                <MenuItem
                  sx={{ text1 }}
                  onClick={() => {
                    if (showLogoutIcon) {
                      navigate("/order-history");
                    }
                    handleClose();
                  }}
                >
                  <HistoryIcon sx={{ color: foregroundColor, mr: 1 }} />
                  <Typography fontSize="11px">Order History</Typography>
                </MenuItem>
              )}
              {showLogoutIcon && (
                <MenuItem
                  sx={{ text1 }}
                  onClick={() => {
                    if (showLogoutIcon) {
                      navigate("/register");
                    }
                    handleClose();
                  }}
                >
                  <RegisterIcon sx={{ color: foregroundColor, mr: 1 }} />
                  <Typography fontSize="11px">Register</Typography>
                </MenuItem>
              )}
              {showLogoutIcon &&
                "School,Tutoring And Academy" === lsm?.servicecat && (
                  <MenuItem
                    sx={{ text1 }}
                    onClick={() => {
                      navigate("/user-panel");
                      handleClose();
                    }}
                  >
                    <Person sx={{ color: foregroundColor, mr: 1 }} />
                    <Typography fontSize="11px">School</Typography>
                  </MenuItem>
                )}
              {showLogoutIcon && (
                <MenuItem
                  sx={{ text1 }}
                  onClick={async () => {
                    try {
                      await auth.signOut();
                      dispatch(setLogout());
                      localStorage.clear();
                      navigate("/login");
                    } catch (e) {
                      console.log(e);
                    }
                    handleClose();
                  }}
                >
                  <LogoutIcon sx={{ color: foregroundColor, mr: 1 }} />
                  <Typography fontSize="11px">Logout</Typography>
                </MenuItem>
              )}
            </Menu>
          </div>
        }
      </Toolbar>
    </AppBar>
  );
};
