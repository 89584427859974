import { createSlice } from '@reduxjs/toolkit';

import { initializeTrafficRoom } from './thunks';
import { IChatSlice } from './types';

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    roomlst: [],
    cmdict: {},
    fcl: null, 
    roomStatus: null,
    isError: false,
    isLoading: false,
  } as IChatSlice,
  reducers: {
    updateChatState(state, action): void {
      state.roomlst = { ...state.roomlst, ...action.payload };
    },
    setFcl(state, action): void {
      state.fcl = {
        ...action.payload
      }
    },
    setRoomStatus(state, action): void {
      state.roomStatus = {
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    // // initializeRoom
    // builder.addCase(initializeRoom.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(initializeRoom.fulfilled, (state, action) => {
    //   state.isLoading = false;
    // });
    // builder.addCase(initializeRoom.rejected, (state) => {
    //   state.isLoading = false;
    //   state.isError = true;
    // });


    // initializeTrafficRoom
    builder.addCase(initializeTrafficRoom.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(initializeTrafficRoom.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(initializeTrafficRoom.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

  },
});

export const { updateChatState, setFcl, setRoomStatus } = chatSlice.actions;
export const { reducer } = chatSlice;
