import { Grid, experimentalStyled } from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormikControl from "src/components/formik/FormikControl";
import Geocode from "src/configs/maps";
import {
  CartOrigin,
  ICartState,
  ILatLng,
  UserSavedAddress,
} from "src/slices/cart/types";
import { setLoading, toastMessage } from "src/slices/commonSlice";
import { AppDispatch } from "src/store";
import { AddressPicker } from "../address-picker";
import CModal from "src/components/CModal";
import { setDialog } from "src/slices/uiSettingsSlice";
import CButton from "src/components/button-group/CButton";
import { useCurrentLocation } from "src/hooks/useCurrentLocation";
import { selectCartState } from "src/slices/cart/selectors";
import { saveUserAddress } from "src/slices/cart/thunks";
import { auth } from "src/configs/firebase";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const SpecificCModal = experimentalStyled(CModal)(() => ({
  "& + .pac-container": {
    zIndex: 10001,
  },
}));

interface IAddEditAddressDialog {
  dialogName: string;
  selectedAddress?: UserSavedAddress;
  setSelectedAddress?: any;
  actionButtons: any[];
  setActionButtons: React.Dispatch<React.SetStateAction<any[]>>;
}

export const AddEditAddressDialog: FC<IAddEditAddressDialog> = ({
  dialogName,
  selectedAddress,
  setActionButtons,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);
  const [reverseGeocodingData, setReverseGeocodingData] =
    useState<UserSavedAddress>(null);

  const selectedCartState: ICartState = useSelector(selectCartState);
  const { textColor } = useDisplaySettingsContext();

  const ActionButtons = () => [
    {
      // type: 'submit',
      text: "Add/Edit",
      color: "primary",
      variant: "contained",
      click: () => {
        const { values } = formikRef.current;
        const userSavedAddress: UserSavedAddress = {
          shortname: values.shortname,
          adm: reverseGeocodingData.adm,
        };
        let addrlist = selectedCartState.custaddresslist;
        if (!addrlist) {
          addrlist = [userSavedAddress];
        }
        let usalist = addrlist;
        if (
          selectedCartState.calledfrom === CartOrigin.AppointmentEndUser ||
          selectedCartState.calledfrom === CartOrigin.EcomEndUser
        ) {
          dispatch(
            saveUserAddress({
              uid: auth.currentUser.uid,
              address: userSavedAddress,
              sid: selectedCartState.entityid!,
            })
          );
        }
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
    {
      text: "Cancel",
      color: "secondary",
      variant: "contained",
      click: () => {
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
  ];

  useEffect(() => {
    setActionButtons(ActionButtons());
  }, [selectedCartState, reverseGeocodingData]);

  // States
  const currentLocation = useCurrentLocation();
  const [geolocation, setGeolocation] = useState<ILatLng>({
    latitude: 0,
    longitude: 0,
  });
  const [selectedLatLngsFromPickup, setSelectedLatLngsFromPickup] =
    useState<ILatLng>({
      latitude: 0,
      longitude: 0,
    });

  // Selectors
  const { dialog } = useSelector((state: any) => state.uiSettings);

  // Triggers
  useEffect(() => {
    if (geolocation.latitude && geolocation.longitude) {
      getAddressDataByLatLng(geolocation);
    }
  }, [geolocation]);

  useEffect(() => {
    if (selectedAddress) {
      formikRef.current.setFieldValue("shortname", selectedAddress.shortname);
      formikRef.current.setFieldValue("state", selectedAddress.adm.state);
      formikRef.current.setFieldValue("district", selectedAddress.adm.district);
      formikRef.current.setFieldValue("city", selectedAddress.adm.town_village);
      formikRef.current.setFieldValue("area", selectedAddress.adm.area_sector);
      formikRef.current.setFieldValue(
        "society",
        selectedAddress.adm.societyname
      );
      formikRef.current.setFieldValue(
        "addressline",
        selectedAddress.adm.addressline
      );
      setReverseGeocodingData(selectedAddress);
    }
  }, [selectedAddress]);

  // Helpers
  function getAddressComponent(components, type) {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : "";
  }

  const getAddressDataByLatLng = (latlng: ILatLng) => {
    if (latlng.latitude && latlng.longitude) {
      dispatch(setLoading(true));
      Geocode.fromLatLng(
        latlng.latitude.toString(),
        latlng.longitude.toString()
      )
        .then((response) => {
          const result = response.results[0];
          if (result) {
            dispatch(setLoading(false));
            const addressComponents = result.address_components;
            const geometry = result.geometry;

            const latitude = geometry.location.lat;
            const longitude = geometry.location.lng;
            const pincode = getAddressComponent(
              addressComponents,
              "postal_code"
            );
            const country = getAddressComponent(addressComponents, "country");
            const state = getAddressComponent(
              addressComponents,
              "administrative_area_level_1"
            );
            const district = getAddressComponent(
              addressComponents,
              "administrative_area_level_2"
            );
            const town_village = getAddressComponent(
              addressComponents,
              "locality"
            );
            const area_sector = getAddressComponent(
              addressComponents,
              "sublocality_level_1"
            );
            const societyname = getAddressComponent(
              addressComponents,
              "sublocality_level_2"
            );
            const addressline = result.formatted_address;
            formikRef.current.setFieldValue("state", state);
            formikRef.current.setFieldValue("district", district);
            formikRef.current.setFieldValue("city", town_village);
            formikRef.current.setFieldValue("area", area_sector);
            formikRef.current.setFieldValue("society", societyname);
            formikRef.current.setFieldValue("addressline", addressline);

            setReverseGeocodingData({
              shortname: formikRef.current.values.shortname,
              adm: {
                country: country,
                state: state,
                district: district,
                area_sector: area_sector,
                societyname: societyname,
                longitude: longitude,
                latitude: latitude,
                addressline: addressline,
                pincode: pincode,
                town_village: town_village,
              },
            });
          } else {
            dispatch(
              toastMessage({
                error: true,
                message: "No address found for these coordinates.",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.error("Error fetching data:", error);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        shortname: "",
        currentLocation: "no",
        address: "",
        state: "",
        district: "",
        city: "",
        area: "",
        society: "",
        addressline: "",
      }}
      innerRef={formikRef}
      onSubmit={() => console.log()}
    >
      {({ values, touched, errors, getFieldProps }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} md={4}>
                  <FormikControl
                    control="Radio"
                    groupLabel="Use Current Location"
                    showInRow
                    required
                    name="currentLocation"
                    handleChange={(e) => {
                      if (e.target.value === "yes") {
                        if (
                          currentLocation.latitude &&
                          currentLocation.longitude
                        ) {
                          getAddressDataByLatLng(currentLocation);
                        }
                      }
                    }}
                    error={Boolean(
                      touched.currentLocation && errors.currentLocation
                    )}
                    helperText={
                      touched.currentLocation && errors.currentLocation
                    }
                    {...getFieldProps("currentLocation")}
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                  />
                </Grid>
                {values.currentLocation === "no" ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <CButton
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        dispatch(
                          setDialog({
                            open: true,
                            dialogName: "AddressPickerDialog",
                          })
                        );
                      }}
                    >
                      Pick Address From Map
                    </CButton>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item xs={true}>
              <FormikControl
                control="InputField"
                label="State"
                name="shortname"
                size="small"
                error={Boolean(touched.shortname && errors.shortname)}
                helperText={touched.shortname && errors.shortname}
                {...getFieldProps("shortname")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikControl
                control="InputField"
                label="State"
                name="state"
                size="small"
                error={Boolean(touched.state && errors.state)}
                helperText={touched.state && errors.state}
                {...getFieldProps("state")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikControl
                control="InputField"
                label="District"
                name="district"
                size="small"
                error={Boolean(touched.district && errors.district)}
                helperText={touched.district && errors.district}
                {...getFieldProps("district")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikControl
                control="InputField"
                label="City"
                name="city"
                size="small"
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
                {...getFieldProps("city")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikControl
                control="InputField"
                label="Area/Sector"
                name="area"
                size="small"
                error={Boolean(touched.area && errors.area)}
                helperText={touched.area && errors.area}
                {...getFieldProps("area")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikControl
                control="InputField"
                label="Society Name"
                name="society"
                size="small"
                error={Boolean(touched.society && errors.society)}
                helperText={touched.society && errors.society}
                {...getFieldProps("society")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormikControl
                control="InputField"
                label="Address line"
                name="addressline"
                size="small"
                error={Boolean(touched.addressline && errors.addressline)}
                helperText={touched.addressline && errors.addressline}
                {...getFieldProps("addressline")}
              />
            </Grid>
          </Grid>
          {dialog.AddressPickerDialog !== undefined &&
          dialog.AddressPickerDialog ? (
            <SpecificCModal
              dialogName="AddressPickerDialog"
              title={"Pick Address"}
              maxWidth="md"
              showSaveButton={true}
              handleSaveButtonText="Select"
              handleCancel={() => {
                setSelectedLatLngsFromPickup({
                  latitude: 0,
                  longitude: 0,
                });
                dispatch(
                  setDialog({
                    open: false,
                    dialogName: "AddressPickerDialog",
                  })
                );
              }}
              handleSave={() => {
                dispatch(
                  setDialog({
                    open: false,
                    dialogName: "AddressPickerDialog",
                  })
                );
                if (
                  selectedLatLngsFromPickup.latitude &&
                  selectedLatLngsFromPickup.longitude
                ) {
                  setGeolocation(selectedLatLngsFromPickup);
                } else {
                  setGeolocation({ ...currentLocation });
                }
              }}
              open={
                dialog.AddressPickerDialog === undefined
                  ? false
                  : dialog.AddressPickerDialog
              }
              content={
                <AddressPicker
                  currentLocation={currentLocation}
                  onLocationSelected={(latlng) => {
                    setSelectedLatLngsFromPickup(latlng);
                  }}
                />
              }
            />
          ) : null}
        </Form>
      )}
    </Formik>
  );
};
