import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormikControl from 'src/components/formik/FormikControl';
import * as CONSTANTS from 'src/constants';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { useGeolocationContext } from 'src/contexts/GeolocationContext';
import { selectAppointmentState } from 'src/slices/appointment/selectors';
import { selectedAppointmentType } from 'src/slices/appointment/thunks';
import { AppointmentState, GenericNameValuePairTrip } from 'src/slices/appointment/types';
import { resetCartState } from 'src/slices/cart';
import { ServiceMemberGrpInfo } from 'src/slices/cart/types';
import { toastMessage } from 'src/slices/commonSlice';
import { selectProductFilterResultLastCall } from 'src/slices/home/selectors';
import { AppDispatch } from 'src/store';

export function AppointmentFilterView() {
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text3, textColor } = useDisplaySettingsContext();
  const navigate = useNavigate();
  const position = useGeolocationContext();

  const appointmentState: AppointmentState = useSelector(
    selectAppointmentState
  );
  const vehicleLastCallPayload = useSelector(selectProductFilterResultLastCall);
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const formikRef = useRef(null);

  const [pageState, setPageState] = useState({
    appointmentusertype: "For Self",
    appointmenttype: "",
    allowedtypes: {},
    memberExistenceType: "Create New",
    selectedgrp: null,
    calledmemregform: false,
    requireageandsexflag: false,
    _dob: new Date(Date.now()),
  });

  useEffect(() => {
    const requireageandsexflag = reqagesexandfor();
    setPageState((pState) => ({
      ...pState,
      requireageandsexflag: requireageandsexflag,
    }));
    dispatch(resetCartState());
  }, []);

  const reqagesexandfor = () => {
    // final appointmentBloc = BlocProvider.of<AppointmentBloc>(context);
    const medicaltype: string[] = ["MEDICINE", "MEDICAL-TEST", "DOCTOR"];
    let requiredagesexfor = false;
    const sm = appointmentState.serviceModel;
    for (let s of medicaltype) {
      if (sm.servicetype !== null && sm.servicetype!.includes(s)) {
        requiredagesexfor = true;
        break;
      }
    }
    return requiredagesexfor;
  };

  const getAllowedAptType = () => {
    let serviceModel = appointmentState.serviceModel;
    let allowedtypes = [];
    if (serviceModel.hasqapt!) {
      allowedtypes.push("General Queue");
    }
    if (serviceModel.hasrapt!) {
      allowedtypes.push("Fixed Time");
    }
    if (serviceModel.hasvapt ?? false) {
      allowedtypes.push("Home Visit");
    }
    setPageState((pState) => ({
      ...pState,
      allowedtypes: allowedtypes,
    }));

    if (allowedtypes.length > 0) {
      if (formikRef.current) {
        formikRef.current.setFieldValue("appointmentType", allowedtypes[0]);
      }
    } else {
      dispatch(
        toastMessage({
          error: true,
          message:
            "Service Provider is in process of configuring Appointment. Please try later!",
        })
      );
    }
    return allowedtypes;
  };

  const getAllowedAptTypeMemo = useMemo(
    () => getAllowedAptType(),
    [appointmentState, formikRef.current]
  );

  const ReturnGrpMemberFields = ({
    touched,
    errors,
    getFieldProps,
    showname,
  }) => {
    return (
      <Stack direction="column" spacing={1}>
        {showname && (
          <FormikControl
            control="InputField"
            label="Name"
            name="name"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            {...getFieldProps("name")}
          />
        )}
        {pageState.requireageandsexflag && (
          <>
            <FormikControl
              control="SelectField"
              label="Gender"
              name="gender"
              options={[
                {
                  label: "M",
                  value: "M",
                },
                {
                  label: "F",
                  value: "F",
                },
              ]}
              error={Boolean(touched.gender && errors.gender)}
              helperText={touched.gender && errors.gender}
              {...getFieldProps("gender")}
            />
            <FormikControl
              control="CalendarTime"
              label="Date of Birth"
              dateOrTimeOnly="date"
              name="dob"
              error={Boolean(touched.dob && errors.dob)}
              helperText={touched.dob && errors.dob}
              {...getFieldProps("dob")}
            />
          </>
        )}
      </Stack>
    );
  };

  function hasGrpMemberList(
    gnv: GenericNameValuePairTrip | undefined | null
  ): boolean {
    if (!gnv?.info1) {
      return false;
    } else {
      for (const k of gnv.info1) {
        if (k.name === "grp") {
          if (k.value !== null) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    }
  }

  function listFromJsonDataforServiceGrp(mjson: any): ServiceMemberGrpInfo[] {
    const _list: ServiceMemberGrpInfo[] = [];
    const json: any[] | null = mjson as any[];
    if (json !== null) {
      json.forEach((j) => {
        _list.push(j as ServiceMemberGrpInfo);
      });
    }
    return _list;
  }

  function getServiceMemberGrpList(
    gnv: GenericNameValuePairTrip | undefined | null
  ): ServiceMemberGrpInfo[] {
    const mylist: ServiceMemberGrpInfo[] = [];
    if (!gnv?.info1) {
      return mylist;
    } else {
      for (const k of gnv.info1) {
        if (k.name === "grp") {
          if (k.value !== null) {
            const parsed2 = JSON.parse(k.value);
            if (parsed2 !== null) {
              return listFromJsonDataforServiceGrp(parsed2);
            }
            return mylist;
          } else {
            return mylist;
          }
        }
      }
      return mylist;
    }
  }

  return (
    <>
      {appointmentState.serviceModel ? (
        <Box
          sx={{
            width: isMobile ? "100%" : "50%",
            background: "white",
            margin: "0 auto",
            p: 1,
          }}
        >
          <Formik
            initialValues={{
              appointmentType: "",
              appointmentUserType: "For Self",
              memberExistenceType: "Create New",
              categoryType: "",
              name: "",
              gender: "M",
              dob: new Date(),
            }}
            innerRef={formikRef}
            onSubmit={(_) => {}}
          >
            {({ values, touched, errors, getFieldProps }) => (
              <Stack direction="column" spacing={2}>
                <Typography
                  sx={{
                    fontSize: heading3,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {appointmentState.serviceModel?.servicename ?? "Appointment"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {"Appointment Type"}
                </Typography>
                <FormikControl
                  control="Radio"
                  showInRow
                  required
                  name="appointmentType"
                  error={Boolean(
                    touched.appointmentType && errors.appointmentType
                  )}
                  helperText={touched.appointmentType && errors.appointmentType}
                  {...getFieldProps("appointmentType")}
                  options={getAllowedAptTypeMemo.map((allowtype) => ({
                    value: allowtype,
                    label: allowtype,
                  }))}
                />
                <FormikControl
                  control="Radio"
                  showInRow
                  required
                  name="appointmentUserType"
                  error={Boolean(
                    touched.appointmentUserType && errors.appointmentUserType
                  )}
                  helperText={
                    touched.appointmentUserType && errors.appointmentUserType
                  }
                  {...getFieldProps("appointmentUserType")}
                  options={[
                    {
                      value: "For Self",
                      label: "For Self",
                    },
                    {
                      value: "For Dependent",
                      label: "For Dependent",
                    },
                  ]}
                />
                {values.appointmentUserType === "For Self" &&
                  ((!appointmentState.customerdatasm &&
                    pageState.requireageandsexflag) ||
                    (appointmentState.customerdatasm &&
                      pageState.requireageandsexflag &&
                      !appointmentState.customerdatasm.dob)) && (
                    <ReturnGrpMemberFields
                      showname={true}
                      errors={errors}
                      touched={touched}
                      getFieldProps={getFieldProps}
                    />
                  )}
                {values.appointmentUserType === "For Dependent" && (
                  <>
                    <FormikControl
                      control="Radio"
                      showInRow
                      required
                      name="memberExistenceType"
                      error={Boolean(
                        touched.memberExistenceType &&
                          errors.memberExistenceType
                      )}
                      helperText={
                        touched.memberExistenceType &&
                        errors.memberExistenceType
                      }
                      {...getFieldProps("memberExistenceType")}
                      options={[
                        {
                          value: "Create New",
                          label: "Create New",
                        },
                        {
                          value: "Existing Member",
                          label: "Existing Member",
                        },
                      ]}
                    />
                    {values.memberExistenceType === "Existing Member" && (
                      <>
                        {appointmentState.customerdatasm?.grpmemberlist &&
                        appointmentState.customerdatasm!.grpmemberlist.length >
                          0 ? (
                          <>
                            <FormikControl
                              control="SelectField"
                              label="Category Type"
                              name="categoryType"
                              options={getServiceMemberGrpList(
                                appointmentState.customerdata
                              ).map((data) => ({
                                label: data,
                                value: data,
                              }))}
                              error={Boolean(
                                touched.categoryType && errors.categoryType
                              )}
                              helperText={
                                touched.categoryType && errors.categoryType
                              }
                              {...getFieldProps("categoryType")}
                            />
                          </>
                        ) : (
                          <Typography fontSize={text3}>No members found</Typography>
                        )}
                      </>
                    )}
                    {values.memberExistenceType === "Create New" && (
                      <ReturnGrpMemberFields
                        errors={errors}
                        touched={touched}
                        getFieldProps={getFieldProps}
                        showname={true}
                      />
                    )}
                  </>
                )}
                <Button
                sx={{
                  color: textColor,
                }}
                  variant="contained"
                  onClick={() => {
                    let aptother = false;
                    let newcustmember = null;
                    if (values.appointmentUserType === "For Self") {
                      if (
                        (!appointmentState.customerdatasm &&
                          pageState.requireageandsexflag) ||
                        (appointmentState.customerdatasm &&
                          pageState.requireageandsexflag &&
                          !appointmentState.customerdatasm.dob)
                      ) {
                        newcustmember = {
                          dob: CONSTANTS.toJsonTimeStamp(CONSTANTS.getJustDatePart(values.dob)),
                          sex: values.gender,
                        };
                      }
                    } else {
                      aptother = true;
                      if (
                        values.categoryType === "" &&
                        pageState.requireageandsexflag
                      ) {
                        dispatch(
                          toastMessage({
                            error: true,
                            message:
                              "please fill the required fields to proceed further!",
                          })
                        );
                        return;
                      }

                      let data = getServiceMemberGrpList(
                        appointmentState.customerdata
                      );
                      const selectedSv = data.filter(
                        (d) => d.name === values.categoryType
                      )[0];
                      newcustmember = selectedSv ?? {
                        name: values.name,
                        dob: CONSTANTS.toJsonTimeStamp(CONSTANTS.getJustDatePart(values.dob)),
                        sex: values.gender,
                      };
                    }

                    let sc = 'regularApt';
                    if (values.appointmentType === "General Queue")
                      sc = 'checkIn';
                    else if (values.appointmentType === "Fixed Time")
                      sc = 'regularApt';
                    else if (values.appointmentType === "Home Visit")
                      sc = 'homeVisit';

                    dispatch(
                      selectedAppointmentType({
                        selectedType: sc,
                        newcustmember: newcustmember,
                        aptother: aptother,
                      })
                    ).then(() => {
                      navigate(`/appointment-list`);
                    });
                  }}
                >
                  Next
                </Button>
              </Stack>
            )}
          </Formik>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={heading2}>No Data Found</Typography>
        </Box>
      )}
    </>
  );
}
