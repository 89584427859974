import { Box, Grid, Typography } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { ContainedButton } from 'src/components/button-group';
import FormikControl from 'src/components/formik/FormikControl';
import { auth } from 'src/configs/firebase';
import { RoutePaths, Slices } from 'src/constants';
import logo from 'src/icons/logo.png';
import { setLoginUser } from 'src/slices/auth';
import { selectCartType } from 'src/slices/cart/selectors';
import { toastMessage } from 'src/slices/commonSlice';
import { selectSelectedAtypeCategory } from 'src/slices/landing-page/selectors';
import { AppDispatch } from 'src/store';
import * as Yup from 'yup';

export const Login = () => {
  const formikRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const cartType = useSelector(selectCartType);
  const atypeCategory = useSelector(selectSelectedAtypeCategory);
  const { isLoggedIn } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );

  if (isLoggedIn && !auth?.currentUser?.isAnonymous) {
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }

  const validationSchemaModel = Yup.object({
    username: Yup.string()
      .min(4, "Username should be minimum 4 characters length")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const loginAPI = async (email: string, password: string) => {
    try {
      try {
        auth.signOut();
      } catch (e) {
        console.log(e);
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      localStorage.setItem("user", JSON.stringify(userCredential.user));
      dispatch(setLoginUser({ isLoggedIn: true, user: userCredential.user }));

      if (cartType === "spp" || atypeCategory === "spp") {
        navigate("/cart");
      } else if (cartType === "sps") {
        navigate("/appointment-cart");
      } else {
        navigate("/");
      }
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        dispatch(
          toastMessage({
            error: true,
            message: "Email is not correct. kindly enter a corrent email!",
          })
        );
      } else if (error.code === "auth/user-not-found") {
        dispatch(
          toastMessage({
            error: true,
            message: "Entered email is incorrect!",
          })
        );
      } else if (error.code === "auth/wrong-password") {
        dispatch(
          toastMessage({
            error: true,
            message: "Entered password is incorrect!",
          })
        );
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: error.message,
          })
        );
      }
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ p: 0, m: 0, height: "95vh", backgroundColor: "#FAFAFA" }}
    >
      <Grid item xs={8} md={4}>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={async (values) => {
            await loginAPI(values.username, values.password);
          }}
        >
          {({ touched, errors, getFieldProps }) => (
            <Form id="Login">
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={logo}
                    alt="logo"
                    height={100}
                    justifySelf="center"
                    sx={{
                      backgroundColor: "primary.main",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={30}>Login</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    name="username"
                    label="Username"
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    {...getFieldProps("username")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="PasswordInputField"
                    name="password"
                    label="Password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    {...getFieldProps("password")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography display="inline">
                    Don't have an account?
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={() => {
                      navigate(RoutePaths.SIGN_UP);
                    }}
                    sx={{
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Sign up!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ContainedButton fullWidth type="submit">
                    Login
                  </ContainedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
