import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { DriverCard } from 'src/slices/trip/types';
import { AppDispatch } from 'src/store';

interface IUserListItem {
  driver: DriverCard;
  mainPoints: any;
}

export const UserListItem: FC<IUserListItem> = ({ driver, mainPoints }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { heading3, text2 } = useDisplaySettingsContext();

  const KeyValue = ({ title, value }) => {
    return (
      <>
        <Grid item xs={3}>
          <Typography sx={{ fontSize: text2, fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography sx={{ fontSize: text2 }}>{value}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          key={driver.spid}
          sx={{
            padding: "10px",
            marginTop: "5px",
            marginBottom: "5px",
            wordBreak: "break-word",
            background: "white",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              minHeight="150px"
              minWidth="150px"
              sx={{
                borderRadius: "5px",
                marginRight: "10px",
                backgroundImage: `url(${driver.driphoto})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: heading3,
                  fontWeight: "bold",
                  color: "primary.main",
                }}
              >
                {driver.spname}
              </Typography>
              <Grid container>
                <KeyValue title="Name" value={driver.spname} />
                <KeyValue title="Vehicle" value={driver.vehnum} />
                <KeyValue title="Type" value={driver.vehtype} />
                <KeyValue title="Trip" value={driver.tripname} />
                <KeyValue
                  title="Address"
                  value={driver.relationid ?? "NoAddress"}
                />
                <KeyValue title="Phone" value={driver.driphone ?? "NoPhone"} />
              </Grid>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontSize={text2} fontWeight={"bold"}>
                  Point
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={text2} fontWeight={"bold"}>
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={text2} fontWeight={"bold"}>
                  Time Offset
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mainPoints.map((keyValue) => (
              <TableRow
                key={keyValue.pointmodel?.pointName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ fontSize: text2 }}>
                  {keyValue.distance}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ fontSize: text2 }}>
                  {keyValue.timeOffset}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ fontSize: text2 }}>
                  {keyValue.timeOffset}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
