import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "src/constants";
import * as CONSTANTS from "src/constants";
import { sendRequestJSON } from "src/lib/axios";

import { setLastProviderCall, setProductFilterResultCall } from ".";
import { toastMessage } from "../commonSlice";
import {
  IFetchProductFilterResultPayload,
  IGeneralTextLookupPayload,
  IGetServiceProvidersPayload,
  IGetSingleServiceProviderPayload,
} from "./types";

function fromUserEndUserClusterInfo(euci, type, forservice) {
  if (
    forservice &&
    ![
      CONSTANTS.prodat_homestay,
      CONSTANTS.prodat_hotel,
      CONSTANTS.prodat_hospital,
      CONSTANTS.prodat_banquet,
      CONSTANTS.prodat_farm,
      CONSTANTS.prodat_farmproduct,
    ].includes(type)
  ) {
    return euci.serviceprovider || [];
  } else {
    if (type === "REALESTATE") {
      return euci.realestate || [];
    }
    if (
      [
        CONSTANTS.prodat_homestay,
        CONSTANTS.prodat_hotel,
        CONSTANTS.prodat_hospital,
        CONSTANTS.prodat_banquet,
        CONSTANTS.prodat_farm,
      ].includes(type)
    ) {
      return euci.rentalh || [];
    } else if (type === CONSTANTS.prodat_farmproduct) {
      return euci.farmprod || [];
    } else if (type === "PET") {
      return euci.pet || [];
    } else if (type === "JOB") {
      return euci.job || [];
    } else if (type === "VEHICLE") {
      return euci.vehicle || [];
    } else if (type === CONSTANTS.prodat_classifiedprod) {
      return euci.cproduct || [];
    } else if (type === "TRIP") {
      return euci.trip || [];
    } else {
      return [];
    }
  }
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getServiceProviders = createAsyncThunk(
  "home/getServiceProviders",
  async (provider: IGetServiceProvidersPayload, { dispatch, getState }) => {
    const state = (getState() as any).loginInformation;
    const productState = (getState() as any).product.productState;
    dispatch(setLastProviderCall(provider));
    try {
      const serverList =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ]?.serviceprovider ?? [];

      const cid =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ]?.docid ?? "";

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      const response = await sendRequestJSON(
        provider,
        "/api/Search/GetServiceProviderList",
        "post",
        BaseUrl.custom,
        server,
        cid
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const getMoreServiceProviders = createAsyncThunk(
  "home/getMoreServiceProviders",
  async (provider: IGetServiceProvidersPayload, { dispatch, getState }) => {
    const state = (getState() as any).loginInformation;
    const productState = (getState() as any).product.productState;
    dispatch(setLastProviderCall(provider));
    try {
      const serverList =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ]?.serviceprovider ?? [];

      const cid =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ]?.docid ?? "";

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      const response = await sendRequestJSON(
        provider,
        "/api/Search/GetServiceProviderList",
        "post",
        BaseUrl.custom,
        server,
        cid
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchSingleServiceProvider = createAsyncThunk(
  "home/fetchSingleServiceProvider",
  async (payload: IGetSingleServiceProviderPayload, { dispatch, getState }) => {
    const state = (getState() as any).loginInformation;
    const productState = (getState() as any).product.productState;
    try {
      const serverList =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ]?.serviceprovider ?? [];

      const cid =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ]?.docid ?? "";

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      const response = await sendRequestJSON(
        payload,
        "/api/Search/FetchSingleServiceProvider",
        "post",
        BaseUrl.custom,
        server,
        cid
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchProductFilterResult = createAsyncThunk(
  "home/fetchProductFilterResult",
  async (payload: IFetchProductFilterResultPayload, { dispatch, getState }) => {
    const state = (getState() as any).loginInformation;
    const productState = (getState() as any).product.productState;
    dispatch(setProductFilterResultCall(payload));
    try {
      const serverList =
        fromUserEndUserClusterInfo(
          state.endUserClusterInfo[
            `INDIA#${
              productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
              "GEORGIA"
            }`
          ],
          payload.dynamicProperty.psc.grouptype,
          !payload.dynamicProperty.psc.isshop
        ) ?? [];

      const docid =
        state.endUserClusterInfo[
          `INDIA#${
            productState?.productSearchInformationConfig?.sp?.curStatename?.toUpperCase() ??
            "GEORGIA"
          }`
        ].docid;

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      const response = await sendRequestJSON(
        payload,
        "/api/Search/fetchProductFilterResult",
        "post",
        BaseUrl.custom,
        server,
        docid
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchProductFilterItemDetails = createAsyncThunk(
  "home/fetchProductFilterItemDetails",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        payload,
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const generalTextLookUp = createAsyncThunk(
  "home/generalTextLookUp",
  async (payload: IGeneralTextLookupPayload, { dispatch }) => {
    dispatch(setProductFilterResultCall(payload));
    try {
      const response = await sendRequestJSON(
        payload,
        "/Search/GeneralTextLookUp",
        "post",
        BaseUrl.ubuntu
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);
