import { Typography, Box, Grid, Paper, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsHomeLoading,
} from "src/slices/home/selectors";
import { AppDispatch } from "src/store";
import { Form, Formik } from "formik";
import FormikControl from "src/components/formik/FormikControl";
import { selectCartState } from "src/slices/cart/selectors";
import { ICartState } from "src/slices/cart/types";
import { toastMessage } from "src/slices/commonSlice";
import { useNavigate } from "react-router-dom";
import { selectAppointmentState } from "src/slices/appointment/selectors";
import { AppointmentState } from "src/slices/appointment/types";
import CButton from "src/components/button-group/CButton";
import { completeAppointmentCreation } from "src/slices/appointment/thunks";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const AppointmentDetailsView = ({}) => {
  const navigate = useNavigate();

  const formikRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text1, text2,textColor } = useDisplaySettingsContext();
  
  const cartState: ICartState = useSelector(selectCartState);
  const appointmentState: AppointmentState = useSelector(
    selectAppointmentState
  );

  const dispatch = useDispatch<AppDispatch>();

  const selectIsLoading = useSelector(selectIsHomeLoading);

  useEffect(() => {
    formikRef.current.setFieldValue(
      "serviceProvider",
      appointmentState?.onCreationAppointment?.serviceprovidername
    );
    formikRef.current.setFieldValue(
      "date",
      appointmentState?.onCreationAppointment?.startdate ?? "Not Defined"
    );
    formikRef.current.setFieldValue(
      "time",
      appointmentState?.onCreationAppointment?.period ?? "Not Defined"
    );
  }, []);

  useEffect(()=>{
    if(appointmentState?.onCreationAppointment?.id ?? null){
    console.log("ORDER COMPLETE");
    dispatch(toastMessage({
      message: "Appointment is Created"
    }));
    navigate('/');
    }
  },[appointmentState.onCreationAppointment]);

  return (
    <>
      {true ? (
        <Box
          sx={{
            width: isMobile ? "100%" : "50%",
            alignSelf: "center",
          }}
        >
          <Formik
            initialValues={{
              serviceProvider: "",
              date: "",
              time: "",
            }}
            innerRef={formikRef}
            onSubmit={(values) => {
              console.log(values);
              dispatch(
                completeAppointmentCreation({
                  order: cartState.cart,
                })
              );
            }}
          >
            {({ touched, errors, getFieldProps }) => (
              <Form>
                <Paper sx={{ p: 2 }}>
                  <Stack alignItems="center">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography fontSize={heading3}>
                          {appointmentState.serviceModel.servicename ??
                            "Not Loaded"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontSize={text2}>
                          Appointment Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontSize={text1}>
                          Please verify your appointment details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          disabled
                          control="InputField"
                          label={"Service Provider"}
                          name="serviceProvider"
                          size="small"
                          error={Boolean(
                            touched.serviceProvider && errors.serviceProvider
                          )}
                          helperText={
                            touched.serviceProvider && errors.serviceProvider
                          }
                          {...getFieldProps("serviceProvider")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          disabled
                          control="InputField"
                          label={"Date"}
                          name="date"
                          size="small"
                          error={Boolean(touched.date && errors.date)}
                          helperText={touched.date && errors.date}
                          {...getFieldProps("date")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikControl
                          disabled
                          control="InputField"
                          label={"Time"}
                          name="time"
                          size="small"
                          error={Boolean(touched.time && errors.time)}
                          helperText={touched.time && errors.time}
                          {...getFieldProps("time")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CButton variant="contained" type="submit" sx={{color: textColor }}>
                          View
                        </CButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Paper>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
};
