import { Box, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CKeyValueList from 'src/components/CKeyValueList';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { clearProductFilterItemDetails } from 'src/slices/home';
import { selectIsHomeLoading, selectProductFilterItemDetails } from 'src/slices/home/selectors';
import { fetchProductFilterItemDetails } from 'src/slices/home/thunks';
import { AppDispatch } from 'src/store';

export function ProductDetailsPage() {
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, text1, text2 } = useDisplaySettingsContext();
  
  const selectIsLoading = useSelector(selectIsHomeLoading);
  const productDetails = useSelector(selectProductFilterItemDetails);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productid = queryParams.get("productid");
  const serviceid = queryParams.get("serviceid");
  const userid = queryParams.get("userid");

  const [selectVehicleData, setSelectVehicleData] = useState<any>();

  const [selectedImage, setSelectedImage] = useState<string>();

  const [origprice, setOrigprice] = useState<string>("");
  const [disprice, setDisprice] = useState<string>("");

  useEffect(() => {
    dispatch(
      fetchProductFilterItemDetails({
        data: {
          productactionrequest: {
            qtype: null,
            origin: "SERVICEPROVIDERINFO",
            entitytype: "SERVICEPROVIDERINFO",
            serviceid: serviceid,
            userid: userid,
            producttype: "PRODUCT",
            classifiedtype: "PRODUCT",
            additionaltype: "spp",
            productid: productid,
          },
          qrytype: "GETSINGLEDSPRODUCT",
        },
      })
    );
    return () => {
      dispatch(clearProductFilterItemDetails());
    };
  }, []);

  useEffect(() => {
    if (
      productDetails &&
      productDetails.data.pdlist &&
      productDetails.data.pdlist.length !== 0
    ) {
      setSelectVehicleData(productDetails.data.pdlist[0]);
      if (productDetails.data.pdlist[0].adata.imagelist.length !== 0) {
        setSelectedImage(productDetails.data.pdlist[0].adata.imagelist[0]);
      }
      if (
        productDetails!.data.pdlist[0].adata.varinattype &&
        productDetails!.data.pdlist[0].adata.varinattype !== "CLASSIFIED"
      ) {
        if (productDetails!.data.pdlist[0].adata.varinattype === "NOPACKAGE") {
          if (
            productDetails!.data.pdlist[0].adata.nopackagedata?.priceperunit
          ) {
            setOrigprice(
              productDetails!.data.pdlist[0].adata.nopackagedata!.priceperunit.toString()
            );
          } else {
            setOrigprice("0");
          }
          if (
            productDetails!.data.pdlist[0].adata.nopackagedata
              ?.discountedpriceperunit
          ) {
            setDisprice(
              productDetails!.data.pdlist[0].adata.nopackagedata?.discountedpriceperunit.toString()
            );
          } else {
            setDisprice(origprice);
          }
        }
      } else {
        if (productDetails!.data.pdlist[0].adata.price) {
          setOrigprice(productDetails!.data.pdlist[0].adata.price.toString());
        } else {
          setOrigprice("0");
        }
        if (productDetails!.data.pdlist[0].adata.discountedprice) {
          setDisprice(
            productDetails!.data.pdlist[0].adata.discountedprice.toString()
          );
        } else {
          setDisprice(origprice);
        }
      }
    }
  }, [productDetails]);

  const getPrice = () => {
    if (
      selectVehicleData.adata.varinattype === "SINGLE" ||
      selectVehicleData.adata.varinattype === "SERVICE"
    ) {
      let price = selectVehicleData.adata.price ?? 0;
      let discountedPrice = selectVehicleData.adata.discountedprice ?? 0;
      return (
        <Stack direction="row" gap={2}>
          <Typography fontSize={text2}>{discountedPrice}</Typography>
          <Typography fontSize={text1} sx={{ textDecoration: "line-through" }}>
            {price}
          </Typography>
        </Stack>
      );
    } else if (selectVehicleData.adata.varinattype === "NOPACKAGE") {
      let price = (selectVehicleData.adata.nopackinfo?.unitprlist ?? [])[0]
        .price;
      let discountedPrice = (selectVehicleData.adata.nopackinfo?.unitprlist ??
        [])[0].discountedprice;

      if (!discountedPrice) {
        return discountedPrice;
      }

      return (
        <Stack direction="row" gap={2}>
          <Typography fontSize={text2}>{discountedPrice}</Typography>
          <Typography fontSize={text1} sx={{ textDecoration: "line-through" }}>
            {price}
          </Typography>
        </Stack>
      );
    } else if (selectVehicleData.adata.varinattype === "NOPACKAGEFARM") {
      let price = (selectVehicleData.adata.nopackinfofarmer?.unitprlist ??
        [])[0].price;
      let discountedPrice = (selectVehicleData.adata.nopackinfofarmer
        ?.unitprlist ?? [])[0].discountedprice;

      if (!discountedPrice) {
        return discountedPrice;
      }

      return (
        <Stack direction="row" gap={2}>
          <Typography fontSize={text2}>{discountedPrice}</Typography>
          <Typography fontSize={text1} sx={{ textDecoration: "line-through" }}>
            {price}
          </Typography>
        </Stack>
      );
    } else {
      if (
        selectVehicleData.adata.discountedprice &&
        selectVehicleData.adata.varinattype !== "SIZEANDCOLOR"
      ) {
        return selectVehicleData.adata.discountedprice &&
          parseInt(selectVehicleData.adata.discountedprice) !== 0
          ? disprice.toString()
          : origprice.toString();
      } else {
        if (selectVehicleData.adata.varinattype === "SIZEANDCOLOR") {
          return (
            <Stack direction="row" gap={2}>
              <Typography fontSize={text2}>
                {selectVehicleData.adata.sizeandcolordata![0].discountedprice}
              </Typography>
              <Typography fontSize={text1} sx={{ textDecoration: "line-through" }}>
                {selectVehicleData.adata.sizeandcolordata![0].price}
              </Typography>
            </Stack>
          );
        }
        if (selectVehicleData.adata.varinattype === "PACKAGE") {
          return (
            <Stack direction="row" gap={2}>
              <Typography fontSize={text2}>
                {selectVehicleData.adata.packagedata![0].discountedprice}
              </Typography>
              <Typography fontSize={text1} sx={{ textDecoration: "line-through" }}>
                {selectVehicleData.adata.packagedata![0].price}
              </Typography>
            </Stack>
          );
        }
      }
    }
    return "";
  };

  return (
    <>
      {selectVehicleData ? (
        <>
          <Paper sx={{ padding: 2, width: '80%', margin: '0 auto' }}>
            <Typography fontSize={text1} sx={{mb: 2}}>
              {selectVehicleData.adata.title}
            </Typography>
            <Grid container spacing={2} width={"50%"} sx={{mb: 2}}>
              <Grid
                item
                xs={12}
                lg={1}
                sx={{
                  [theme.breakpoints.up("md")]: {
                    mr: 10,
                  },
                  [theme.breakpoints.down("md")]: {
                    display: "flex",
                    flexDirection: "row",
                  },
                }}
              >
                {selectVehicleData.adata.imagelist.map((img) => (
                  <Box
                    onClick={() => {
                      setSelectedImage(img);
                    }}
                    minHeight={isMobile ? "50px" : "50px"}
                    minWidth={isMobile ? "50px" : "50px"}
                    maxHeight="400px"
                    sx={{
                      overFlow: "auto",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      backgroundImage: `url(${img})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      border: selectedImage === img ? "1px solid black" : "",
                      "&:hover": {
                        border: "1px solid black",
                      },
                    }}
                  ></Box>
                ))}
              </Grid>
              <Grid item xs={12} lg={9}>
                <Box
                  minHeight={isMobile ? "200px" : "400px"}
                  minWidth={isMobile ? "100%" : "400px"}
                  sx={{
                    borderRadius: "5px",
                    marginRight: "10px",
                    backgroundImage: `url(${selectedImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: isMobile ? "contain" : "contain",
                  }}
                ></Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Brand",
                      value: selectVehicleData.adata.brand,
                    },
                    {
                      key: "Description",
                      value: selectVehicleData.adata.description,
                    },
                    {
                      key: "Price",
                      value: getPrice(),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={
                    selectVehicleData.adata?.dynamicproperties?.map((obj) => ({
                      key: obj.propertyname.split("_").join(" ").toUpperCase(),
                      value: obj.values.join(" "),
                    })) ?? []
                  }
                />
              </Grid>
              {/* <Grid item xs={12} lg={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Address",
                      value:
                        selectVehicleData.adata.contactdetails.address
                          .addressline,
                    },
                    {
                      key: "Email",
                      value: selectVehicleData.adata.contactdetails.email,
                    },
                    {
                      key: "Phone",
                      value: selectVehicleData.adata.contactdetails.phonenum,
                    },
                  ]}
                />
              </Grid> */}
            </Grid>
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
}
