import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Grid, Radio, styled, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { useGeolocationContext } from 'src/contexts/GeolocationContext';
import { IGeneralTextLookupResultItem } from 'src/slices/home/types';
import { setDialog } from 'src/slices/uiSettingsSlice';
import { AppDispatch } from 'src/store';

import CModal from '../CModal';
import FormikControl from '../formik/FormikControl';
import { DistrictAutocomplete } from './DistrictAutocomplete';

const TopbarLocationBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  gap: "2px",
}));

export const TopbarLocation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { district, updateDistrict } = useGeolocationContext();

  const [selectedDistrict, setSelectedDistrict] =
    useState<IGeneralTextLookupResultItem | null>(null);
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const formikRef = useRef(null);
  const { text1, text2, text3, foregroundColor } = useDisplaySettingsContext();
  const [selectedValue, setSelectedValue] = useState("radius");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikRef.current.setFieldValue("radius", "");
    formikRef.current.setFieldValue("district", "");
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <TopbarLocationBox
        sx={{ color: foregroundColor }}
        onClick={() => {
          dispatch(setDialog({ open: true, dialogName: "LocationFilters" }));
        }}
      >
        <LocationOnIcon sx={{ fontSize: text1 }} />
        <Typography fontSize={text3}>{district}</Typography>
      </TopbarLocationBox>
      {dialog.LocationFilters !== undefined && dialog.LocationFilters ? (
        <CModal
          dialogName="LocationFilters"
          title={"Location Filters"}
          maxWidth="xs"
          showSaveButton={true}
          handleSaveButtonText="Apply"
          handleCancel={() => {}}
          handleSave={() => {
            updateDistrict(selectedDistrict.text);
            dispatch(setDialog({ open: false, dialogName: "LocationFilters" }));
          }}
          open={
            dialog.LocationFilters === undefined
              ? false
              : dialog.LocationFilters
          }
          content={
            <Formik
              initialValues={{
                radius: "",
                district: "",
              }}
              innerRef={formikRef}
              onSubmit={() => console.log()}
            >
              {({ touched, errors, getFieldProps }) => (
                <Form>
                  <Grid container spacing={2}>
                    {/* Price */}
                    <Grid item xs={12}>
                      <Typography fontSize={text2}>
                        Enter your Address
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Radio
                        checked={selectedValue === "radius"}
                        onChange={handleChange}
                        value="radius"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "radius" }}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <FormikControl
                        control="InputField"
                        label={"Radius"}
                        name="radius"
                        size="small"
                        disabled={selectedValue !== "radius"}
                        error={Boolean(touched.radius && errors.radius)}
                        helperText={touched.radius && errors.radius}
                        {...getFieldProps("radius")}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Radio
                        checked={selectedValue === "district"}
                        onChange={handleChange}
                        value="district"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "district" }}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <DistrictAutocomplete
                        disabled={selectedValue !== "district"}
                        onSelect={(value) => {
                          setSelectedDistrict(value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          }
        />
      ) : null}
    </>
  );
};
