import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectAppointment = createSelector(selectSelf, (state) => state.appointment);

export const selectIsAppointmentLoading = createSelector(
  selectAppointment,
  (appointment) => appointment.isLoading
);

export const selectAppointmentState = createSelector(
  selectAppointment,
  (appointment) => appointment.appointmentState
);

export const selectBusySlotOccupancyResponse = createSelector(
  selectAppointment,
  (appointment) => appointment.getBusySlotOccupancyResponse
);