import { combineReducers } from "@reduxjs/toolkit";

import { reducer as loginInformation } from "../slices/auth";
import { reducer as commonReducer } from "../slices/commonSlice";
import { reducer as uiSettings } from "../slices/uiSettingsSlice";
import { reducer as homeReducer } from "../slices/home";
import { reducer as cartReducer } from "../slices/cart";
import { reducer as productReducer } from "../slices/product";
import { reducer as appointmentReducer } from "../slices/appointment";
import { reducer as orderHistoryReducer } from "../slices/order-history";
import { reducer as tripReducer } from "../slices/trip";
import { reducer as chatReducer } from "../slices/chat";
import { reducer as userPanelReducer } from "../slices/user-panel";
import { webpageDataReducer, categoryProductsReducer } from "../slices/landing-page";

const appReducer = combineReducers({
  commonReducer,
  uiSettings,
  loginInformation,
  home: homeReducer,
  cart: cartReducer,
  chat: chatReducer,
  product: productReducer,
  appointment: appointmentReducer,
  orderHistory: orderHistoryReducer,
  trip: tripReducer,
  webpageData: webpageDataReducer,
  categoryProducts: categoryProductsReducer,
  userPanel: userPanelReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "loginInformations/logOutUser") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
