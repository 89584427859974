import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WifiIcon from '@mui/icons-material/Wifi';
import { Badge, Box, Fab, Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CButton from 'src/components/button-group/CButton';
import CKeyValueList from 'src/components/CKeyValueList';
import { auth } from 'src/configs/firebase';
import * as CONSTANTS from 'src/constants';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { selectCartState } from 'src/slices/cart/selectors';
import { addedProductCartEvent } from 'src/slices/cart/thunks';
import { ICartState } from 'src/slices/cart/types';
import { selectIsHomeLoading } from 'src/slices/home/selectors';
import { selectProductState } from 'src/slices/product/selectors';
import { ProductState } from 'src/slices/product/types';
import { AppDispatch } from 'src/store';

export function RentalsProductListSPView() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { heading2, heading3, text1, text2, text3, textColor } = useDisplaySettingsContext();
  
  const selectIsLoading = useSelector(selectIsHomeLoading);
  const state: ProductState = useSelector(selectProductState);
  const cartState: ICartState = useSelector(selectCartState);
  const [selectedImage, setSelectedImage] = useState<string>();

  useEffect(() => {
    if (state && state.productFilterResultModel?.rpds?.activesp) {
      if (state.productFilterResultModel!.rpds!.activesp!.imagelist !== 0) {
        setSelectedImage(
          state.productFilterResultModel!.rpds!.activesp!.imagelist[0]
        );
      }
    }
  }, [state.productFilterResultModel?.rpds?.activesp]);

  const addProductToCard = (lrsp, spid, sdt, edt) => {
    const p = {
      product: {
        id: Math.floor(Math.random() * 2340).toString(),
        itemid: lrsp.prodid,
        productid: lrsp.prodid,
        tileimage:
          lrsp.imagelist != null && lrsp.imagelist!.length > 0
            ? lrsp.imagelist![0]
            : CONSTANTS.noimageurl,
        title: lrsp.title,
        price: lrsp.priceinfo ?? 0,
        category: lrsp.title,
        rtype: lrsp.rtype,
        rcat: lrsp.category,
        unitprice: (lrsp.priceinfo ?? 0).toString(),
        namedrentalres:
          lrsp.invcat !== null &&
          [
            CONSTANTS.prodat_rentprod,
            CONSTANTS.prodat_hospital,
            CONSTANTS.prodat_hotel,
          ].includes(lrsp.atyp ?? ""),
        unit: "",
        qty: 1.0,
        totalprice: lrsp.priceinfo ?? 0,
        discountedprice: lrsp.priceinfo ?? 0,

        taxbracket: lrsp.taxbracket,
        gsttoken: lrsp.gsttoken,
      },
    };
    if (cartState.lsm?.spi !== null) {
      dispatch(
        addedProductCartEvent({
          ...p,
          sdt: CONSTANTS.getJustDatePart(sdt),
          edt: CONSTANTS.getJustDatePart(edt),
        })
      );
    } else {
      dispatch(
        addedProductCartEvent({
          ...p,
          spi: spid,
          sdt: CONSTANTS.getJustDatePart(sdt),
          edt: CONSTANTS.getJustDatePart(edt),
        })
      );
    }
  };

  const KeyValue = ({ title, value }) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: text2, fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontSize: text2 }}>{value}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Badge
        badgeContent={cartState?.cart?.productlist?.length ?? 0}
        color="secondary"
        overlap="circular"
        sx={{
          position: "absolute",
          bottom: 20,
          right: 20,
          zIndex: 1,
        }}
      >
        <Fab
          size="large"
          color="primary"
          aria-label="add"
          sx={{
            zIndex: 0,
            color: 'white'
          }}
          onClick={() => {
            if (!auth?.currentUser?.isAnonymous) {
              navigate("/cart");
            } else {
              navigate("/login");
            }
          }}
        >
          {/* <Typography sx={{
          position: 'absolute',
          height: '20px',
          width: '20px',
          bottom: 35,
          right: 35,
          // padding:1,
          background: 'red',
          borderRadius: 20/2,
        }}>1</Typography> */}
          <ShoppingCartIcon />
        </Fab>
      </Badge>
      {state?.productFilterResultModel?.rpds?.activesp ? (
        <>
          <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box
                  minHeight="600px"
                  minWidth="600px"
                  sx={{
                    borderRadius: "5px",
                    marginRight: "10px",
                    backgroundImage: `url(${selectedImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={3}>
                {state.productFilterResultModel!.rpds!.activesp!.imagelist.map(
                  (img) => (
                    <Box
                      onClick={() => {
                        setSelectedImage(img);
                      }}
                      minHeight="150px"
                      minWidth="150px"
                      maxHeight="400px"
                      sx={{
                        overFlow: "auto",
                        borderRadius: "5px",
                        marginBottom: "10px",
                        backgroundImage: `url(${img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        border: selectedImage === img ? "1px solid black" : "",
                        "&:hover": {
                          border: "1px solid black",
                        },
                      }}
                    ></Box>
                  )
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={text1}>
                  {state.productFilterResultModel!.rpds!.activesp!.spname ??
                    "NoName"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <CKeyValueList
                  labelWidth={200}
                  size="medium"
                  list={[
                    {
                      key: "Address",
                      value: CONSTANTS.getLimitedAddressStringFromAreaModel(
                        state.productFilterResultModel?.rpds?.activesp?.adm
                      ),
                    },
                    {
                      key: "Phone",
                      value:
                        state.productFilterResultModel?.rpds?.activesp?.phone ??
                        "NoPhone",
                    },
                    {
                      key: "Email",
                      value:
                        state.productFilterResultModel?.rpds?.activesp?.email ??
                        "NoEmail",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={8}>
                <Stack direction="column">
                  <Typography fontSize={text2}>Room</Typography>
                  {state.productFilterResultModel?.rpds?.activesp?.prodlist.map(
                    (data) => (
                      <Box
                        key={data.prodid}
                        onClick={() => {}}
                        sx={{
                          padding: "10px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          wordBreak: "break-word",
                          background: "white",
                          borderRadius: "10px",
                          border: "1px solid #000000",
                          //   "&:hover": {
                          //     border: "1px solid #000000",
                          //     cursor: "pointer",
                          //   },
                          overflow: "scroll",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Box
                            minHeight="150px"
                            minWidth="150px"
                            sx={{
                              borderRadius: "5px",
                              marginRight: "10px",
                              backgroundImage: `url(${
                                state.productFilterResultModel?.rpds?.activesp
                                  ?.imagelist[0] ?? ""
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: heading3,
                                fontWeight: "bold",
                                color: "primary.main",
                              }}
                            >
                              {data.title}
                            </Typography>
                            <Grid container>
                              <KeyValue
                                title="Name"
                                value={data?.title ?? "NoTitle"}
                              />
                              <KeyValue
                                title="Description"
                                value={data?.desc ?? "NoDesc"}
                              />

                              <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                  {(data?.amen ?? []).map((roomData) => (
                                    <Box
                                      sx={{
                                        mr: 2,
                                      }}
                                    >
                                      <WifiIcon />
                                      <Typography fontSize={text3}>{roomData}</Typography>
                                    </Box>
                                  ))}
                                </Stack>
                              </Grid>

                              <KeyValue
                                title="PerDay"
                                value={data?.priceinfo ?? 0}
                              />
                              <Grid item xs={12}>
                                <CButton
                                sx={{
                                  color: textColor
                                }}
                                  fullWidth
                                  variant="contained"
                                  onClick={() => {
                                    addProductToCard(
                                      data,
                                      state.productFilterResultModel!.rpds!
                                        .activesp!.spi,
                                      state.productFilterModel?.rpsf?.sdt,
                                      state.productFilterModel?.rpsf?.edt
                                    );
                                  }}
                                >
                                  Book now
                                </CButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
}
