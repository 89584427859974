import { createTheme, ThemeOptions } from "@mui/material/styles";
declare module "@mui/material/styles" {
  // To use the property by accessing theme variable like theme.orderStatus.newOrder
  // we need to add the same property in Theme itself
  interface CustomPalette {
    orderStatus: {
      newOrder: {
        main: string;
        contrastText: string;
      };
      readyForPickup: {
        main: string;
        contrastText: string;
      };
      inProgress: {
        main: string;
        contrastText: string;
      };
      readyForDelivery: {
        main: string;
        contrastText: string;
      };
      cancelled: {
        main: string;
        contrastText: string;
      };
      delivered: {
        main: string;
        contrastText: string;
      };
    };
  }

  // To add custom values in theme, we need to add the property in ThemeOptions
  interface CustomPaletteOption {
    orderStatus: {
      newOrder: {
        main: React.CSSProperties["color"];
        contrastText: React.CSSProperties["color"];
      };
      readyForPickup: {
        main: React.CSSProperties["color"];
        contrastText: React.CSSProperties["color"];
      };
      inProgress: {
        main: React.CSSProperties["color"];
        contrastText: React.CSSProperties["color"];
      };
      readyForDelivery: {
        main: React.CSSProperties["color"];
        contrastText: React.CSSProperties["color"];
      };
      cancelled: {
        main: React.CSSProperties["color"];
        contrastText: React.CSSProperties["color"];
      };
      delivered: {
        main: React.CSSProperties["color"];
        contrastText: React.CSSProperties["color"];
      };
    };
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPaletteOption {}

  // If we want to add some custom property in theme direcly, (not in palette)
  // we can just extend Theme and ThemeOptions in same way
  // interface Theme extends CustomThemeOption {}
  // interface ThemeOption extends CustomThemeOption {}
}

const themeOptions: ThemeOptions = {
  typography: {
    fontSize: 11,
  },
  palette: {
    mode: "light",
    primary: {
      main: "#014876",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#f50057",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#f43636",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#66bb66",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#29b9f6",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ff9b26",
      contrastText: "#FFFFFF",
    },
    orderStatus: {
      newOrder: {
        main: "#f0ad4e",
        contrastText: "#FFFFFF",
      },

      readyForPickup: {
        main: "#008edd",
        contrastText: "#FFFFFF",
      },

      inProgress: {
        main: "#76a2ee",
        contrastText: "#FFFFFF",
      },

      readyForDelivery: {
        main: "#2fbbb2",
        contrastText: "#FFFFFF",
      },

      cancelled: {
        main: "#d9534f",
        contrastText: "#FFFFFF",
      },

      delivered: {
        main: "#5cb85c",
        contrastText: "#FFFFFF",
      },
    },
  },
  // breakpoints: {
  //   values: {
  //       xs: 0,
  //       sm: 400,
  //       md: 600,
  //       lg: 900,
  //       xl: 1200,
  //     },
  // },
};

export const theme = createTheme(themeOptions);
