import { createSlice } from '@reduxjs/toolkit';
import { auth } from 'src/configs/firebase';
import * as CONSTANTS from 'src/constants';

import {
  completeAppointmentCreation,
  fetchAppointmentEmployee,
  getBusySlotOccupancy,
  selectedAppointmentEmployee,
  selectedAppointmentType,
} from './thunks';
import { AppointmentDataItem, AppointmentState, IAppointmentSlice } from './types';

function doStateHandlingForDifferentAptType(
  state: AppointmentState,
  returndata: AppointmentDataItem[] | null,
  endcursor: string | null,
  apttype: string
): AppointmentState {
  if (apttype === CONSTANTS.apt_checkin) {
    const lb = [null, undefined].includes(state.aptcardlist)
      ? []
      : state.aptcardlist;
    mergeAptDataItemList(lb, returndata);
    const my: AppointmentState = {
      ...state,
      aptcardlist: lb,
      endcursor_ci: endcursor,
      loading: false,
    };
    return my;
  } else if (apttype === CONSTANTS.apt_regular) {
    const lb = [null, undefined].includes(state.aptcardlist_regular)
      ? []
      : state.aptcardlist_regular;
    mergeAptDataItemList(lb, returndata);
    const my: AppointmentState = {
      ...state,
      aptcardlist_regular: lb,
      endcursor_reg: endcursor,
    };
    return my;
  } else {
    return state;
  }
}

function mergeAptDataItemList(
  lb: AppointmentDataItem[],
  returndata: AppointmentDataItem[] | null
): AppointmentDataItem[] {
  const mylist: number[] = []; // for return data
  const forexistingdata: number[] = []; // for existing data
  const lastDate = lb.length > 0 ? lb[0].odt : null;

  if (returndata === null || returndata.length === 0) return lb;
  if (
    lastDate !== null &&
    returndata[0].odt !== null &&
    new Date(returndata[0].odt).getTime() - new Date(lastDate).getTime() >
      12 * 60 * 60 * 1000
  )
    return lb;

  for (let j = 0; j < returndata.length; j++) {
    for (let i = 0; i < lb.length; i++) {
      if (returndata[j].docid === lb[i].docid) {
        if (returndata[j].state !== "close") {
          lb[i] = returndata[j];
          mylist.push(j);
        } else {
          mylist.push(j);
          forexistingdata.push(i);
        }
        break;
      }
    }
  }

  mylist.sort((a, b) => b - a);
  forexistingdata.sort((a, b) => b - a);

  for (const p of mylist) {
    returndata.splice(p, 1);
  }
  for (const p of forexistingdata) {
    lb.splice(p, 1);
  }
  lb.push(...returndata);

  return lb;
}

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    appointmentState: {},
    getBusySlotOccupancyResponse: null,
    isError: false,
    isLoading: false,
  } as IAppointmentSlice,
  reducers: {
    setAppointmentServiceModel(state, action): void {
      state.appointmentState = action.payload;
    },
    updateAppointmentState(state, action): void {
      state.appointmentState = {
        ...state.appointmentState,
        ...action.payload,
      };
    },
    updateCustomerInAppointmentState(state, action): void {
      state.appointmentState.onCreationAppointment = {
        ...state.appointmentState.onCreationAppointment,
        ...action.payload,
      };
    },
    selectedNewAppointmentSlot(state, action): void {
      if (!state.appointmentState.creatingManualAppointment) {
        state.appointmentState.onCreationAppointment = {};
      }

      let reqtzoffset =
        state.appointmentState.fromentitypage !== null &&
        state.appointmentState.fromentitypage!
          ? true
          : false;
      let tzoffset = state.appointmentState.serviceModel?.tzoffset ?? 0;

      let mycustomerid: string = null;
      if (state.appointmentState.customerdatasm)
        mycustomerid = state.appointmentState.customerdatasm!.docid;
      else if (state.appointmentState.customerdata)
        mycustomerid = state.appointmentState.customerdata!.value;

      state.appointmentState.onCreationAppointment! = {
        ...state.appointmentState.onCreationAppointment!,
        customerid: mycustomerid,
        starttime: action.payload.startTime,
        slotduration: action.payload.slotDuration,
        prodtype: state.appointmentState.prodtype,
        appointmenttype: action.payload.appointmentType,
        slotconfigurationtype: action.payload.appointmentType,
        dayofweek: action.payload.selectedDate!.getDay().toString(),
        serviceproviderid: state.appointmentState.serviceModel!.serviceID,
        serviceprovidername: state.appointmentState.serviceModel!.servicename,
        servicetype: state.appointmentState.serviceModel!.servicetype[0] ?? "",
        startdate: CONSTANTS.formatDate(
          action.payload.selectedDate!,
          "MMM-dd-yyyy"
        ),
        startdatetimestamp: CONSTANTS.getJustDatePartWithTZAdjustment(
          action.payload.selectedDate!,
          tzoffset,
          reqtzoffset
        ),
        stuffid: state.appointmentState.selectedEmployee?.staffid,
        queuerunningnumber: action.payload.queueRunningNumber,
        period: action.payload.selectedSlotName,
        state: "req",
        instore: false,
        isactive: false,
        assigned: false,
        oncreation: false,
        isopen: true,
        grpmem: state.appointmentState.newcustmember,
      };

      if (!state.appointmentState.isfromowner) {
        state.appointmentState.onCreationAppointment! = {
          ...state.appointmentState.onCreationAppointment!,
          customername: auth.currentUser.displayName ?? "",
          customercontactnumber: auth.currentUser.phoneNumber ?? "",
          custuserid: auth.currentUser.uid,
        };
      }

      state.appointmentState = {
        ...state.appointmentState,
        newsave: false,
      };
    },
  },
  extraReducers: (builder) => {
    // selectedAppointmentType
    builder.addCase(selectedAppointmentType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(selectedAppointmentType.fulfilled, (state, action) => {
      if (action.payload) {
        const returndata = JSON.parse(action.payload.data);

        if (returndata.errorid === -1) {
          // let slots = returndata.slotlist;
          // let newSlots = slots.map(slot => (slot?.slotlist?.map(innerSlot => ({...innerSlot, isBusy: innerSlot.isBusy ?? true}))));
          // returndata.slotlist = newSlots;
          state.appointmentState.slotConfigurations = returndata.slotlist;
        } else {
          state.appointmentState.slotConfigurations = [];
        }
        state.isLoading = false;
      } else {
        state.isLoading = false;
      }
    });
    builder.addCase(selectedAppointmentType.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // getBusySlotOccupancy
    builder.addCase(getBusySlotOccupancy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBusySlotOccupancy.fulfilled, (state, action) => {
      state.getBusySlotOccupancyResponse = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getBusySlotOccupancy.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // completeAppointmentCreation
    builder.addCase(completeAppointmentCreation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(completeAppointmentCreation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      if (action.payload) {
        const m1data = JSON.parse(action.payload.data);

        if (m1data.errorid === -1) {
          const id = m1data!.aptcard?.docid;
          if (![null, undefined].includes(id)) {
            state.appointmentState.customerdatasm = null;
            state.appointmentState.newcustmember = null;
            state.appointmentState.customerdata = null;
            state.appointmentState.onCreationAppointment.id = id;
            state.appointmentState.onCreationAppointment.mycartprods =
              action.payload.order;
            state.appointmentState.onCreationAppointment.queuerunningnumber = ![
              null,
              undefined,
            ].includes(m1data.aptcard?.qnum)
              ? parseInt(m1data.aptcard!.qnum!)
              : 0;
            if (
              ![null, undefined].includes(m1data.aptcard) &&
              ![null, undefined].includes(m1data.aptcard!.apttype)
            ) {
              const mnew = [m1data.aptcard!];
              doStateHandlingForDifferentAptType(
                state.appointmentState,
                mnew,
                null,
                m1data.aptcard!.apttype
              );
              state = { ...state };
            }
          } else {
            state = { ...state };
          }
        }
      }
    });
    builder.addCase(completeAppointmentCreation.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchAppointmentEmployee
    builder.addCase(fetchAppointmentEmployee.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentEmployee.fulfilled, (state, action) => {
      if (action.payload?.data?.errorid !== -1) {
        state.appointmentState.staffList = [];
      } else {
        state.appointmentState.staffList =
          action.payload?.data?.aptstafflist === null
            ? []
            : action.payload?.data?.aptstafflist!;
      }
      state.isLoading = false;
    });
    builder.addCase(fetchAppointmentEmployee.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // selectedAppointmentEmployee
    builder.addCase(selectedAppointmentEmployee.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(selectedAppointmentEmployee.fulfilled, (state, action) => {
      if (action.payload?.errorid === -1) {
        state.appointmentState.slotConfigurations =
          action.payload?.slotlist ?? [];
      } else {
        state.appointmentState.slotConfigurations = [];
      }
      state.isLoading = false;
    });
    builder.addCase(selectedAppointmentEmployee.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const {
  setAppointmentServiceModel,
  updateAppointmentState,
  selectedNewAppointmentSlot,
  updateCustomerInAppointmentState,
} = appointmentSlice.actions;
export const { reducer } = appointmentSlice;
