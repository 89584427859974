import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLastPayloadForProductCall,
  selectShopData,
} from "src/slices/landing-page/selectors";
import Box from "src/components/pages/landing-page/Box";
import { ProductListItem } from "../cart-product/product-list-item";
import { VehicleListItem } from "src/components/pages/vehicle/vehicle-list-item";
import { PetListItem } from "src/components/pages/pet/pet-list-item";
import { JobListItem } from "src/components/pages/job/job-list-item";
import { RealEstateListItem } from "src/components/pages/real-estate/real-estate-list-item";
import { AppDispatch } from "src/store";
import { useCallback } from "react";
import { fetchShopProductFilterResult } from "src/slices/landing-page/thunks";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";

export const ShopProduct = () => {
  const dispatch = useDispatch<AppDispatch>();
  const shopData: any = useSelector(selectShopData);
  const payload: any = useSelector(selectLastPayloadForProductCall);

  const { heading3, foregroundColor } = useDisplaySettingsContext();
  
  const loadMoreData = useCallback(() => {
    const newPayload = {
      dynamicProperty: {
        ...payload.dynamicProperty,
        offset: payload.dynamicProperty.offset + 10,
      },
    };
    dispatch(fetchShopProductFilterResult(newPayload));
  }, [payload]);

  const LoadMore = () => {
    return (
      <>
        {shopData?.hasmoreresults && (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: heading3,
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => loadMoreData()}
          >
            Load More
          </Typography>
        )}
      </>
    );
  };
  return (
    <>
      {shopData?.docwithdata1?.length > 0 && (
        <Box
          id="shop"
          title="Shop"
          subtitle=""
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            paddingLeft: { xs: 2, sm: 12 },
            paddingRight: { xs: 2, sm: 12 },
            paddingBottom: 5,
          }}
        >
          <Grid container spacing={1}>
            {shopData?.docwithdata1?.map((data) => (
              <Grid item xs={12} md={6}>
                <ProductListItem key={data.productid} productData={data} />
              </Grid>
            ))}
          </Grid>
          <LoadMore />
        </Box>
      )}
      {shopData?.docwithdata4?.length > 0 && (
        <Box
          id="shop"
          title="Shop"
          subtitle=""
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            paddingLeft: { xs: 2, sm: 12 },
            paddingRight: { xs: 2, sm: 12 },
            paddingBottom: 5,
          }}
        >
          <Grid container spacing={1}>
            {shopData?.docwithdata4?.map((data) => (
              <Grid item xs={12} md={6}>
                <VehicleListItem key={data.productid} vehicle={data} />
              </Grid>
            ))}
          </Grid>
          <LoadMore />
        </Box>
      )}
      {shopData?.docwithdata5?.length > 0 && (
        <Box
          id="shop"
          title="Shop"
          subtitle=""
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            paddingLeft: { xs: 2, sm: 12 },
            paddingRight: { xs: 2, sm: 12 },
            paddingBottom: 5,
          }}
        >
          <Grid container spacing={1}>
            {shopData?.docwithdata5?.map((data) => (
              <Grid item xs={12} md={6}>
                <PetListItem key={data.productid} pet={data} />
              </Grid>
            ))}
          </Grid>
          <LoadMore />
        </Box>
      )}
      {shopData?.docwithdata2?.length > 0 && (
        <Box
          id="shop"
          title="Shop"
          subtitle=""
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            paddingLeft: { xs: 2, sm: 12 },
            paddingRight: { xs: 2, sm: 12 },
            paddingBottom: 5,
          }}
        >
          <Grid container spacing={1}>
            {shopData?.docwithdata2?.map((data) => (
              <Grid item xs={12} md={6}>
                <JobListItem key={data.productid} job={data} />
              </Grid>
            ))}
          </Grid>
          <LoadMore />
        </Box>
      )}
      {shopData?.docwithdata3?.length > 0 && (
        <Box
          id="shop"
          title="Shop"
          subtitle=""
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            paddingLeft: { xs: 2, sm: 12 },
            paddingRight: { xs: 2, sm: 12 },
            paddingBottom: 5,
          }}
        >
          <Grid container spacing={1}>
            {shopData?.docwithdata3?.map((data) => (
              <Grid item xs={12} md={6}>
                <RealEstateListItem key={data.productid} realEstate={data} />
              </Grid>
            ))}
          </Grid>
          <LoadMore />
        </Box>
      )}
    </>
  );
};
