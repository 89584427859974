import {
  Box,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import CTypography from "src/components/CTypography";
import { ICartState } from "src/slices/cart/types";
import { CartCounter } from "../../counter";
import {
  changedQuantityCartEvent,
  getRentalProductInventoryStatus,
} from "src/slices/cart/thunks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import CButton from "src/components/button-group/CButton";
import CModal from "src/components/CModal";
import { setDialog } from "src/slices/uiSettingsSlice";
import { selectCartState } from "src/slices/cart/selectors";
import { format } from "date-fns";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const CBox = styled(Box)(({ theme }) => ({
  height: "50px",
  width: "100px",
  display: "flex",
  background: theme.palette.primary.main,
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  borderRadius: "5px",
}));

interface OrderCartItemProps {
  item?: any;
  modifiableStatuses?: string[];
  isnoneditable?: boolean;
  state?: ICartState;
  isRental?: boolean;
  showrentalitemselector?: boolean;
  showrentalreturnbutton?: boolean;
  showrentalitemstatus?: boolean;
  showitembuildstatus?: boolean;
  allowbuildstateupdate?: boolean;
  showitemstate?: boolean;
  allowitemstateupdate?: boolean;
  showresouurcepicekeroncard?: boolean;
  showresourceavailabilityonitemcard?: boolean;
  startDate?: Date;
  endDate?: Date;
  onRentStatusChange?: Function;
  isActive?: boolean;
}

export const OrderCartItem: FC<OrderCartItemProps> = ({
  item,
  modifiableStatuses,
  state,
  isnoneditable,
  showitemstate,
  allowitemstateupdate,
  isRental,
  isActive,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const cartState: ICartState = useSelector(selectCartState);

  const { text1, text2, text3} = useDisplaySettingsContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const KeyValue = ({ leftValue, rightValue, isBold = false }) => {
    return (
      <>
        <Grid item xs={6}>
          <Typography
            sx={{ fontSize: text2 , fontWeight: isBold ? "bold" : "normal" }}
          >
            {leftValue}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ fontSize: text2 , fontWeight: isBold ? "bold" : "normal" }}
          >
            {rightValue}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "5px",
        width: "100%",
        [theme.breakpoints.up("md")]: { height: "150px" },
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          textAlign: "center",
        },
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        padding: "10px 10px 10px 10px",
        marginBottom: "10px",
      }}
    >
      <Box
        minHeight={isMobile ? "100px" : "130px"}
        minWidth={isMobile ? "100px" : "130px"}
        sx={{
          borderRadius: "5px",
          marginRight: "10px",
          backgroundImage: `url(${item.tileimage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CTypography fontSize={text1}>{item.title ?? ""}</CTypography>
        <CTypography fontSize={text2}>{item.unitprice!}</CTypography>
        {showitemstate && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CTypography fontSize={text2}>Current State</CTypography>
            {allowitemstateupdate ? (
              <CButton size="small" variant="outlined" sx={{fontSize: text2}}>
                Update State
              </CButton>
            ) : (
              <></>
            )}
          </Box>
        )}
        {isRental && !isActive && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CButton
              onClick={() => {
                dispatch(
                  getRentalProductInventoryStatus({
                    sdt: cartState.cart!.sdate ?? new Date(Date.now()),
                    edt: cartState.cart!.edate ?? new Date(Date.now()),
                    pd: item,
                  })
                ).then(() => {
                  dispatch(
                    setDialog({ open: true, dialogName: "InventoryDialog" })
                  );
                });
              }}
              sx={{fontSize: text3}}
            >
              Inventory
            </CButton>
          </Box>
        )}
      </Box>
      {!modifiableStatuses.includes(state.cart!.orderstate) || isnoneditable ? (
        <CBox sx={{fontSize: text2}}>{item.qty!.toFixed(1)}</CBox>
      ) : (
        <CartCounter
          initValue={parseInt(item.qty!.toFixed(0))}
          onChange={(value) => {
            dispatch(
              changedQuantityCartEvent({
                newQuantity: value,
                product: item,
              })
            );
            // console.log(value);
          }}
        />
      )}
      {dialog.InventoryDialog !== undefined && dialog.InventoryDialog ? (
        <CModal
          dialogName="InventoryDialog"
          title={"Inventory"}
          maxWidth="xs"
          showSaveButton={false}
          handleSaveButtonText="Apply"
          handleCancelButtonText="close"
          handleCancel={() => {}}
          handleSave={() => {
            dispatch(setDialog({ open: false, dialogName: "InventoryDialog" }));
          }}
          open={
            dialog.InventoryDialog === undefined
              ? false
              : dialog.InventoryDialog
          }
          content={
            <Grid container spacing={1}>
              <KeyValue leftValue="Date" rightValue="Quantity" isBold={true} />
              {cartState.inventsta?.map((inventoryData) => (
                <KeyValue
                  leftValue={format(new Date(inventoryData?.name), "E MM/dd")}
                  rightValue={inventoryData?.value}
                />
              ))}
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};
