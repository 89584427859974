import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectHistoryState = createSelector(
  selectSelf,
  (state) => state.orderHistory
);

export const selectIsHistoryLoading = createSelector(
  selectHistoryState,
  (orderHistory) => orderHistory.isLoading
);

export const selectHistory = createSelector(
  selectHistoryState,
  (orderHistory) => orderHistory.history
);
