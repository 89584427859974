import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Divider,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { toastMessage } from "src/slices/commonSlice";
import { loadResults } from "src/slices/user-panel/thunks";
import { AppDispatch } from "src/store";

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H"];

interface IViewResultDialog {
  dialogName: string;
  resultData: any;
}

export const ViewResultDialog: FC<IViewResultDialog> = ({
  dialogName,
  resultData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [result, setResult] = useState(null);
  const [mcqs, setMcqs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { foregroundColor } = useDisplaySettingsContext();

  useEffect(() => {
    if (resultData) {
      dispatch(loadResults(resultData)).then((response) => {
        setIsLoading(false);
        const res = response.payload?.data?.knowsummarylist?.answerlist ?? null;
        setMcqs(response.payload?.data?.rawasg?.qlist ?? null);
        setResult(res);
      });
    }
  }, [resultData]);

  const KeyValue = ({ label, value }) => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {result ? (
        <Grid container sx={mcqs && { height: "70vh" }}>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
            }}
          >
            Score
          </Typography>
          <Grid item xs={12}>
            <KeyValue label={result[0].name} value={result[0].id} />
            <KeyValue label={"Total Score"} value={result[0].totscore} />
            <KeyValue label={"Total Attempted"} value={result[0].qattempt} />
            <KeyValue label={"MC Correct"} value={result[0].mqcor} />
            <KeyValue label={"MC Wrong"} value={result[0].mqwrong} />
            <KeyValue label={"MC Skip"} value={result[0].mqskip} />
          </Grid>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
            }}
          >
            Details
          </Typography>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            {mcqs &&
              mcqs.map((mcq, index) => (
                <Box sx={{ py: 2 }}>
                  <Typography sx={{ marginBottom: "5px" }} variant="h6">{`${
                    index + 1
                  }. ${mcq?.questiontext}`}</Typography>
                  <Typography variant="subtitle2">Options</Typography>
                  <FormGroup>
                    {mcq?.choices?.map((choice, choiceIndex) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              result[index]?.assignmentAnswers?.answerlist[
                                choiceIndex
                              ]?.choices?.includes(choiceIndex) ?? false
                            }
                          />
                        }
                        label={`${ALPHABET[choiceIndex]}. ${choice.option}`}
                        sx={{
                          margin: "4px 0px",
                          border: "1px solid black",
                          borderRadius: "4px",
                          padding: 0,
                        }}
                      />
                    ))}
                  </FormGroup>
                  <KeyValue label={"Weightage"} value={mcq.score} />
                  <KeyValue
                    label={"Correct Answer"}
                    value={mcq?.choices
                      .map((choice, index) =>
                        choice.isans ? ALPHABET[index] : ""
                      )
                      .filter((choice) => choice)
                      .join(", ")}
                  />
                  <Divider />
                </Box>
              ))}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!isLoading ? (
            <Typography>No Result Found</Typography>
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </Box>
      )}
    </>
  );
};
