import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore } from 'firebase/firestore';
import app, { auth } from 'src/configs/firebase';

import { setFcl, setRoomStatus } from '.';
import { toastMessage } from '../commonSlice';
import { ChannelRoomUpdatedStatus } from './types';

// const getProjectAuthRepositoryForEntityIdTrafficEndUserChat = async (
//   appName,
//   userId
// ) => {
//   let response = await sendRequestJSON(
//     {
//       data: {
//         appnamesforsecondardfirebase: [appName],
//         platform: "web",
//         qrytype: "CUSTOMTOKEN",
//         foruserid: userId,
//       },
//     },
//     "",
//     "post",
//     BaseUrl.digital
//   );

//   const resp = response.result?.data?.data;
//   if (resp?.credinfolist && resp?.credinfolist?.length > 0) {
//     const appcred = resp.credinfolist![0];
//     const firebaseConfig = {
//       appId: appcred.googleappid,
//       apiKey: appcred.apikey,
//       projectId: appcred.projid,
//       messagingSenderId: appcred.senderid,
//       databaseURL: appcred.firebaseurl,
//       storageBucket: appcred.storagebucket,
//     };
//     const newFirebaseApp = initializeApp(firebaseConfig, "M" + appcred.appname);
//   }
// };

// export const initializeRoom = createAsyncThunk(
//   "chat/initializeRoom",
//   async (payload: any, { dispatch, rejectWithValue }) => {
//     console.log(payload);
//     try {
//     } catch (error) {
//       dispatch(
//         toastMessage({
//           error: true,
//           message: "Error has occured",
//         })
//       );
//       return rejectWithValue("Error has occured");
//     }
//   }
// );

export const initializeTrafficRoom = createAsyncThunk(
  "chat/initializeTrafficRoom",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const d = {
        apistate: ChannelRoomUpdatedStatus.INACTIVE,
        userid: payload.userid,
        usertraffic: payload.usertraffic,
        ccr: payload.ccr,
      };
      dispatch(setRoomStatus(d));
      // await getProjectAuthRepositoryForEntityIdTrafficEndUserChat(
      //   payload.ccr.appname,
      //   payload.userid
      // );
      let ff: any = 0;
      let crpar = payload.ccr.crid!.split("@");
      if (app && crpar.length === 2) {
        let dbane = crpar[0];
        dbane = "mychatdb1";
        let ff = getFirestore(app, dbane);
        console.log(ff);

        dispatch(
          setFcl({
            metaid: crpar[0],
            roomid: crpar[1],
            firebasefirestore: ff,
            userid: auth.currentUser.uid,
            authrepo: app,
          })
        );

        dispatch(
          setRoomStatus({
            apistate: ChannelRoomUpdatedStatus.DATALIST,
            usertraffic: payload.usertraffic,
            userid: payload.userid,
            ccr: payload.ccr,
            fcl: {
              metaid: crpar[0],
              roomid: crpar[1],
              firebasefirestore: ff,
              userid: auth.currentUser.uid,
              authrepo: app,
            },
            metaid: crpar[0],
          })
        );
      }
      // const response = await sendRequestJSON(
      //   payload,
      //   "",
      //   "post",
      //   BaseUrl.digital
      // );
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

// export const getRegularLocation = createAsyncThunk(
//   "trip/getRegularLocation",
//   async (payload: any, { dispatch, getState, rejectWithValue }) => {
//     const state: ITripSlice = (getState() as any).trip;
//     try {
//       const response = await sendRequestJSON(
//         {
//           dynamicProperty: {
//             entitytype: null,
//             datatype: "GETTRIPLOC",
//             identifier: null,
//             qrytxt: null,
//             entityid: payload.entityid,
//             date: null,
//             timeoffset: Math.abs(new Date().getTimezoneOffset() * 60),
//             tripcutidlist: [payload.tcid],
//             haspub: null,
//             offset: null,
//             curlongi: null,
//             curlati: null,
//             radius: null,
//           },
//         },
//         "/Search/SearchForRelationEntityPage",
//         "post",
//         BaseUrl.ubuntu
//       );

//       return response.result?.data?.triploclist[0];
//     } catch (error) {
//       return null;
//     }
//   }
// );

// export const getAdhocLocation = createAsyncThunk(
//   "trip/getAdhocLocation",
//   async (_, { dispatch, getState, rejectWithValue }) => {
//     const state: ITripSlice = (getState() as any).trip;
//     const loginState = (getState() as any).loginInformation;
//     try {
//       const response = await sendRequestJSON(
//         {
//           dynamicProperty: {
//             entitytype: null,
//             datatype: "GETADHOCTRIPLOC",
//             identifier: loginState.user.uid ?? '',
//             qrytxt: null,
//             rname: state.tripState?.spadhoclst[0]?.rname ?? null,
//             entityid: null,
//             date: null,
//             timeoffset: Math.abs(new Date().getTimezoneOffset() * 60),
//             tripcutidlist: null,
//             haspub: null,
//             offset: null,
//             curlongi: null,
//             curlati: null,
//             radius: null,
//           },
//         },
//         "/Search/SearchForRelationEntityPage",
//         "post",
//         BaseUrl.ubuntu
//       );

//       return response.result?.data?.triploclist[0];
//     } catch (error) {
//       return null;
//     }
//   }
// );
