import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CKeyValueList from 'src/components/CKeyValueList';
import StaticGoogleMap from 'src/components/pages/home/static-google-map';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { selectTripLocation, selectTripState } from 'src/slices/trip/selectors';
import { getAdhocLocation, getRegularLocation, getTripListData } from 'src/slices/trip/thunks';
import { AppDispatch } from 'src/store';

export const TripView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();
  
  const queryParams = new URLSearchParams(location.search);
  const tripId = queryParams.get("tripid");

  const tripState = useSelector(selectTripState);
  const tripLocation = useSelector(selectTripLocation);

  useEffect(() => {
    if (tripId) {
      dispatch(getTripListData(tripId));
    }
  }, [tripId, dispatch]);

  useEffect(() => {
    if (tripId && tripState) {
      if (tripId.includes("@")) {
        dispatch(
          getRegularLocation({
            tcid: tripState.spadhoclst[0].adhocid,
            entityid: tripState.spadhoclst[0].driinfo.spid,
          })
        );
      } else {
        dispatch(getAdhocLocation(false));
      }
    }
  }, [tripState]);

  return tripState && tripState.spadhoclst ? (
    <Grid container spacing={2}>
      {tripState.spadhoclst.map((tripData) => (
        <>
          <Grid item xs={12} md={4}>
            <CKeyValueList
              labelWidth={200}
              size="medium"
              list={[
                {
                  key: "Service Provided by",
                  value: tripData.driinfo.spname,
                },
                {
                  key: "Vehicle Number",
                  value: tripData.driinfo.vehnum,
                },
                {
                  key: "Driver Name",
                  value: tripData.driinfo.username,
                },
                {
                  key: "Sender Name",
                  value: tripData.delitem.sender?.name ?? "",
                },
                {
                  key: "Sender Address",
                  value: tripData.delitem.sender?.address?.addressline ?? "",
                },
                {
                  key: "Receiver Name",
                  value: tripData.delitem.receiver?.name ?? "",
                },
                {
                  key: "Receiver Address",
                  value: tripData.delitem.receiver?.address?.addressline ?? "",
                },
                {
                  key: "Start Time",
                  value:
                    new Date(tripData.starttime * 1000).toLocaleDateString() ??
                    "",
                },
              ]}
            />
          </Grid>
          {tripLocation && (
            <Grid item xs={12} md={8}>
              <StaticGoogleMap
                latlngs={[
                  {
                    lat: tripLocation.lati ?? 0,
                    lng: tripLocation.longi ?? 0,
                  },
                ]}
                // lng={tripLocation.longi ?? 0}
                height={isMobile ? "50vh" : "80vh"}
                width="100%"
              />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography fontSize={heading3}>No Data</Typography>
    </Box>
  );
};
