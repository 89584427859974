import { Box, Stack, Typography, styled } from "@mui/material";
import TestimonialCard from "src/components/pages/landing-page/testimonial-card-slider/TestimonialCard";
import TestimonialHero from "./TestimonialHero";
import { useSelector } from "react-redux";
import { selectTestimonialList } from "src/slices/landing-page/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const HeroBox = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  background: "white",
  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  color: "#000",
  margin: 0,
  padding: 0,
}));

export const Testimonial = ({ deviceType }) => {
  const testimonialList = useSelector(selectTestimonialList);
  const { foregroundColor } = useDisplaySettingsContext();

  return (
    <>
      {testimonialList && (
        <Stack
          direction="column"
          gap="96px"
          sx={{ backgroundColor: "white", pt: "32px" }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "48px",
              fontWeight: 700,
              color: foregroundColor,
            }}
          >
            Testimonial
          </Typography>
          <HeroBox sx={{ textAlign: "-webkit-center" }}>
            <TestimonialHero deviceType={deviceType} />
          </HeroBox>
        </Stack>
      )}
    </>
  );
};
