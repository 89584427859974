import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Stack, Box, IconButton, Typography } from "@mui/material";
import { Clear, Delete } from "@mui/icons-material";

const FileDropzone = ({ acceptedFileTypes, onSelectedFilesChange }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSelectedFilesChange = useCallback(
    (files) => {
      setSelectedFiles(files);
      if (onSelectedFilesChange) {
        onSelectedFilesChange(files);
      }
    },
    [onSelectedFilesChange]
  );

  const removeFile = useCallback(
    (fileToRemove) => {
      handleSelectedFilesChange(
        selectedFiles.filter((file) => file !== fileToRemove)
      );
    },
    [handleSelectedFilesChange, selectedFiles]
  );

  const removeAllFiles = useCallback(() => {
    handleSelectedFilesChange([]);
  }, [handleSelectedFilesChange]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(
        (file) =>
          !selectedFiles.find((selectedFile) => selectedFile.path === file.path)
      );
      // handleSelectedFilesChange([...selectedFiles, ...filteredFiles]);
      handleSelectedFilesChange(filteredFiles);
    },
    [handleSelectedFilesChange, selectedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false
  });

  return (
    <Box
      sx={{
        border: "2px dashed grey",
        borderRadius: "10px",
        p: 2,
        textAlign: "center",
        cursor: "pointer",
        mb: 2,
        ...(isDragActive && {
          backgroundColor: "#e6f7ff",
          borderColor: "primary.main",
        }),
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Typography variant="subtitle1" gutterBottom>
        Drag and drop image here, or click to select image
      </Typography>
      {selectedFiles.length > 0 && (
        <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            Selected files:
          </Typography>
          <IconButton onClick={removeAllFiles}>
            <Delete />
          </IconButton>
        </Box>
      )}
      <Stack spacing={1} sx={{ mt: 1 }}>
        {selectedFiles.map((file) => (
          <Box
            key={file.path}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2">{file.name}</Typography>
            <IconButton onClick={() => removeFile(file)}>
              <Clear />
            </IconButton>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default FileDropzone;
