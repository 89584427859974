import PhoneIcon from '@mui/icons-material/Phone';
import { Box, styled, Typography } from '@mui/material';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';

const ContactBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "2px",
}));

export const UserContact = ({ contactNumber }) => {
  const { text1, text3, foregroundColor } = useDisplaySettingsContext();
  return (
    <ContactBox sx={{ color: foregroundColor }}>
      <PhoneIcon sx={{ fontSize: text1 }} />
      <Typography fontSize={text3}>{contactNumber}</Typography>
    </ContactBox>
  );
};
