import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from 'src/configs/firebase';
import { BaseUrl } from 'src/constants';
import * as CONSTANTS from 'src/constants';
import { sendRequestJSON } from 'src/lib/axios';

import { updateHistory } from '.';
import { toastMessage } from '../commonSlice';

function toJsonTimeStamp(time) {
  if (time !== null && time instanceof Date) {
    const utcTime = time.toISOString(); // Convert to UTC string
    const millisecondsSinceEpoch = new Date(utcTime).getTime(); // Get milliseconds since epoch
    return Math.round(millisecondsSinceEpoch / 1000);
  }
  return null;
}

export const getListDataEndUser = createAsyncThunk(
  "history/getListDataEndUser",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            euhm: {
              type: payload.type,
              action: "getdatalistfortype",
              sdt: toJsonTimeStamp(CONSTANTS.getJustDatePart(payload.sdt)),
              edt: toJsonTimeStamp(CONSTANTS.getJustDatePart(payload.edt)),
              uid: auth.currentUser.uid,
            },
            qrytype: "ENDUSERHISTORY",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      if (response.result?.data?.data?.errorid === -1) {
        dispatch(
          updateHistory({
            entityid: auth.currentUser.uid,
            qrytype: payload.type,
          })
        );
        return response.result.data;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const shareEndUserRating = createAsyncThunk(
  "history/shareEndUserRating",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            euhm: {
              rcar: {
                uid: auth?.currentUser?.uid,
                oid: payload.oid,
                comment: payload.comment,
                rating: payload.rating,
              },
              uid: auth.currentUser.uid,
            },
            qrytype: "ENDUSERSAVERATING",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      if (response.result?.data?.data?.errorid === -1) {
        return response.result.data;
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: "Unable to save rating, please try again later",
          })
        );
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Unable to save rating, please try again later",
        })
      );
      return rejectWithValue("Unable to save rating, please try again later");
    }
  }
);
