import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { router } from "./routes";
import store from "./store";
import { theme as appTheme } from "./theme";
import { GeolocationProvider } from "./contexts/GeolocationContext";
import {
  DisplaySettingsProvider,
  useDisplaySettingsContext,
} from "./contexts/DisplaySettings";

const persistor = persistStore(store);

export const AppSetup = () => {
  const [theme, setTheme] = useState(appTheme);

  const { foregroundColor } = useDisplaySettingsContext();

  useEffect(() => {
    const updatedTheme: any = {
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          main: foregroundColor,
        },
      },
    };

    setTheme(updatedTheme);
  }, [foregroundColor]);

  return (
    <DisplaySettingsProvider>
      <GeolocationProvider>
        <ReduxProvider store={store}>
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </PersistGate>
        </ReduxProvider>
      </GeolocationProvider>
    </DisplaySettingsProvider>
  );
};
