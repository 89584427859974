import { useSelector } from "react-redux";
import { selectProdatypeinfo } from "src/slices/landing-page/selectors";
import { AppointmentFilterView } from "./appointment-view";
import { Box } from "@mui/material";

export const SpecialWidget = () => {
  const prodatypeinfo = useSelector(selectProdatypeinfo);

  const SpecialWidgetComponent = () => {
    if (
      ["aptwidget", "medaptwidget"].includes(prodatypeinfo?.catlst?.widgettype)
    ) {
      return (
        <Box sx={{
            width: '100%',
            background: 'white',
            padding: '20px'
        }}>
          <AppointmentFilterView />
        </Box>
      );
    }
  };

  return <>{prodatypeinfo?.catlst?.widgettype && <SpecialWidgetComponent />}</>;
};
