import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material';
import Paper from '@mui/material/Paper';
import { TransitionProps } from '@mui/material/transitions';
import PropTypes from 'prop-types';
import React, { FC, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/store';

import { Slices } from '../constants';
import XIcon from '../icons/X';
import { setDialog } from '../slices/uiSettingsSlice';
import { ContainedButton, OutlinedButton } from './button-group';
import CTypography from './CTypography';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

interface ICModalConfirmation {
  title?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  content?: any;
  showSaveButton?: boolean;
  handleSave?: () => void;
  handleSaveButtonText?: string;
  showCancelButton?: boolean;
  handleCancel?: () => void;
  handleCancelButtonText?: string;
  dialogName: string;
  noContentPadding?: boolean;
}

function PaperComponent(props: any) {
  return <Paper {...props} />;
}

const CModalConfirmation: FC<ICModalConfirmation> = ({
  title,
  maxWidth = "xs",
  content,
  showSaveButton = true,
  handleSave,
  handleSaveButtonText,
  showCancelButton = true,
  handleCancel,
  handleCancelButtonText,
  dialogName,
  noContentPadding = false,
  ...rest
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { dialog } = useSelector((state: any) => ({
    dialog: state[Slices.UI_SETTINGS].dialog,
  }));

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setDialog({ open: false, dialogName }));
    }
  };
  return (
    <Dialog
      aria-labelledby="modal-modal-confirmation"
      aria-describedby="modal-modal-confirmation"
      open={dialog[dialogName] === undefined ? false : dialog[dialogName]}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      {...rest}
    >
      <span style={{ cursor: "move" }} className="handle">
        <DialogTitle>
          <CTypography sx={{ fontWeight: "600", fontSize: "18px" }}>
            {!!title && title !== "" ? title : "Confirmation"}
          </CTypography>
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={(e) => {
              handleClose(e, "crossIconClose");
              if (handleCancel) handleCancel();
            }}
          >
            <XIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
      </span>

      <DialogContent
        dividers
        sx={{ p: noContentPadding ? "6px 16px !important" : 2 }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {content !== undefined && typeof content === "string"
          ? content
          : content !== undefined
          ? content
          : "Are you sure you want to exit?"}
      </DialogContent>

      <DialogActions>
        {showSaveButton && (
          <ContainedButton
            autoFocus
            onClick={(e) => {
              handleClose(e, "ExitButtonClose");
              if (handleSave) handleSave();
            }}
          >
            {handleSaveButtonText === undefined ? "Yes" : handleSaveButtonText}
          </ContainedButton>
        )}

        {showCancelButton && (
          <OutlinedButton
            onClick={(e) => {
              handleClose(e, "ExitButtonClose");
              if (handleCancel) handleCancel();
            }}
          >
            {handleCancelButtonText === undefined
              ? "No"
              : handleCancelButtonText}
          </OutlinedButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

CModalConfirmation.propTypes = {
  title: PropTypes.string,
  maxWidth: PropTypes.any,
  content: PropTypes.any,
  showSaveButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSaveButtonText: PropTypes.string,
  handleCancelButtonText: PropTypes.string,
  dialogName: PropTypes.string,
  noContentPadding: PropTypes.bool,
};

export default CModalConfirmation;
