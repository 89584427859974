import { createSlice } from '@reduxjs/toolkit';

import {
  fetchProductFilterItemDetails,
  fetchProductFilterResult,
  fetchSingleServiceProvider,
  generalTextLookUp,
  getMoreServiceProviders,
  getServiceProviders,
} from './thunks';
import { IHomeSlice } from './types';

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    providers: null,
    selectedProvider: null,
    serviceProviderLastCall: null,
    fetchProductFilterResult: null,
    productFilterResultLastCall: null,
    productFilterItemDetails: null,
    generalTextLookupResult: null,
    isError: false,
    isLoading: false,
  } as IHomeSlice,
  reducers: {
    setLastProviderCall(state, action): void {
      state.serviceProviderLastCall = action.payload;
    },
    setProductFilterResultCall(state, action): void {
      state.productFilterResultLastCall = action.payload;
    },
    clearProductFilterItemDetails(state): void {
      state.productFilterItemDetails = null;
    },
    clearVehiclesList(state): void {
      state.fetchProductFilterResult = null;
    },
  },
  extraReducers: (builder) => {
    // getServiceProviders
    builder.addCase(getServiceProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServiceProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providers = action.payload;
    });
    builder.addCase(getServiceProviders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // getMoreServiceProviders
    builder.addCase(getMoreServiceProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMoreServiceProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providers.smlist = [
        ...state.providers.smlist,
        ...action.payload.smlist,
      ];
      state.providers.hasmorerecords = action.payload.hasmorerecords;
      // console.log("After concat:", state.providers);
    });
    builder.addCase(getMoreServiceProviders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchSingleServiceProvider
    builder.addCase(fetchSingleServiceProvider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSingleServiceProvider.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProvider = action.payload;
    });
    builder.addCase(fetchSingleServiceProvider.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchProductFilterResult
    builder.addCase(fetchProductFilterResult.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductFilterResult.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fetchProductFilterResult = action.payload;
    });
    builder.addCase(fetchProductFilterResult.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // fetchProductFilterItemDetails
    builder.addCase(fetchProductFilterItemDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchProductFilterItemDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.productFilterItemDetails = action.payload;
      }
    );
    builder.addCase(fetchProductFilterItemDetails.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // generalTextLookUp
    builder.addCase(generalTextLookUp.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(generalTextLookUp.fulfilled, (state, action) => {
      // state.isLoading = false;
      state.generalTextLookupResult = action.payload;
    });
    builder.addCase(generalTextLookUp.rejected, (state) => {
      // state.isLoading = false;
      state.isError = true;
    });
  },
});

export const {
  setLastProviderCall,
  clearProductFilterItemDetails,
  clearVehiclesList,
  setProductFilterResultCall,
} = homeSlice.actions;
export const { reducer } = homeSlice;
