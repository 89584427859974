import {
  TextField,
  IconButton,
  InputAdornment,
  Box,
  styled,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { auth } from "src/configs/firebase";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import { getServiceProviders } from "src/slices/home/thunks";
import * as CONSTANTS from "src/constants";
import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { AtypeTabButton } from "./AtypeTabButton";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";

const SearchTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid #0294cf;
    }

    &.Mui-focused active {
      border: 1px solid #0294cf;
    }

    .Mui-focused focus {
      border: 1px solid #0294cf;
    }
  }
`;

export const AtypeTab = ({ atype, atypelst, subdomain }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { foregroundColor } = useDisplaySettingsContext();

  return (
    <Box>
      <Box
        sx={{
          paddingBottom: "15px",
          display: "flex",
          flexDirection: "row",
          gap: '12px',
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {atypelst?.map((data) => (
          <AtypeTabButton subdomain={subdomain} data={data} isSelected={data.id === atype} />
        )) ?? []}
      </Box>
    </Box>
  );
};
