import { createAsyncThunk } from '@reduxjs/toolkit';
import { BaseUrl } from 'src/constants';
import * as CONSTANTS from 'src/constants';
import { sendRequestJSON } from 'src/lib/axios';

import { toastMessage } from '../commonSlice';
import { ITripSlice } from './types';

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getTripListData = createAsyncThunk(
  "trip/getTripListData",
  async (tripId: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            adhocactionmodel: {
              id: tripId,
              action: tripId.includes("@") ? "getforregular" : "getforadhoc",
            },
            qrytype: "WEBTRIPIDINFO",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      return JSON.parse(response.result?.data?.data);
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const getRegularLocation = createAsyncThunk(
  "trip/getRegularLocation",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state: ITripSlice = (getState() as any).trip;
    try {
      const response = await sendRequestJSON(
        {
          dynamicProperty: {
            entitytype: null,
            datatype: "GETTRIPLOC",
            identifier: null,
            qrytxt: null,
            entityid: payload.entityid,
            date: null,
            timeoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            tripcutidlist: [payload.tcid],
            haspub: null,
            offset: null,
            curlongi: null,
            curlati: null,
            radius: null,
          },
        },
        "/Search/SearchForRelationEntityPage",
        "post",
        BaseUrl.ubuntu
      );

      return response.result?.data?.triploclist[0];
    } catch (error) {
      return null;
    }
  }
);

export const getAdhocTripById = createAsyncThunk(
  "trip/getAdhocTripById",
  async (tripId: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            qrytype: "GETADHOCTRIPBYID",
            euhm: null,
            wpdm: null,
            applocationdata: null,
            genmsg: null,
            feepaymentmsg: null,
            timezoneoffset: 0,
            productactionrequest: null,
            rname: null,
            cname: null,
            orderactionrequest: null,
            aptreq: null,
            stockactionmodel: null,
            car: null,
            dtr: null,
            custenq: null,
            statusupdate: null,
            scar: null,
            tripreq: null,
            delactionmodel: null,
            adhocactionmodel: {
              dto: null,
              id: tripId, // "4705982156374016",
              offset: 0,
              trackid: null,
              fcmstatus: null,
              qrycode: null,
              atsp: null,
              action: null,
              latlng: null,
              dt: null,
              onlyopen: null,
              vehtype: null,
              isdelgood: null,
              formyvendor: null,
              entityid: null,
              entitytype: null,
              spname: null,
              staffid: null,
              uid: null,
              spid: null,
            },
            triplookupreq: null,
            foruserid: null,
            platform: null,
            dsrequest: null,
            statreq: null,
            spevent: null,
            appnamesforsecondardfirebase: null,
            lasttimestamp: 0,
            gm: null,
            acadmsg: null,
            ugm: null,
            gsi: null,
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      return response.result?.data?.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error has occured",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const getAdhocLocation = createAsyncThunk(
  "trip/getAdhocLocation",
  async (isById: boolean, { dispatch, getState, rejectWithValue }) => {
    const state: ITripSlice = (getState() as any).trip;
    const loginState = (getState() as any).loginInformation;
    try {
      const response = await sendRequestJSON(
        {
          dynamicProperty: {
            entitytype: null,
            datatype: "GETADHOCTRIPLOC",
            identifier: loginState.user.uid ?? "",
            qrytxt: null,
            rname: isById
              ? state.tripState?.dto?.rname ?? null
              : state.tripState?.spadhoclst[0]?.rname ?? null,
            entityid: null,
            date: null,
            timeoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            tripcutidlist: null,
            haspub: null,
            offset: null,
            curlongi: null,
            curlati: null,
            radius: null,
          },
        },
        "/Search/SearchForRelationEntityPage",
        "post",
        BaseUrl.ubuntu
      );

      return response.result?.data?.triploclist[0];
    } catch (error) {
      return null;
    }
  }
);

export const getTripRelatedLookups = createAsyncThunk(
  "trip/getTripRelatedLookups",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const loginState = (getState() as any).loginInformation;

    try {
      const serverList =
        loginState.endUserClusterInfo[
          `INDIA#${payload.state?.toUpperCase() ?? "GEORGIA"}`
        ]?.serviceprovider ?? [];

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      const response = await sendRequestJSON(
        {
          dynamicProperty: {
            offset: 0,
            qrytxt: payload.text,
            entityid: null,
            ispub: true,
            datatype: "ENDUSERPOINT",
            nidlst: null,
          },
        },
        "/api/Search/GetTripRelatedLookups",
        "post",
        BaseUrl.custom,
        server
      );

      return response.result?.data;
    } catch (error) {
      return null;
    }
  }
);

export const getEndUserSearchResults = createAsyncThunk(
  "trip/getEndUserSearchResults",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const loginState = (getState() as any).loginInformation;

    try {
      const serverList =
        loginState.endUserClusterInfo[
          `INDIA#${payload.state?.toUpperCase() ?? "GEORGIA"}`
        ]?.serviceprovider ?? [];

      let server = "";
      if (serverList.length > 0) {
        if (serverList.length === 1) server = serverList[0];
        else server = serverList[getRandomInt(0, serverList.length - 1)];
      }
      
      const response = await sendRequestJSON(
        {
          dynamicProperty: {
            offset: null,
            startpoint: null,
            endpoint: null,
            startpointstr: payload.startPoint,
            endpointstr: payload.endPoint,
            getactivetrip: false,
            date: CONSTANTS.toJsonTimeStamp(payload.dateOfTravel),
            curlati: null,
            curlongi: null,
            radius: null,
            usecur: null,
            entityid: null,
            nidlst: payload.nidlst,
            datatype: "ENDUSERTRIPCUT",
          },
        },
        "/api/Search/GetTripRelatedLookups",
        "post",
        BaseUrl.custom,
        server
      );

      return response.result?.data;
    } catch (error) {
      return null;
    }
  }
);
