import { createSlice } from "@reduxjs/toolkit";
import { Slices } from "src/constants";

import { IAuthSlice } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
// import { userProfileCreationRequest } from "./thunks";
const initialState: IAuthSlice = {
  isLoggedIn: false,
  user: null,
  endUserClusterInfo: {},
  endUserClusterInfoSP: {},
  userProfileCreationResponse: null,
};

const slice = createSlice({
  name: Slices.LOGIN_INFORMATION,
  initialState: { ...initialState },

  reducers: {
    setLoginUser(state: IAuthSlice, action: PayloadAction<IAuthSlice>): void {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.user = action.payload.user;
    },
    setLogout(state: IAuthSlice): void {
      state.isLoggedIn = false;
      state.user = null;
    },
    setEndUserClusterInfo(state: IAuthSlice, action: PayloadAction<any>): void {
      state.endUserClusterInfo = action.payload;
    },
    setEndUserClusterInfoSP(
      state: IAuthSlice,
      action: PayloadAction<any>
    ): void {
      state.endUserClusterInfoSP = {
        ...state.endUserClusterInfoSP,
        ...action.payload,
      };
    },
    clearUserProfileCreationResponse(state: IAuthSlice): void {
      state.userProfileCreationResponse = null;
    },
  },
  // extraReducers: (builder) => {
  //   // userProfileCreationRequest
  //   builder.addCase(userProfileCreationRequest.pending, (state) => {
  //     state.isLoading = true;
  //   });
  //   builder.addCase(userProfileCreationRequest.fulfilled, (state, action) => {
  //     state.isLoading = false;
  //     state = {
  //       ...state,
  //       userProfileCreationResponse: action.payload?.data,
  //     };
  //   });
  //   builder.addCase(userProfileCreationRequest.rejected, (state) => {
  //     state.isLoading = false;
  //     state.isError = true;
  //   });
  // },
});

export const {
  setLoginUser,
  setLogout,
  setEndUserClusterInfo,
  setEndUserClusterInfoSP,
  clearUserProfileCreationResponse,
} = slice.actions;
export const { reducer } = slice;
