import { Typography, Box, Grid, Paper, Button, Stack } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import {
  OrderDetailsOrigin,
  getFinalDeliveryOptionForCartEndUserSide,
} from "src/constants";
import { selectIsHomeLoading } from "src/slices/home/selectors";
import { AppDispatch } from "src/store";
import { OrderSummary } from "../order-summary";
import CTypography from "src/components/CTypography";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "src/components/formik/FormikControl";
import { selectCartState } from "src/slices/cart/selectors";
import { CartOrigin, ICartState } from "src/slices/cart/types";
import { addDays } from "date-fns";
import { toastMessage } from "src/slices/commonSlice";
import { useNavigate } from "react-router-dom";
import { DeliveryMethod } from "../delivery-method";
import { OrderCartItem } from "./order-cart-item";
import * as CONSTANTS from "src/constants";
import { cartCheckout } from "src/slices/cart/thunks";
import { resetCartState } from "src/slices/cart";
import { auth } from "src/configs/firebase";
import { selectLsm } from "src/slices/landing-page/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

interface IformInitialValue {
  customerName: string;
  customerPhone: string;
  address: string;
  finalAmount: string;
  amountPaid: string;
  startDate: string;
  endDate: string;
}

interface ICartViewProps {
  popsCount?: number;
  usagetype?: string;
}
export const CartPage: FC<ICartViewProps> = ({
  popsCount = 4,
  usagetype = "DEFAULT",
}) => {
  const navigate = useNavigate();

  const { heading2, text1, text2, textColor } = useDisplaySettingsContext();

  const formInitialValue: IformInitialValue = {
    customerName: "",
    address: "",
    customerPhone: "",
    finalAmount: "0",
    amountPaid: "0",
    startDate: "",
    endDate: "",
  };
  const validationSchemaModel = Yup.object().shape({});

  const [pageState, setPageState] = useState({
    statuses: [
      "PENDING",
      "PROCESSING",
      "DELIVERING",
      "DELIVERED",
      "READY TO PICK",
      "CLOSED",
      "CANCEL",
    ],
    modifiableStatuses: ["PENDING", "PROCESSING", "DELIVERING", "CLOSED"],
    detailorigin: null,
    showpaymentinfo: true,
    showorderstate: true,
    alloworderstateedit: false,
    showfinalandpaidamt: false,
    showdelivery: true,
    showaddresspicker: true,
    showrentalrelatedfields: true,
    showresouurcepicekeroncard: false,
    showresourcepickerfortableselection: false,
    noneditable: false,
    showresourceavailabilityonitemcard: false,
    showresourcereturnonitemcard: false,
    showitemstate: false,
    allowitemstateupdate: false,
    showAddToLedger: false,

    address: null,

    // c
    customerId: null,
    addToLedger: false,
    _reqdelivery: null,
    delmechanism: -1,
    _deliveryAddressIdxs: [],
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    deliveryAddresses: [],

    availableForRent: false,
  });

  const selectedCartState: ICartState = useSelector(selectCartState);
  const lsm = useSelector(selectLsm);

  useEffect(() => {
    formikRef.current.setFieldValue(
      "finalAmount",
      selectedCartState.cart?.totalcalculatedprice?.toFixed(2) ?? "0.00"
    );
    formikRef.current.setFieldValue(
      "amountPaid",
      selectedCartState.cart?.amountpaid?.toFixed(2) ?? "0.00"
    );
  }, [
    selectedCartState.cart?.totalcalculatedprice,
    selectedCartState.cart?.amountpaid,
  ]);

  useEffect(() => {
    if (selectedCartState.cart) {
      let _reqdelivery =
        selectedCartState.cart!.reqdel !== null &&
        (selectedCartState.cart!.reqdel! === 1 ||
          selectedCartState.cart!.reqdel! === 2);
      setPageState((state) => ({
        ...state,
        _reqdelivery: !state._reqdelivery ? _reqdelivery : state._reqdelivery,
        customerId: selectedCartState.cart?.customerid,
      }));
      if (selectedCartState.ordersaved) {
        // Navigate to next screen
        if (
          selectedCartState.calledfrom == CartOrigin.AppointmentEndUser ||
          selectedCartState.calledfrom == CartOrigin.EcomEndUser
        ) {
          dispatch(resetCartState());
          dispatch(
            toastMessage({
              message: "Order is Saved",
            })
          );
          navigate("/");
        }
      }
    }
  }, [selectedCartState]);

  useEffect(() => {
    const initOrigin = getFinalDeliveryOptionForCartEndUserSide(
      selectedCartState?.lsm || lsm,
      selectedCartState.prodtype
    );

    setPageState((state) => ({ ...state, detailorigin: initOrigin }));
    //   initializecomponentviewing();

    const componentviewingforsimplesell = () => {
      setPageState((values) => ({
        ...values,
        showpaymentinfo: false,
        showorderstate: true,
        alloworderstateedit: true,
        showfinalandpaidamt: true,
        showdelivery: false,
        statuses: ["PENDING", "PROCESSING", "CLOSED", "CANCEL"],
        showaddresspicker: true,
        showrentalrelatedfields: false,
        showresouurcepicekeroncard: false,
        showresourcepickerfortableselection: false,
        noneditable: false,
      }));
    };

    const componentviewingforsimplesellwithdel = () => {
      setPageState((values) => ({
        ...values,
        showpaymentinfo: false,
        showorderstate: true,
        alloworderstateedit: true,
        showfinalandpaidamt: true,
        showdelivery: true,
        showaddresspicker: true,
        showrentalrelatedfields: false,
        showresouurcepicekeroncard: false,
        showresourcepickerfortableselection: false,
        noneditable: false,
      }));
    };

    const componentviewingforhistory = () => {
      setPageState((values) => ({
        ...values,
        showpaymentinfo: false,
        showorderstate: true,
        alloworderstateedit: false,
        showfinalandpaidamt: true,
        showdelivery: false,
        showaddresspicker: false,
        showrentalrelatedfields: false,
        showresouurcepicekeroncard: false,
        showresourcepickerfortableselection: false,
        noneditable: true,
      }));
    };

    const componentviewingforrental = () => {
      setPageState((values) => ({
        ...values,
        showpaymentinfo: false,
        showorderstate: true,
        alloworderstateedit: true,
        showfinalandpaidamt: true,
        showdelivery: false,
        statuses: ["PENDING", "PROCESSING", "CLOSED", "CANCEL"],
        showaddresspicker: false,
        showrentalrelatedfields: true,
        showresouurcepicekeroncard: true,
        showresourcepickerfortableselection: true,
        noneditable: false,
      }));
    };

    const setRentalDate = () => {
      if (selectedCartState.cart?.sdate) {
        formikRef.current.setFieldValue(
          "startDate",
          selectedCartState.cart!.sdate.toString()
        );
        formikRef.current.setFieldValue(
          "endDate",
          selectedCartState.cart!.edate.toString()
        );
      } else if (selectedCartState.rrfi?.sdt) {
        formikRef.current.setFieldValue(
          "startDate",
          selectedCartState.rrfi!.sdt!.toString()
        );
        formikRef.current.setFieldValue(
          "endDate",
          selectedCartState.rrfi!.edt!.toString()
        );
      } else {
        let mkdt = new Date(Date.now());
        formikRef.current.setFieldValue(
          "startDate",
          selectedCartState.sdt ?? mkdt.toString()
        );
        formikRef.current.setFieldValue(
          "endDate",
          selectedCartState.edt ?? addDays(mkdt, 1).toString()
        );
      }
    };

    const initializecomponentviewing = () => {
      if (initOrigin === OrderDetailsOrigin.SELL) {
        componentviewingforsimplesell();
      } else if (
        initOrigin === OrderDetailsOrigin.APT ||
        initOrigin === OrderDetailsOrigin.ORDERFORSERVICE
      ) {
        componentviewingforsimplesell();
      } else if (initOrigin === OrderDetailsOrigin.SELLWITHDEL) {
        componentviewingforsimplesellwithdel();
      } else if (initOrigin === OrderDetailsOrigin.SERVER) {
        componentviewingforsimplesell();
      } else if (initOrigin === OrderDetailsOrigin.SELLCOOK) {
        componentviewingforsimplesellwithdel();
      } else if (initOrigin === OrderDetailsOrigin.RENTAL) {
        setRentalDate();
        componentviewingforrental();
      } else if (initOrigin === OrderDetailsOrigin.RENTALPRODUCT) {
        setRentalDate();
        componentviewingforrental();
      } else {
        componentviewingforhistory();
      }
    };

    initializecomponentviewing();
  }, []);

  const onUpdateOrder = (values) => {
    let finalamout = 0.0;
    let amountpaid = 0.0;
    if (selectedCartState.cart?.isrental === true) {
      if (selectedCartState.cart?.custphonenum === null) {
        dispatch(
          toastMessage({
            error: true,
            message: "Please set customer information ",
          })
        );
        return;
      }

      if (
        selectedCartState.cart?.sdate === null ||
        selectedCartState.cart?.edate === null
      ) {
        dispatch(
          toastMessage({
            error: true,
            message: "Please set start/end date ",
          })
        );
        return;
      }

      let sdt = CONSTANTS.getJustDatePart(selectedCartState.cart!.sdate!);
      let edt = CONSTANTS.getJustDatePart(selectedCartState.cart!.edate!);
      if (sdt === edt) {
        dispatch(
          toastMessage({
            error: true,
            message: "End Date should be greater than startdate ",
          })
        );
        return;
      }
    }
    if (pageState._reqdelivery ?? false) {
      if (
        !(
          pageState.address !== null ||
          selectedCartState.cart?.custaddress !== null
        )
      ) {
        dispatch(
          toastMessage({
            error: true,
            message:
              "Customer address is required as delivery is set to be required",
          })
        );
        return;
      }
    }
    let tempDelmechanis = getfinaldelmech();
    setPageState((pState) => ({
      ...pState,
      delmechanism: tempDelmechanis,
    }));
    if (values.finalAmount !== "" && values.finalAmount.length > 0) {
      finalamout = parseFloat(values.finalAmount) ?? 0;
    }
    if (values.amountPaid !== "" && values.amountPaid.length > 0) {
      amountpaid = parseFloat(values.amountPaid) ?? 0;
    }
    if (amountpaid === 0) {
      dispatch(
        toastMessage({
          error: true,
          message: "Amount paid cannot be set to 0",
        })
      );
      return;
    }
    if (amountpaid > finalamout) {
      dispatch(
        toastMessage({
          error: true,
          message: "Amount paid cannot be greater than final amount",
        })
      );
      return;
    }
    if (amountpaid < finalamout && pageState.customerId !== null) {
      dispatch(
        toastMessage({
          error: true,
          message: "Amount paid cannot be less than final amount",
        })
      );
      return;
    }

    const adm = selectedCartState.cart?.custaddress;
    const finalprice = values.finalAmount;
    const amountPaid = values.amountPaid;
    const delmech = tempDelmechanis;
    dispatch(
      cartCheckout({
        custname: formikRef.current?.customerName ?? null,
        custphonenum: formikRef.current?.customerName ?? null,
        customerid: auth.currentUser?.uid ?? null,
        adm,
        finalprice,
        amountPaid,
        delmech,
      })
    ).then(() => {
      navigate("/");
    });
  };

  const getfinaldelmech = () => {
    // var ab = HelpUtil.getDefaultServiceModel();
    let ab = null;
    if (ab === null) {
      return -1;
    } else {
      if (pageState._reqdelivery ?? false) {
        if (ab.providerdelivery === CONSTANTS.deloption_thirdpartymanaged) {
          return 1;
        } else {
          return 2;
        }
      } else {
        return -1;
      }
    }
  };

  const dispatch = useDispatch<AppDispatch>();

  const selectIsLoading = useSelector(selectIsHomeLoading);

  const formikRef = useRef(null);
  return (
    <>
      {selectedCartState ? (
        <>
          <Formik
            initialValues={formInitialValue}
            validationSchema={validationSchemaModel}
            innerRef={formikRef}
            onSubmit={(values) => {
              onUpdateOrder(values);
              // onClickSubmit(values);
            }}
          >
            {({ values, touched, errors, setFieldValue, getFieldProps }) => (
              <Form id="CreateOrder">
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Paper
                      sx={{
                        overflow: "scroll",
                        padding: 2,
                        alignSelf: "center",
                      }}
                    >
                      {selectedCartState.cart?.productlist?.map((item) => (
                        <OrderCartItem
                          isActive={selectedCartState.orderid != null}
                          item={item}
                          modifiableStatuses={pageState.modifiableStatuses}
                          state={selectedCartState}
                          isnoneditable={pageState.noneditable}
                          showitemstate={pageState.showitemstate}
                          isRental={pageState.showrentalrelatedfields}
                          allowitemstateupdate={pageState.allowitemstateupdate}
                          showresourceavailabilityonitemcard={false}
                          //showrentalrelatedfields && state.cart?.id ==null,
                          showrentalreturnbutton={true}
                          //showrentalrelatedfields && state.cart?.id !=null,
                          showresouurcepicekeroncard={true}
                          //showrentalrelatedfields && state.cart?.id !=null && item.namedrentalres ==true ,

                          onRentStatusChange={(isAvailable: boolean) => {
                            // setState(() {
                            //   availableForRent = isAvailable;
                            // });
                          }}
                          startDate={
                            selectedCartState.sdt ?? new Date(Date.now())
                          }
                          endDate={
                            selectedCartState.edt ??
                            addDays(new Date(Date.now()), 1)
                          }
                        />
                        // <Box
                        //   sx={{
                        //     border: "1px solid black",
                        //     borderRadius: "5px",
                        //     width: "100%",
                        //     height: "150px",
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     alignItems: "center",
                        //     gap: "5px",
                        //     padding: "10px 10px 10px 10px",
                        //     marginBottom: "10px",
                        //   }}
                        // >
                        //   <Box
                        //     minHeight="130px"
                        //     minWidth="130px"
                        //     sx={{
                        //       borderRadius: "5px",
                        //       marginRight: "10px",
                        //       backgroundImage: `url("https://appstorage.sgp1.digitaloceanspaces.com/5751959759880192/CHAT/Images/1688785229992.png?AWSAccessKeyId=EE2PKJSMHJXNDXS2UG7C&Expires=2004404431&Signature=huYz%2FFEeeGFp96bk8EnRzgFlhDI%3D")`,
                        //       backgroundRepeat: "no-repeat",
                        //       backgroundPosition: "center",
                        //       backgroundSize: "contain",
                        //     }}
                        //   ></Box>
                        //   <Box
                        //     sx={{
                        //       width: "100%",
                        //     }}
                        //   >
                        //     <CTypography fontSize={heading3}>Title</CTypography>
                        //     <CTypography fontSize={text1}>Price</CTypography>
                        //   </Box>
                        //   <CartCounter
                        //     initValue={1}
                        //     onChange={(value) => {
                        //       // console.log(value);
                        //     }}
                        //   />
                        // </Box>
                      ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Paper sx={{ padding: 2, alignSelf: "center" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CTypography fontSize={text1} fontWeight="bold">
                            Customer Information
                          </CTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikControl
                            control="InputField"
                            name="customerName"
                            label="Customer Name"
                            error={Boolean(
                              touched.customerName && errors.customerName
                            )}
                            helperText={
                              touched.customerName && errors.customerName
                            }
                            {...getFieldProps("customerName")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikControl
                            control="InputField"
                            name="customerPhone"
                            label="Customer Phone"
                            error={Boolean(
                              touched.customerPhone && errors.customerPhone
                            )}
                            helperText={
                              touched.customerPhone && errors.customerPhone
                            }
                            {...getFieldProps("customerPhone")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormikControl
                            control="InputField"
                            name="address"
                            label="Address"
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                            {...getFieldProps("address")}
                          />
                        </Grid>

                        {lsm && (
                          <Grid item xs={12}>
                            <CTypography fontSize={text1} fontWeight="bold">
                              Payment Information
                            </CTypography>
                          </Grid>
                        )}
                        {lsm && (
                          <Grid xs={12}>
                            <Stack
                              direction="column"
                              justifyContent="space-between"
                              alignItems="center"
                              gap={1}
                            >
                              <Box
                                component="img"
                                src={lsm?.spi?.pqrcodeimage}
                                alt="QR"
                                loading="eager"
                              />
                              <CTypography fontSize={text2} fontWeight="bold">
                                {lsm?.spi?.payeeto}
                              </CTypography>
                            </Stack>
                          </Grid>
                        )}

                        {pageState.showpaymentinfo && (
                          <>
                            <Grid item xs={12}>
                              <CTypography fontSize={text1} fontWeight="bold">
                                Payment Info
                              </CTypography>
                            </Grid>

                            <Grid item xs={12}>
                              <CKeyValueList
                                labelWidth={250}
                                size="medium"
                                list={[
                                  {
                                    key: "Service Provider",
                                    value:
                                      selectedCartState.sm?.spi?.payeeto ?? "a",
                                  },
                                  {
                                    key: "Mobile",
                                    value:
                                      selectedCartState.sm?.spi?.phonenum ??
                                      "a",
                                  },
                                  {
                                    key: "Direction",
                                    value:
                                      selectedCartState.sm?.spi?.address ?? "a",
                                  },
                                ]}
                              />
                            </Grid>
                          </>
                        )}

                        {pageState.showrentalrelatedfields && (
                          <>
                            <Grid item xs={12}>
                              <CTypography fontSize={text1} fontWeight="bold">
                                Rental Dates
                              </CTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikControl
                                control="CalendarTime"
                                label="Start Date"
                                dateOrTimeOnly="date"
                                name="startDate"
                                error={Boolean(
                                  touched.startDate && errors.startDate
                                )}
                                helperText={
                                  touched.startDate && errors.startDate
                                }
                                {...getFieldProps("startDate")}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormikControl
                                control="CalendarTime"
                                label="End Date"
                                dateOrTimeOnly="date"
                                name="endDate"
                                error={Boolean(
                                  touched.endDate && errors.endDate
                                )}
                                helperText={touched.endDate && errors.endDate}
                                {...getFieldProps("endDate")}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <CTypography fontSize={text1} fontWeight="bold">
                            Summary
                          </CTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <OrderSummary state={selectedCartState} />

                          {/* <CKeyValueList
                        labelWidth={200}
                        size="medium"
                        list={[
                          {
                            key: "Amount",
                            value: `₹ ${selectedItemVersion.discountedprice}`,
                          },
                          {
                            key: "Delivery Charges",
                            value: `₹ ${selectedItemVersion.discountedprice}`,
                          },
                          {
                            key: "Tax",
                            value: `₹ ${selectedItemVersion.discountedprice}`,
                          },
                          {
                            key: "Total",
                            value: `₹ ${selectedItemVersion.discountedprice}`,
                          },
                        ]}
                      /> */}
                        </Grid>
                        {pageState.showdelivery &&
                        pageState.showaddresspicker ? (
                          <>
                            <Grid item xs={12}>
                              <CTypography fontSize={text1} fontWeight="bold">
                                Delivery Method
                              </CTypography>
                            </Grid>

                            <Grid item xs={12}>
                              <DeliveryMethod
                                reqDelivery={pageState._reqdelivery ?? false}
                                selectedaddressmodel={
                                  pageState.address ??
                                  selectedCartState.cart?.custaddress
                                }
                                onUpdateDeliveryMethod={(isReqDelivery) => {
                                  if (
                                    isReqDelivery &&
                                    !selectedCartState.cart?.custaddress &&
                                    !selectedCartState.custaddresslist
                                  ) {
                                    // TODO: API CALL
                                    // _bloc = context.read<CartBloc>();
                                    // _bloc.add(GetAddressSp());
                                  }

                                  setPageState((pState) => ({
                                    ...pState,
                                    _reqdelivery: isReqDelivery,
                                  }));
                                }}
                                onSelectDeliveryAddress={(adm) => {
                                  setPageState((pState) => ({
                                    ...pState,
                                    address: adm,
                                  }));
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {pageState.showfinalandpaidamt && (
                          <>
                            <Grid item xs={12} sm={5}>
                              <FormikControl
                                disabled={pageState.noneditable}
                                control="InputField"
                                name="finalAmount"
                                label="Final Amount"
                                error={Boolean(
                                  touched.finalAmount && errors.finalAmount
                                )}
                                helperText={
                                  touched.finalAmount && errors.finalAmount
                                }
                                {...getFieldProps("finalAmount")}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormikControl
                                disabled={pageState.noneditable}
                                control="InputField"
                                name="amountPaid"
                                label="Amount Paid"
                                error={Boolean(
                                  touched.amountPaid && errors.amountPaid
                                )}
                                helperText={
                                  touched.amountPaid && errors.amountPaid
                                }
                                {...getFieldProps("amountPaid")}
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setFieldValue(
                                    "amountPaid",
                                    values.finalAmount
                                  );
                                }}
                              >
                                Copy
                              </Button>
                            </Grid>
                          </>
                        )}

                        {/* SUBMIT FORM */}
                        {!pageState.noneditable &&
                          selectedCartState.cart !== null &&
                          pageState.modifiableStatuses.includes(
                            selectedCartState.cart!.orderstate
                          ) && (
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Button
                                    sx={{
                                      color: textColor,
                                    }}
                                    variant="contained"
                                    type="submit"
                                  >
                                    {selectedCartState.cart?.id != null
                                      ? "Complete Order"
                                      : "Declare Order"}
                                  </Button>
                                </Grid>
                                {/* <Grid item>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  // navigate(-1);
                                }}
                              >
                                CANCEL
                              </Button>
                            </Grid> */}
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
};
