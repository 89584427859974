import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectTrip = createSelector(selectSelf, (state) => state.trip);

export const selectIsTripLoading = createSelector(
  selectTrip,
  (trip) => trip.isLoading
);

export const selectTripState = createSelector(
  selectTrip,
  (trip) => trip.tripState
);

export const selectTripLocation = createSelector(
  selectTrip,
  (trip) => trip.currentLocation
);

export const selectTripRelatedLookupsResult = createSelector(
  selectTrip,
  (trip) => trip.tripRelatedLookups
);

export const selectEndUserSearchResults = createSelector(
  selectTrip,
  (trip) => trip.endUserSearchResults
);
