import {
  Typography,
  Box as Bbox,
  Grid,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import * as CONSTANTS from "src/constants";
import WifiIcon from "@mui/icons-material/Wifi";
import CButton from "src/components/button-group/CButton";
import { addedProductCartEvent } from "src/slices/cart/thunks";
import { selectCartState } from "src/slices/cart/selectors";
import { ICartState } from "src/slices/cart/types";
import { selectBookingAndRentalData } from "src/slices/landing-page/selectors";
import Box from "src/components/pages/landing-page/Box";
import { RentalSpListWithFilter } from "./rental-sp-list-with-fliter";
import { useRef } from "react";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";

function toJsonTimeStamp(time) {
  if (time !== null && time instanceof Date) {
    const utcTime = time.toISOString(); // Convert to UTC string
    const millisecondsSinceEpoch =
      new Date(utcTime).getTime() -
      new Date(utcTime).getTimezoneOffset() * 60000; // Get milliseconds since epoch
    return Math.round(millisecondsSinceEpoch / 1000);
  }
  return null;
}

export function RentalsProductListSPView() {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const cartState: ICartState = useSelector(selectCartState);
  const bookingAndRentalData = useSelector(selectBookingAndRentalData);

  const { heading3, text1, text2, text3, foregroundColor, textColor } =
    useDisplaySettingsContext();

  const formikRef = useRef(null);

  const addProductToCard = (lrsp, spid, sdt, edt) => {
    const p = {
      product: {
        id: Math.floor(Math.random() * 2340).toString(),
        itemid: lrsp.prodid,
        productid: lrsp.prodid,
        tileimage:
          lrsp.imagelist != null && lrsp.imagelist!.length > 0
            ? lrsp.imagelist![0]
            : CONSTANTS.noimageurl,
        title: lrsp.title,
        price: lrsp.priceinfo ?? 0,
        category: lrsp.title,
        rtype: lrsp.rtype,
        rcat: lrsp.category,
        unitprice: (lrsp.priceinfo ?? 0).toString(),
        namedrentalres:
          lrsp.invcat !== null &&
          [
            CONSTANTS.prodat_rentprod,
            CONSTANTS.prodat_hospital,
            CONSTANTS.prodat_hotel,
          ].includes(lrsp.atyp ?? ""),
        unit: "",
        qty: 1.0,
        totalprice: lrsp.priceinfo ?? 0,
        discountedprice: lrsp.priceinfo ?? 0,

        taxbracket: lrsp.taxbracket,
        gsttoken: lrsp.gsttoken,
      },
    };
    if (cartState.lsm?.spi !== null) {
      dispatch(
        addedProductCartEvent({
          ...p,
          sdt: CONSTANTS.getJustDatePart(sdt),
          edt: CONSTANTS.getJustDatePart(edt),
        })
      );
    } else {
      dispatch(
        addedProductCartEvent({
          ...p,
          spi: spid,
          sdt: CONSTANTS.getJustDatePart(sdt),
          edt: CONSTANTS.getJustDatePart(edt),
        })
      );
    }
  };

  const KeyValue = ({ title, value }) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: text2, fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontSize: text2 }}>{value}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <>
      {bookingAndRentalData && (
        <>
          <Box
            id="rooms"
            title="Rooms"
            subtitle=""
            sx={{
              backgroundColor: "white",
              paddingLeft: { xs: 2, sm: 12 },
              paddingRight: { xs: 2, sm: 12 },
              paddingBottom: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RentalSpListWithFilter formikRef={formikRef} />
              </Grid>
              {formikRef.current && (
                <Grid item xs={12}>
                  <Stack direction="column">
                    {bookingAndRentalData?.prodlist.map((data) => (
                      <Bbox
                        key={data.prodid}
                        onClick={() => {}}
                        sx={{
                          padding: "10px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          wordBreak: "break-word",
                          background: "white",
                          borderRadius: "10px",
                          border: "1px solid #000000",
                        }}
                      >
                        <Bbox
                          sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          <Bbox
                            minHeight="150px"
                            minWidth="150px"
                            sx={{
                              borderRadius: "5px",
                              marginRight: "10px",
                              backgroundImage: `url(${
                                bookingAndRentalData?.imagelist[0] ?? ""
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                            }}
                          ></Bbox>
                          <Bbox
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: heading3,
                                fontWeight: "bold",
                                color: "primary.main",
                              }}
                            >
                              {data.title}
                            </Typography>
                            <Grid container>
                              <KeyValue
                                title="Name"
                                value={data?.title ?? "NoTitle"}
                              />
                              <KeyValue
                                title="Description"
                                value={data?.desc ?? "NoDesc"}
                              />
                              <KeyValue
                                title="PerDay"
                                // value={data?.priceinfo ?? 0}
                                value={CONSTANTS.getRateForStartDate(
                                  data,
                                  toJsonTimeStamp(
                                    CONSTANTS.getJustDatePart(
                                      formikRef?.current?.values?.startDate
                                    )
                                  ) ?? 0,
                                  toJsonTimeStamp(
                                    CONSTANTS.getJustDatePart(
                                      formikRef?.current?.values?.endDate
                                    )
                                  ) ?? 0
                                ).toString()}
                              />
                              <Grid item xs={12} sx={{ my: 2 }}>
                                <Stack
                                  direction="row"
                                  justifyContent="start"
                                  flexWrap="wrap"
                                >
                                  {(data?.amen ?? []).map((roomData) => (
                                    <Stack
                                      direction="column"
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{ p: 1 }}
                                    >
                                      <WifiIcon sx={{ fontSize: text1 }} />
                                      <Typography fontSize={text3}>
                                        {roomData}
                                      </Typography>
                                    </Stack>
                                  ))}
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <CButton
                                  fullWidth
                                  variant="contained"
                                  onClick={() => {
                                    addProductToCard(
                                      data,
                                      bookingAndRentalData.spi,
                                      formikRef.current.values.startDate,
                                      formikRef.current.values.endDate
                                    );
                                  }}
                                  sx={{
                                    fontSize: text3,
                                    color: textColor,
                                  }}
                                >
                                  Book now
                                </CButton>
                              </Grid>
                            </Grid>
                          </Bbox>
                        </Bbox>
                      </Bbox>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
