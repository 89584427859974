import { Box as Bbox, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectDeals } from "src/slices/landing-page/selectors";
import Box from "src/components/pages/landing-page/Box";
import { ProductListItem } from "../cart-product/product-list-item";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";

export const Deals1 = () => {
  const deals = useSelector(selectDeals);
  const { foregroundColor } = useDisplaySettingsContext();

  return (
    <>
      {deals && (
        <Box
          id="deals"
          title="Deals"
          subtitle=""
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            paddingLeft: { xs: 2, sm: 4 },
            paddingRight: { xs: 2, sm: 4 },
            paddingBottom: 2,
          }}
        >
          <Grid container spacing={1}>
            {deals?.docwithdata1?.map((data) => (
              <Grid item xs={12} md={6}>
                <ProductListItem key={data.productid} productData={data} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};
