/*eslint-disable*/
import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastMessageInfoBoxRemove } from "../slices/commonSlice";
import PropTypes from "prop-types";
import CModalConfirmation from "./CModalConfirmation";

import { setDialog } from "../slices/uiSettingsSlice";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid } from "@mui/material";
import { red, blue, green } from "@mui/material/colors";
import { Slices } from "../constants";
import { AppDispatch } from "src/store";

interface ICToastInfoDialog {
  showSuccessMessage?: string;
  showErrorMessage?: string;
  removePrevious?: boolean;
}

const CToastInfoDialog: FC<ICToastInfoDialog> = ({
  showSuccessMessage,
  showErrorMessage,
  removePrevious = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    infoMessage,
    infoMessageOpen,
    infoMessageError,
    infoMessageInformation,
    okButtonHandler,
    cancelButtonHandler,
  } = useSelector((state: any) => state[Slices.COMMON_REDUCER]);
  const { dialog } = useSelector((state: any) => ({
    dialog: state[Slices.UI_SETTINGS].dialog,
  }));

  useEffect(() => {
    if (infoMessageOpen) {
      dispatch(setDialog({ open: true, dialogName: "notification" }));
    }
  }, [infoMessageOpen]);

  const handleSave = () => {
    dispatch(toastMessageInfoBoxRemove());
    dispatch(setDialog({ open: false, dialogName: "notification" }));
    if (okButtonHandler) {
      okButtonHandler();
    }
  };

  const handleClose = () => {
    dispatch(setDialog({ open: false, dialogName: "notification" }));
    dispatch(toastMessageInfoBoxRemove());
    if (cancelButtonHandler) {
      cancelButtonHandler();
    }
  };

  return (
    <>
      <CModalConfirmation
        dialogName="notification"
        title={
          infoMessageError
            ? "Error"
            : infoMessageInformation
            ? "Information"
            : dialog["notification"] && dialog["notification"] !== undefined
            ? "Success"
            : ""
        }
        handleSaveButtonText="Ok"
        handleCancelButtonText="Cancel"
        handleSave={() => {
          handleSave();
        }}
        maxWidth="xs"
        noContentPadding
        showCancelButton={cancelButtonHandler ? true : false}
        handleCancel={() => {
          handleClose();
        }}
        content={
          <>
            {
              <Grid container>
                <Grid item xs={2} sx={{ pt: "5px" }}>
                  {infoMessageError ? (
                    <CancelIcon
                      color="action"
                      style={{ fontSize: 50, color: red["500"] }}
                    />
                  ) : infoMessageInformation ? (
                    <ErrorIcon
                      color="action"
                      style={{ fontSize: 50, color: blue.A400 }}
                    />
                  ) : dialog["notification"] &&
                    dialog["notification"] !== undefined ? (
                    <CheckCircleIcon
                      color="action"
                      style={{ fontSize: 50, color: green["500"] }}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {infoMessage}
                </Grid>
              </Grid>
            }
          </>
        }
      />
    </>
  );
};

CToastInfoDialog.propTypes = {
  showSuccessMessage: PropTypes.string,
  showErrorMessage: PropTypes.string,
  removePrevious: PropTypes.bool,
};

export default CToastInfoDialog;
