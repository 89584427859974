import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useLocation, useNavigate } from 'react-router-dom';
import CModal from 'src/components/CModal';
import FormikControl from 'src/components/formik/FormikControl';
import { PetListItem } from 'src/components/pages/pet/pet-list-item';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { useGeolocationContext } from 'src/contexts/GeolocationContext';
import {
  selectIsHomeLoading,
  selectProductFilterResult,
  selectProductFilterResultLastCall,
} from 'src/slices/home/selectors';
import { fetchProductFilterResult } from 'src/slices/home/thunks';
import { IFetchProductFilterResultPayload } from 'src/slices/home/types';
import { setDialog } from 'src/slices/uiSettingsSlice';
import { AppDispatch } from 'src/store';

export function PetsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const position = useGeolocationContext();

  const { state } = useLocation();

  const selectIsLoading = useSelector(selectIsHomeLoading);
  const pets = useSelector(selectProductFilterResult);
  const petLastCallPayload = useSelector(selectProductFilterResultLastCall);
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const formikRef = useRef(null);
  const { heading2, textColor, text2, text3 } = useDisplaySettingsContext();
  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState<any>([]);

  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [paginationCount, setPaginationCount] = useState(0);

  useEffect(() => {
    if (pets) {
      if (selectedFilters.length !== 0) {
        const fltrList = pets.diminfo.map((data) => {
          if (
            selectedFilters.filter(
              (fiteredData) => fiteredData.paraname === data.dimname
            ).length !== 0
          ) {
            return {
              paraname: data.dimname,
              fieldvalues: data.fieldvalues.map((fieldData) => ({
                ...fieldData,
                checked: true,
              })),
            };
          } else {
            return {
              paraname: data.dimname,
              fieldvalues: data.fieldvalues.map((fieldData) => ({
                ...fieldData,
                checked: false,
              })),
            };
          }
        });
        setFilterList(fltrList);
      } else {
        const fltrList = pets.diminfo.map((data) => ({
          paraname: data.dimname,
          fieldvalues: data.fieldvalues.map((fieldData) => ({
            ...fieldData,
            checked: false,
          })),
        }));
        setFilterList(fltrList);
      }
    }
  }, [pets]);

  useEffect(() => {
    if (paginationCount !== -1) {
      dispatch(
        fetchProductFilterResult({
          ...state,
          dynamicProperty: {
            ...state.dynamicProperty,
            offset: paginationCount,
          },
        })
      );
    }
  }, [paginationCount]);

  return (
    <>
      {pets ? (
        <>
          <Badge
            badgeContent={0}
            color="secondary"
            overlap="circular"
            sx={{
              position: "absolute",
              bottom: 20,
              right: 100,
              zIndex: 1,
            }}
          >
            <Fab
              disabled={paginationCount === 0}
              size="large"
              color="primary"
              aria-label="add"
              sx={{
                zIndex: 0,
                color: 'white'
              }}
              onClick={() => {
                if (paginationCount > 0) {
                  setPaginationCount((pCount) => pCount - 10);
                }
              }}
            >
              <ArrowUpward />
            </Fab>
          </Badge>
          <Badge
            badgeContent={0}
            color="secondary"
            overlap="circular"
            sx={{
              position: "absolute",
              bottom: 20,
              right: 30,
              zIndex: 1,
            }}
          >
            <Fab
              disabled={pets?.docwithdata5?.length === 0}
              size="large"
              color="primary"
              aria-label="add"
              sx={{
                zIndex: 0,
                color: 'white'
              }}
              onClick={() => {
                if (pets?.docwithdata5?.length !== 0) {
                  setPaginationCount((pCount) => pCount + 10);
                }
              }}
            >
              <ArrowDownward />
            </Fab>
          </Badge>
          <Box
            sx={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              gap: "5px",
            }}
          >
            <TextField
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              id="search"
              label="Search for Animals"
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <Button
            sx={{
              color: textColor
            }}
              onClick={() => {
                dispatch(setDialog({ open: true, dialogName: "PetFilters" }));
              }}
            >
              Filters
            </Button>
          </Box>
          {selectedFilters.length === 0 ? (
            <></>
          ) : (
            <Box
              sx={{
                bgcolor: "white",
                p: 1,
                mt: "5px",
                overflow: "auto",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {selectedFilters.map((filter) => (
                <>
                  <Typography fontSize={text3}>{`${filter.paraname}:`}</Typography>
                  {filter.fieldvalues.map((subFilter) => (
                    <Chip label={subFilter} variant="filled" color="primary" />
                  ))}
                </>
              ))}
            </Box>
          )}
          <Grid container spacing={1}>
            {pets.docwithdata5
              .filter((data) =>
                data.title.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((data) => (
                <Grid item xs={12} sm={6}>
                  <PetListItem key={data.productid} pet={data} />
                </Grid>
              ))}
          </Grid>
          {dialog.PetFilters !== undefined && dialog.PetFilters ? (
            <CModal
              dialogName="PetFilters"
              title={"Pet Filters"}
              maxWidth="sm"
              showSaveButton={true}
              handleSaveButtonText="Apply"
              handleCancel={() => {}}
              handleSave={() => {
                dispatch(setDialog({ open: false, dialogName: "PetFilters" }));

                const selectedFilterList = filterList.filter(
                  (data) =>
                    data.fieldvalues.filter((fieldData) => fieldData.checked)
                      .length !== 0
                );

                const selectedFilterListValues = selectedFilterList.map(
                  (data) => ({
                    paraname: data.paraname,
                    fieldvalues: data.fieldvalues
                      .filter((fieldData) => fieldData.checked)
                      .map((fieldData) => fieldData.fieldvalue),
                  })
                );
                setSelectedFilters(selectedFilterListValues);

                // Price Filter
                let filterObj = {
                  fieldname: "Price",
                  minval: 0,
                  maxval: 0,
                };
                let isPriceFilterSelected = false;
                if (formikRef.current.values.priceMin !== "") {
                  filterObj.minval = parseInt(
                    formikRef.current.values.priceMin
                  );
                  isPriceFilterSelected = true;
                }
                if (formikRef.current.values.priceMax !== "") {
                  filterObj.maxval = parseInt(
                    formikRef.current.values.priceMax
                  );
                  isPriceFilterSelected = true;
                }
                const filterValue = isPriceFilterSelected ? [filterObj] : [];
                dispatch(
                  fetchProductFilterResult({
                    dynamicProperty: {
                      psc: {
                        servicetype:
                          petLastCallPayload.dynamicProperty.psc.servicetype,
                        grouptype:
                          petLastCallPayload.dynamicProperty.psc.grouptype,
                        isshop: false,
                        sp: {
                          curStatename: position.district,
                          statename: position.district,
                          lati: position.latitude,
                          longi: position.longitude,
                        },
                      },
                      categorytype:
                        petLastCallPayload.dynamicProperty.categorytype,
                      termquery: "",
                      filters: filterValue,
                      facetparalist: selectedFilterListValues,
                      serviceproviderid: "",
                      isservice: false,
                    },
                  } as IFetchProductFilterResultPayload)
                );
              }}
              open={dialog.PetFilters === undefined ? false : dialog.PetFilters}
              content={
                <Formik
                  initialValues={{
                    priceMin: "",
                    priceMax: "",
                  }}
                  innerRef={formikRef}
                  onSubmit={() => console.log()}
                >
                  {({ touched, errors, getFieldProps }) => (
                    <Form>
                      <Grid container spacing={2}>
                        {/* Price */}
                        <Grid item xs={12}>
                          <Typography fontSize={text2}>Price</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Min"}
                            name="priceMin"
                            size="small"
                            error={Boolean(touched.priceMin && errors.priceMin)}
                            helperText={touched.priceMin && errors.priceMin}
                            {...getFieldProps("priceMin")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Max"}
                            name="priceMax"
                            size="small"
                            error={Boolean(touched.priceMax && errors.priceMax)}
                            helperText={touched.priceMax && errors.priceMax}
                            {...getFieldProps("priceMax")}
                          />
                        </Grid>

                        {filterList.map((filterData, filterListIndex) => (
                          <Grid item xs={12}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography fontSize={text2}>
                                  {filterData.paraname}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container xs={2}>
                                  {filterData.fieldvalues.map(
                                    (fieldData, fieldValueIndex) => (
                                      <>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Checkbox
                                            size="medium"
                                            color="primary"
                                            checked={fieldData.checked ?? false}
                                            onClick={(e: any) => {
                                              if (e.target.checked) {
                                                filterList[
                                                  filterListIndex
                                                ].fieldvalues[
                                                  fieldValueIndex
                                                ].checked = true;
                                                setFilterList([...filterList]);
                                              } else {
                                                filterList[
                                                  filterListIndex
                                                ].fieldvalues[
                                                  fieldValueIndex
                                                ].checked = false;
                                                setFilterList([...filterList]);
                                              }
                                            }}
                                          />

                                          <Typography sx={{ width: 100 }}>
                                            {fieldData.fieldvalue}
                                          </Typography>
                                        </Box>
                                      </>
                                    )
                                  )}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        ))}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              }
            />
          ) : null}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>No Data Found</Typography>
          )}
        </Box>
      )}
    </>
  );
}
