import { createSlice } from '@reduxjs/toolkit';

import {
  getAdhocLocation,
  getAdhocTripById,
  getEndUserSearchResults,
  getRegularLocation,
  getTripListData,
  getTripRelatedLookups,
} from './thunks';
import { ITripSlice } from './types';

export const tripSlice = createSlice({
  name: "trip",
  initialState: {
    tripState: null,
    currentLocation: null,
    isError: false,
    isLoading: false,
  } as ITripSlice,
  reducers: {
    updateTripState(state, action): void {
      state.tripState = { ...state.tripState, ...action.payload };
    },
    clearEndUserTripState(state): void {
      state.endUserSearchResults = null;
    },
  },
  extraReducers: (builder) => {
    // getTripListData
    builder.addCase(getTripListData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTripListData.fulfilled, (state, action) => {
      state.tripState = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTripListData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    //getRegularLocation
    builder.addCase(getRegularLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRegularLocation.fulfilled, (state, action) => {
      state.currentLocation = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getRegularLocation.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // getAdhocLocation
    builder.addCase(getAdhocLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdhocLocation.fulfilled, (state, action) => {
      state.currentLocation = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAdhocLocation.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // getAdhocTripById
    builder.addCase(getAdhocTripById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdhocTripById.fulfilled, (state, action) => {
      state.tripState = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAdhocTripById.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // getTripRelatedLookups
    builder.addCase(getTripRelatedLookups.pending, (state) => {});
    builder.addCase(getTripRelatedLookups.fulfilled, (state, action) => {
      state.tripRelatedLookups = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTripRelatedLookups.rejected, (state) => {
      state.isError = true;
    });

    builder.addCase(getEndUserSearchResults.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEndUserSearchResults.fulfilled, (state, action) => {
      state.endUserSearchResults = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getEndUserSearchResults.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { updateTripState, clearEndUserTripState } = tripSlice.actions;
export const { reducer } = tripSlice;
